import React, { useEffect, useState, useTransition } from "react";
import {
  Button,
  Tab,
  Tabs,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Container,
  Pagination,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  Typography,
  Divider,
  ListItemButton,
} from "@mui/material";
import { BiEdit } from "react-icons/bi";
import { IoMdAddCircle as AddIcon } from "react-icons/io";
import { AiFillDelete as DeleteIcon } from "react-icons/ai";
import MUIAddIcon from "@mui/icons-material/Add";
import EditPipeDialog from "./EditPipeDialog";
import {
  PipeMetadataType,
  JunctionMetadataType,
  PipeMap,
  JunctionMap,
  NetworkMetadataProps,
  raw2pipes,
  PipesRawData,
  raw2junctions,
  JunctionsRawData,
} from "../../pages/PipelineConfigPage";
import axios from "axios";
import { API_URL } from "../../constants";
import EditJunctionDialog from "./EditJunctionDialog";
import { useTranslation } from "react-i18next";

type NetworkMetadataEditorProps = NetworkMetadataProps & {
  editable: boolean;
  setEditable: React.Dispatch<React.SetStateAction<boolean>>;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
};

const NetworkMetadataEditor: React.FC<NetworkMetadataEditorProps> = ({
  pipes,
  junctions,
  setPipes,
  setJunctions,
  network_id,
  editable,
  setEditable,
  setActiveStep,
}) => {
  const [activeTab, setActiveTab] = useState<"pipe" | "junction">("pipe");

  const { t } = useTranslation();
  const handleChange = (event: any, newValue: any) => {
    setActiveTab(newValue);
  };

  return (
    <Box maxWidth="md" sx={{ border: "1px solid #CCD6E0", borderRadius: "0.5rem", margin: "1rem" }}>
      <Tabs sx={{ margin: "2rem 2rem 1rem" }} TabIndicatorProps={{ hidden: true }} value={activeTab} onChange={handleChange}>
        <Tab
          sx={{
            width: "8rem",
            borderRadius: "8px 0px 0px 8px",
            borderTop: "1px solid #CCD6E0",
            borderLeft: "1px solid #CCD6E0",
            borderBottom: "1px solid #CCD6E0",
            textTransform: "none",
            "&.Mui-selected": {
              border: "1px solid #245FA6",
              background: "#EFF5FA",
            },
          }}
          label={t("uploadNetwork.content.pipes")}
          value="pipe"
        />
        <Tab
          sx={{
            width: "8rem",
            borderRadius: "0px 8px 8px 0px",
            borderTop: "1px solid #CCD6E0",
            borderRight: "1px solid #CCD6E0",
            borderBottom: "1px solid #CCD6E0",
            textTransform: "none",
            "&.Mui-selected": {
              border: "1px solid #245FA6",
              background: "#EFF5FA",
            },
          }}
          label={t("uploadNetwork.content.junctions")}
          value="junction"
        />
      </Tabs>

      <Box>
        {activeTab === "pipe" ? (
          <PipeList
            pipes={pipes}
            setPipes={setPipes}
            network_id={network_id}
            editable={editable}
            setEditable={setEditable}
            setActiveStep={setActiveStep}
          />
        ) : (
          <JunctionList
            junctions={junctions}
            setJunctions={setJunctions}
            network_id={network_id}
            editable={editable}
            setEditable={setEditable}
            setActiveStep={setActiveStep}
          />
        )}
      </Box>
      {/* <EditPipeDialog pipe={fakePipeMetadata[0]} /> */}
    </Box>
  );
};

const handleUpload = (nid: string) => {
  axios.post(`${API_URL}/models/${nid}`);
};

const PipeList: React.FC<Partial<NetworkMetadataEditorProps>> = ({
  pipes,
  setPipes,
  network_id,
  editable,
  setEditable,
  setActiveStep,
}) => {
  const [curPage, setCurPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [selectedPipe, setSelectedPipe] = useState<PipeMetadataType | null>(null);
  const [deletingPipe, setDeletingPipe] = useState<PipeMetadataType | null>(null);
  const [hoveredItem, setHoveredItem] = useState(-1);
  const { t } = useTranslation();
  //handle EDIT
  const handleEditPipe = (pipe: PipeMetadataType) => {
    setSelectedPipe(pipe);
  };

  // handle DELETE
  const handleCancelDelete = () => {
    setDeletingPipe(null);
  };
  const handleConfirmDelete = async () => {
    console.log("confirm deleting pipe" + deletingPipe?.LABEL);
    console.log(API_URL + `/network/pipe/${deletingPipe?.LABEL}/${network_id}`);
    await axios
      .delete(API_URL + `/network/pipe/${deletingPipe?.LABEL}/${network_id}`)
      .then((r) => console.log(r.data))
      .catch((e) => console.log(e));

    await axios.get(API_URL + "/network/pipes/" + network_id).then((pipesRawData) => {
      const data =
        typeof pipesRawData.data === "string" ? JSON.parse(pipesRawData.data.replace(/\bNaN\b/g, "null")) : pipesRawData.data;
      const parsedPipes = raw2pipes(data as PipesRawData);
      setPipes!(parsedPipes);
      return parsedPipes;
    });

    setDeletingPipe(null);
  };
  const handleClickDelete = (pipe: PipeMetadataType) => {
    setDeletingPipe(pipe);
  };

  // handle ADD
  const handleAddPipe = () => {
    setSelectedPipe({} as PipeMetadataType);
  };
  const handlePageChange = (event: any, page: number) => {
    setCurPage(page);
  };

  return (
    <Box display="flex" flexDirection="column">
      <List>
        <ListItem sx={{ display: "flex", gap: "20%", background: "#EFF5FA" }}>
          <Typography sx={{ marginLeft: "1rem" }} width={"1rem"}>
            #
          </Typography>
          <ListItemText primary={t("uploadNetwork.content.pipeLabel")} />
          {editable && (
            <Typography justifySelf="right" color="#245FA6" component="div">
              {t("uploadNetwork.dialog.addPipe")}
            </Typography>
          )}
          {editable && (
            <ListItemSecondaryAction>
              <IconButton edge="end" onClick={handleAddPipe}>
                <MUIAddIcon sx={{ color: "#245FA6" }} />
              </IconButton>
            </ListItemSecondaryAction>
          )}
        </ListItem>
        <Divider></Divider>
        {Object.entries(pipes ?? {})
          .slice((curPage - 1) * itemsPerPage, curPage * itemsPerPage)
          .map(([label, pipe], index) => (
            <div
              key={index}
              style={{ background: index === hoveredItem ? "#F5F5F5" : undefined }}
              onMouseEnter={() => setHoveredItem(index)}
              onMouseLeave={() => setHoveredItem(-1)}
            >
              <ListItemButton
                key={index}
                sx={{ height: "3rem", display: "flex", gap: "20%" }}
                onClick={() => {
                  handleEditPipe(pipe);
                }}
              >
                <Typography sx={{ marginLeft: "1rem" }} width={"1rem"}>
                  {(curPage - 1) * itemsPerPage + index + 1}
                </Typography>
                <ListItemText primary={label} />
                {editable && index === hoveredItem && (
                  <ListItemSecondaryAction>
                    <IconButton edge="end" onClick={() => handleEditPipe(pipe)}>
                      <BiEdit />
                    </IconButton>
                    <IconButton edge="end" onClick={() => handleClickDelete(pipe)}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                )}
              </ListItemButton>
              <Divider></Divider>
            </div>
          ))}
      </List>
      <Pagination
        style={{ alignSelf: "center", marginBottom: "1rem" }}
        showFirstButton
        showLastButton
        count={Math.ceil(Object.keys(pipes ?? {}).length / itemsPerPage)}
        page={curPage}
        onChange={handlePageChange}
      />
      {editable && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            handleUpload(network_id ?? "");
            setEditable!(false);
            setActiveStep!(3);
          }}
        >
          {t("common.confirmAndUpload")}
        </Button>
      )}
      {selectedPipe && (
        <EditPipeDialog
          create={selectedPipe.LABEL === undefined}
          open={!!selectedPipe}
          selectedPipe={selectedPipe}
          setSelectedPipe={setSelectedPipe}
          network_id={network_id ?? ""}
          setPipes={setPipes}
          editable={editable}
        />
      )}
      {deletingPipe && (
        <Dialog
          open={!!deletingPipe}
          onClose={handleCancelDelete}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {t("uploadNetwork.dialog.deleteWarning1")} {deletingPipe.LABEL} {t("uploadNetwork.dialog.deleteWarning2")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancelDelete}>{t("common.cancel")}</Button>
            <Button onClick={handleConfirmDelete} autoFocus>
              {t("common.confirm")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
};

const JunctionList: React.FC<Partial<NetworkMetadataEditorProps>> = ({
  junctions,
  setJunctions,
  network_id,
  editable,
  setEditable,
  setActiveStep,
}) => {
  const [curPage, setCurPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [selectedJunction, setSelectedJunction] = useState<JunctionMetadataType | null>(null);
  const [deletingJunction, setDeletingJunction] = useState<JunctionMetadataType | null>(null);
  const [hoveredItem, setHoveredItem] = useState(-1);
  const { t } = useTranslation();
  //handle EDIT
  const handleEditJunction = (junction: JunctionMetadataType) => {
    setSelectedJunction(junction);
  };

  // handle DELETE
  const handleCancelDelete = () => {
    setDeletingJunction(null);
  };
  const handleConfirmDelete = async () => {
    console.log("confirm deleting junction" + deletingJunction?.LABEL);
    console.log(API_URL + `/network/junction/${deletingJunction?.LABEL}/${network_id}`);
    await axios
      .delete(API_URL + `/network/junction/${deletingJunction?.LABEL}/${network_id}`)
      .then((r) => console.log(r.data))
      .catch((e) => console.log(e));

    await axios.get(API_URL + "/network/junctions/" + network_id).then((junctionsRawData) => {
      const data =
        typeof junctionsRawData.data === "string"
          ? JSON.parse(junctionsRawData.data.replace(/\bNaN\b/g, "null"))
          : junctionsRawData.data;
      const parsedJunctions = raw2junctions(data as JunctionsRawData);
      setJunctions!(parsedJunctions);
      return parsedJunctions;
    });

    setDeletingJunction(null);
  };
  const handleClickDelete = (junction: JunctionMetadataType) => {
    setDeletingJunction(junction);
  };

  // handle ADD
  const handleAddJunction = () => {
    setSelectedJunction({} as JunctionMetadataType);
  };

  const handlePageChange = (event: any, page: number) => {
    setCurPage(page);
  };

  return (
    <Box display="flex" flexDirection="column">
      <List>
        <ListItem key={-1} sx={{ display: "flex", gap: "20%", background: "#EFF5FA" }}>
          <Typography sx={{ marginLeft: "1rem" }} width={"1rem"}>
            #
          </Typography>
          <ListItemText primary={t("uploadNetwork.content.junctionLabel")} sx={{ translate: "-10% 0" }} />
          {editable && (
            <Typography justifySelf="right" color="#245FA6" component="div">
              {t("uploadNetwork.dialog.addJunction")}
            </Typography>
          )}
          {editable && (
            <ListItemSecondaryAction>
              <IconButton edge="end" onClick={handleAddJunction}>
                <MUIAddIcon sx={{ color: "#245FA6" }} />
              </IconButton>
            </ListItemSecondaryAction>
          )}
        </ListItem>
        <Divider></Divider>
        {Object.entries(junctions ?? {})
          .slice((curPage - 1) * itemsPerPage, curPage * itemsPerPage)
          .map(([label, junction], index) => (
            <div
              key={index}
              style={{ background: index === hoveredItem ? "#F5F5F5" : undefined }}
              onMouseEnter={() => setHoveredItem(index)}
              onMouseLeave={() => setHoveredItem(-1)}
            >
              <ListItemButton
                key={index}
                sx={{ height: "3rem", display: "flex", gap: "20%" }}
                onClick={() => {
                  console.log("select junction");
                  handleEditJunction(junction);
                }}
              >
                <Typography sx={{ marginLeft: "1rem" }} width={"1rem"}>
                  {(curPage - 1) * itemsPerPage + index + 1}
                </Typography>
                <ListItemText primary={label} />
                {editable && index === hoveredItem && (
                  <ListItemSecondaryAction>
                    <IconButton edge="end" onClick={() => handleEditJunction(junction)}>
                      <BiEdit />
                    </IconButton>
                    <IconButton edge="end" onClick={() => handleClickDelete(junction)}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                )}
              </ListItemButton>
              <Divider></Divider>
            </div>
          ))}
      </List>
      <Pagination
        style={{ alignSelf: "center", marginBottom: "1rem" }}
        showFirstButton
        showLastButton
        count={Math.ceil(Object.keys(junctions ?? {}).length / itemsPerPage)}
        page={curPage}
        onChange={handlePageChange}
      />
      {editable && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            handleUpload(network_id ?? "");
            setEditable!(false);
            try{setActiveStep!(3);}
            catch(e){
              console.log(e);
            }
            
          }}
        >
          {t("common.confirmAndUpload")}
        </Button>
      )}
      {selectedJunction && (
        <EditJunctionDialog
          create={selectedJunction.LABEL === undefined}
          open={!!selectedJunction}
          selectedJunction={selectedJunction}
          setSelectedJunction={setSelectedJunction}
          network_id={network_id ?? ""}
          setJunctions={setJunctions}
          editable={editable}
        />
      )}
      {deletingJunction && (
        <Dialog
          open={!!deletingJunction}
          onClose={handleCancelDelete}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {t("uploadNetwork.dialog.deleteWarning1")} {deletingJunction.LABEL} {t("uploadNetwork.dialog.deleteWarning2")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancelDelete}>Cancel</Button>
            <Button onClick={handleConfirmDelete} autoFocus>
              {t("common.confirm")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
};

export default NetworkMetadataEditor;
