import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Labels = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  position: relative;
  bottom: 2rem;
`;
const Label = styled.span`
  font-size: 0.8rem;
`;

const PressureColorSlider = styled.div`
  width: 20rem;
  height: 1rem;
  background: linear-gradient(
    -90deg,
    #800026,
    #feb24c,
    #ffffcc
  );
  margin: 1rem;
  border-radius: 0.5rem;
`;

const FlowColorSlider = styled(PressureColorSlider)`
  background: linear-gradient(-90deg, #FF00FF, #64CFF7);
`;



const ColorRangeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  padding: 0.5rem 0.5rem 0;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 1rem;
  z-index: 1;
`;

const ColorRange = () => {
  const { t } = useTranslation();
  console.log("color range");
  return (
    <ColorRangeContainer>
      <PressureColorSlider>
        <Labels>
          <Label>{t("pipelineDetails.lowestNodePressure")}</Label>
          <Label>{t("pipelineDetails.highestNodePressure")}</Label>
        </Labels>
      </PressureColorSlider>

      <FlowColorSlider>
        <Labels>
          <Label>{t("pipelineDetails.lowestPipeFlow")}</Label>
          <Label>{t("pipelineDetails.highestPipeFlow")}</Label>
        </Labels>
      </FlowColorSlider>
    </ColorRangeContainer>
  );
};

export default ColorRange;
