import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Link, Navigate } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import ConfigPage from "./pages/PipelineConfigPage";
import Test from "./pages/Test";
import { NetworkIdProvider } from "./hooks/useNetworkId";
import PipelineMapPage from "./pages/PipelineMapPage";
import { ThemeProvider, createTheme } from "@mui/material";
import { DateProvider } from "./hooks/useDateContext";
import ChartReportPage from "./pages/ChartReportPage";
import AnomalyReportPage from "./pages/AnomalyReportPage";
import { MenuContextProvider } from "./hooks/useMenuInfo";
import RunSimulationPage from "./pages/RunSimulationPage";
import WorkOrderGenerationPage from "./pages/WorkOrderGenerationPage";
import { FilterConfigContextProvider } from "./hooks/useFilterConfig";
import UserConfigPage from "./pages/UserConfigPage";
// import './i18n'

export const smallTypography = {
  fontFamily: ["Montserrat", "Inter"].join(","),
  h1: {
    fontFamily: ["Montserrat", "Inter"].join(","),
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "36px",
    color: "#000A14",
  },
  h2: {
    fontFamily: ["Montserrat", "Inter"].join(","),
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "28px",
  },
  h4: {
    fontFamily: "Montserrat",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "28px",
  },
  h5: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "22px",
  },
  body1: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    // lineGeight: '24px',
  },

  body2: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "22px",
    color: "#666",
  },
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#003366",
    },
    secondary: {
      main: "#19857b",
    },
    error: {
      main: "#ff0000",
    },
    background: {
      default: "#fff",
    },
  },
  typography: {
    fontFamily: ["Montserrat", "Inter"].join(","),
    h1: {
      fontFamily: ["Montserrat", "Inter"].join(","),
      fontSize: "36px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "48px",
      color: "#000A14",
    },
    h2: {
      fontFamily: ["Montserrat", "Inter"].join(","),
      fontSize: "28px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "34px",
    },
    h4: {
      fontFamily: "Montserrat",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "36px",
    },
    h5: {
      fontFamily: "Inter",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "28px",
    },
    body1: {
      fontSize: "16px",
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 500,
    },

    body2: {
      fontFamily: "Inter",
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "28px",
      color: "#666",
    },
  },
});

const App: React.FC = () => {
  useEffect(() => {
    const link = document.createElement("link");
    link.href =
      "https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;1,200;1,300;1,400;1,500&family=Roboto:wght@300;400;500;700&display=swap";
    link.rel = "stylesheet";

    document.head.appendChild(link);

    return () => {
      document.head.removeChild(link);
    };
  }, []);
  return (
    <NetworkIdProvider>
      <MenuContextProvider>
        <DateProvider>
          <FilterConfigContextProvider>
            <ThemeProvider theme={theme}>
              <Router>
                <Routes>
                  <Route path="/" element={<LoginPage />} />
                  <Route path="/user-config" element={<UserConfigPage />} />
                  <Route path="/map" element={<PipelineMapPage />} />
                  <Route path="/chart-report" element={<ChartReportPage />} />
                  <Route path="/anomaly-report" element={<AnomalyReportPage />} />
                  <Route path="/pipeline-config" element={<ConfigPage />} />
                  <Route path="/run-simulation" element={<RunSimulationPage />} />
                  <Route path="/work-order-generation" element={<WorkOrderGenerationPage />} />
                  <Route path="/test" element={<Test />} />
                  <Route path="*" element={<Navigate to="/" />} />
                </Routes>
              </Router>
            </ThemeProvider>
          </FilterConfigContextProvider>
        </DateProvider>
      </MenuContextProvider>
    </NetworkIdProvider>
  );
};

export default App;
