import { none } from "ol/centerconstraint";

export function junctionFileCheck(fileContent: string): boolean {
  // Split by lines
  const lines = fileContent.trim().split("\n");
  // Extract the headers
  const headers = lines[0].split(",");

  // Verify expected headers are present
  const expectedHeaders = ["LABEL", "ELEV_m", "GPS_LATITUDE", "GPS_LONGITUDE", "KNOWN_PRESSURE", "NODE_TYPE"];
  if (!expectedHeaders.every((header) => headers.includes(header))) {
    return false;
  }

  // Mapping headers to their index for easier access
  const headerIndices: { [key: string]: number } = {};
  headers.forEach((header, index) => {
    headerIndices[header] = index;
  });

  // Validate each row
  for (let i = 1; i < lines.length; i++) {
    const values = lines[i].split(",");

    // Validate LABEL - string only
    if (typeof values[headerIndices["LABEL"]] !== "string") return false;

    // Validate ELEV_m, GPS_LATITUDE, GPS_LONGITUDE - number only
    const numericColumns = ["ELEV_m", "GPS_LATITUDE", "GPS_LONGITUDE"];
    for (const column of numericColumns) {
      if (isNaN(Number(values[headerIndices[column]]))) return false;
    }

    // Validate KNOWN_PRESSURE - True or False only
    const knownPressureValue = values[headerIndices["KNOWN_PRESSURE"]];
    if (knownPressureValue !== "True" && knownPressureValue !== "False") return false;

    // Validate NODE_TYPE - Only either E, U, K, I
    if (!["E", "U", "K", "I",null].includes(values[headerIndices["NODE_TYPE"]])) return false;
  }

  return true;
}

export function pipeFileCheck(fileContent: string, nodesLabels: Set<string>): boolean {
  // Split by lines
  const lines = fileContent.trim().split("\n");

  // Extract the headers
  const headers = lines[0].split(",");

  // Verify expected headers are present
  const expectedHeaders = ["START_NODE", "STOP_NODE", "LENGTH_m", "D_mm", "LABEL", "MATERIAL", "PDMA", "VALVE"];
  if (!expectedHeaders.every((header) => headers.includes(header))) {
    console.log(1, "Verify expected headers are present");
    return false;
  }

  // Mapping headers to their index for easier access
  const headerIndices: { [key: string]: number } = {};
  headers.forEach((header, index) => {
    headerIndices[header] = index;
  });

  // Validate each row
  for (let i = 1; i < lines.length; i++) {
    const values = lines[i].split(",");

    // Validate START_NODE and STOP_NODE - string only and is in the LABEL of the Nodes file
    if (!nodesLabels.has(values[headerIndices["START_NODE"]]) || !nodesLabels.has(values[headerIndices["STOP_NODE"]])) {
      console.log(2, "Validate START_NODE and STOP_NODE - string only and is in the LABEL of the Nodes file");
      return false;
    }

    // Validate LENGTH_m and ROUGHNESS and D_mm - number only
    const numericColumns = ["LENGTH_m", "D_mm"];
    for (const column of numericColumns) {
      console.log(3, "Validate LENGTH_m and D_mm - number only");

      if (isNaN(Number(values[headerIndices[column]]))) return false;
    }

    // Validate LABEL, MATERIAL, PDMA, VALVE - string only
    const stringColumns = ["LABEL", "MATERIAL", "PDMA", "VALVE"];
    for (const column of stringColumns) {
      if (typeof values[headerIndices[column]] !== "string") {
        console.log(4, "Validate LABEL, MATERIAL, PDMA, VALVE - string only");

        return false;
      }
    }
  }

  return true;
}

export function dataFileCheck(fileContent: string): boolean {
  // Split by lines
  const lines = fileContent.trim().split("\n");
  console.log(lines, typeof lines);

  // Extract the headers
  const headers = lines[0].split(",");

  // Mapping headers to their index for easier access
  const headerIndices: { [key: string]: number } = {};
  headers.forEach((header, index) => {
    headerIndices[header] = index;
  });

  // For simplicity, I'll check if it has the 'YYYY-MM-DD' format.
  const date = new Date(lines[1].split(",")[headerIndices["t_stamp"]]);
  // If the date is invalid, it will return "Invalid Date" for `toString()`
  if (date.toString() === "Invalid Date") {
    // console.log(1);
    return false;
  }

  // Check mandatory columns
  const mandatoryHeaders = ["t_stamp", "Inlet_Flow_m3h", "Inlet_Pressure_Pa"];
  if (!mandatoryHeaders.every((header) => headers.includes(header))) {
    // console.log(2);
    return false;
  }

  // At least one column has "Pressure_Pa" in name (excluding 'Inlet_Pressure_Pa') and has numeric data
  const pressureColumns = headers.filter((col) => col.includes("Pressure_Pa") && col !== "Inlet_Pressure_Pa");
  if (pressureColumns.length === 0) {
    // console.log(3);
    return false;
  }

  // At least one column has "Totalizer" in the name and has numeric data
  const totalizerColumns = headers.filter((col) => col.includes("Totalizer"));
  if (totalizerColumns.length === 0) {
    // console.log(4);
    return false;
  }

  return true;
}
