import styled from "@emotion/styled";
import { Box, Divider, List, ListItem, ListItemText, Typography } from "@mui/material";
import { FeatureLike } from "ol/Feature";
import React from "react";
import { isNumeric } from "../pipeline_config/FileUploadStepper";
import InfoTooltip from "../utils/InfoIcon";
import { useTranslation } from "react-i18next";

export interface FeatureInfoProps {
  feature: FeatureLike;
  stats?: { [key: string]: string | number };
  meta?: { [key: string]: string | number };
}

const STATS: string[] = [];
const META: string[] = [
  "GPS_LATITUDE",
  "GPS_LONGITUDE",
  "START_NODE",
  "STOP_NODE",
  // "roughness",
  "start_node_elevation",
  "if_valve",
  "dia",
  "valves",
  "length",
  "end_node_elevation",
  "material",
  "valve_state",
  // "LABEL",
  // "Label",
  // "p_in",
  // "p_out",
  // "Reynolds",
  // "hf",
  // "flow_rates",
  // "friction_factor",
  // "start_HGL",
  // "end_HGL",
  // "p_list",
  // "NODE_TYPE",
  "ELEV",
  "LENGTH",
  "MATERIAL",
  "D",
  "PDMA",
  "VALVE",
  "VALVE_STATUS",
  "D_mm",
  "LENGTH_m",
  // "ROUGHNESS",
  "MATERIAL",
];

// Node:
// ELEV to Elevation
// GPS_LATITUDE to GPS Latitude
// GPS_LONGITUDE to GPS Longitude
// Pipe:
// start_node_elevation to Start Node Elevation
// end_node_elevation to End Node Elevation
// material to Material
// dia to Diameter
// START_NODE to Start Junction
// END_NODE to End Junction
// VALVE to Valve Name
// length to Length

const unitMapping: { [key: string]: string } = {
  elevation: "m",
  diameter: "m",
  length: "m",
  startNodeElevation: "m",
  endNodeElevation: "m",
};

const nodeNameMapping: { [key: string]: string } = {
  ELEV: "elevation",
  GPS_LATITUDE: "GPSLatitude",
  GPS_LONGITUDE: "GPSLongitude",
};
const pipeNameMapping: { [key: string]: string } = {
  Label: "label",
  start_node_elevation: "startNodeElevation",
  end_node_elevation: "endNodeElevation",
  material: "material",
  dia: "diameter",
  START_NODE: "startNode",
  STOP_NODE: "stopNode",
  END_NODE: "endJunction",
  VALVE: "valveName",
  length: "length",
};

const FeatureInfoCardWrapper = styled(Box)`
  display: inline-flex;
  padding: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  color: #000a14;
`;

// make it a table or something else later for better display
const FeatureInfoCard: React.FC<FeatureInfoProps> = ({ feature, stats, meta }) => {
  // console.log(feature.getProperties());
  const attr = feature.getProperties().attributes;
  const transformedAttr: { [key: string]: any } = {};
  const { t } = useTranslation();
  attr.NODE_TYPE
    ? Object.entries(attr)
        // filter the properties we need
        .filter(([key, value], idx) => META.includes(key) && !!value)
        // change the key and value
        .map(([key, value]) => {
          const newKey = nodeNameMapping[key] || key; // Use the mapped key, or the original if no mapping exists
          if (["GPSLatitude", "GPSLongitude"].includes(newKey)) transformedAttr[newKey] = "GPS DATA";
          else transformedAttr[newKey] = value;
        })
    : Object.entries(attr)
        .filter(([key, value], idx) => META.includes(key) && !!value)
        .map(([key, value]) => {
          const newKey = pipeNameMapping[key] || key; // Use the mapped key, or the original if no mapping exists
          transformedAttr[newKey] = value;
        });

  console.log("transformedAttr");
  console.log(transformedAttr);

  return attr.NODE_TYPE ? (
    <FeatureInfoCardWrapper>
      <Typography>
        {t("networkType.node")}{" "}
        <InfoTooltip
          html={
            <div
              style={{
                position: "absolute",
                width: "200px",
                top: "90%",
                padding: "10px",
                backgroundColor: "#f9f9f9",
                border: "1px solid #ddd",
                boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
                zIndex: 1,
              }}
            >
              {t("info.featureCard", "Elevation is above the sea level")}
            </div>
          }
        />
      </Typography>
      <Typography variant="h2">{attr.LABEL}</Typography>
      {/* <List sx={{ border: "1px solid #CCD6E0", borderRadius: "8px", padding:'0' }}> */}
      <List sx={{ border: "1px solid #CCD6E0", borderRadius: "8px", maxHeight: "10rem" }}>
        {Object.entries(transformedAttr).map(([key, value], idx) => (
          <Box key={idx} sx={{ display: "flex", flexDirection: "column" }}>
            {idx > 0 && <Divider></Divider>}
            <Box
              justifyContent="space-between"
              sx={{ margin: "0 1rem", display: "flex", gap: "2rem", justifyContent: "space-between" }}
            >
              <Typography component="div">{t(`property.${key}`)}</Typography>
              <Typography component="div">
                {isNumeric(value as string) ? (value as number).toFixed(2) : (value as string)}
                {unitMapping[key] ?? ""}
              </Typography>
            </Box>
          </Box>
        ))}
      </List>
    </FeatureInfoCardWrapper>
  ) : (
    <FeatureInfoCardWrapper>
      <Typography>
        {t("networkType.pipe")}{" "}
        <InfoTooltip
          html={
            <div
              style={{
                position: "absolute",
                width: "200px",
                top: "90%",
                padding: "10px",
                backgroundColor: "#f9f9f9",
                border: "1px solid #ddd",
                boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
                zIndex: 1,
              }}
            >
              {t("info.featureCard", "Elevation is above the sea level")}
            </div>
          }
        />
      </Typography>
      <Typography variant="h2">{attr.Label}</Typography>
      {/* <List sx={{ border: "1px solid #CCD6E0", borderRadius: "8px", padding:'0' }}> */}
      <List sx={{ border: "1px solid #CCD6E0", borderRadius: "8px", maxHeight: "10rem", overflowY: "scroll" }}>
        {Object.entries(transformedAttr).map(([key, value], idx) => (
          <Box key={idx} sx={{ display: "flex", flexDirection: "column" }}>
            {idx > 0 && <Divider></Divider>}
            <Box
              justifyContent="space-between"
              sx={{ margin: "0 1rem", display: "flex", gap: "2rem", justifyContent: "space-between" }}
            >
              <Typography component="div">{t(`property.${key}`)}</Typography>
              <Typography component="div">
                {isNumeric(value as string) ? (value as number).toFixed(2) : (value as string)}
                {unitMapping[key] ?? ""}
              </Typography>
            </Box>
          </Box>
        ))}
      </List>
    </FeatureInfoCardWrapper>
  );
};

{
  /* <div style={{ margin: "1rem" }}>
{feature && (
  <div>
    <h4>{label}</h4>
    <strong>Metadata</strong>
    {Object.entries(feature)
      .filter(([key, value]) => META.includes(key) && !!value)
      .map(([key, value]) => (
        <div key={key}>
          <strong>{key}:</strong> {key === "GPS_LATITUDE" || key === "GPS_LONGITUDE"? "GPS_DATA": value}
        </div>
      ))}
  </div>
)}
</div> */
}

export default FeatureInfoCard;

export const ShowFeature: React.FC<{ feature: FeatureLike }> = ({ feature }) => {
  const { t } = useTranslation();
  const attr = feature.getProperties().attributes;
  return attr.NODE_TYPE ? (
    <div>
      <Typography align="center" sx={{ margin: "1rem 1rem 0" }}>
        {attr.LABEL}
      </Typography>
      {/* Real GPS Data */}
      {/* <Typography sx={{ margin: "0 1rem" }}>{`${t("findLeaksandAnomalies.content.location")}: (${Number(
        attr.GPS_LONGITUDE
      ).toFixed(3)}, ${Number(attr.GPS_LATITUDE).toFixed(3)})`}</Typography> */}

      {/* GPS data dummy */}
      <Typography sx={{ margin: "0 1rem" }}>{`${t("findLeaksandAnomalies.content.location")}: (GPS_DATA, GPS_DATA)`}</Typography>
      <Typography sx={{ margin: "0 1rem" }}>{`${t("property.elevation")}: ${attr.ELEV_m ?? attr.ELEV}m`}</Typography>
      <Typography sx={{ margin: "0 1rem 1rem" }}>{`${t("property.nodeType")}: ${attr.NODE_TYPE}`}</Typography>
    </div>
  ) : (
    <div>
      <Typography align="center" sx={{ margin: "1rem 1rem 0" }}>
        {attr.LABEL}
      </Typography>
      <Typography sx={{ margin: "0 1rem" }}>{`${t("property.startNode")}: ${attr.START_NODE}`}</Typography>
      <Typography sx={{ margin: "0 1rem" }}>{`${t("property.stopNode")}: ${attr.STOP_NODE}`}</Typography>
      <Typography sx={{ margin: "0 1rem" }}>{`${t("property.length")}: ${attr.LENGTH_m ?? attr.length}m`}</Typography>
      <Typography sx={{ margin: "0 1rem 1rem" }}>
        {attr.D_mm ? `${t("property.diameter")}: ${attr.D_mm}mm` : `Diameter: ${attr.dia}m`}
      </Typography>
    </div>
  );
};
