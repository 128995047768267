import React, { useCallback, useEffect, useRef, useState } from "react";
import Navbar, { PageWrapper } from "../components/utils/Navbar";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Snackbar,
  Typography,
  useTheme,
} from "@mui/material";
import PageHeader from "../components/utils/PageHeader";
import { FileRejection, useDropzone } from "react-dropzone";
import { convertCsvToJson, formatFileSize } from "../components/pipeline_config/FileUploadStepper";
import axios from "axios";
import { API_URL } from "../constants";
import { useNetworkId } from "../hooks/useNetworkId";
import { BiUpload } from "react-icons/bi";
import { BsFiletypeCsv } from "react-icons/bs";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DeleteIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CircularWithValueLabel from "../components/run_simulation/CircularProgress";
import Fade from "@mui/material/Fade";
import InfoTooltip from "../components/utils/InfoIcon";
import { dataFileCheck } from "../utils/FileChecker";
import { useTranslation } from "react-i18next";

const RunSimulationPage = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [finished, setFinished] = useState<boolean>(false);

  // const [network_id, setNetwork_id] = useState<string>("");
  const [dataFiles, setDataFiles] = useState<File[]>([]); // histroical data files
  const [uploadingFileIdx, setUploadingFileIdx] = useState<number>(-1); // index of the file under processing, used to show the process
  const [uploadProgress, setUploadProgress] = useState(0); // to show the process circular icon
  const [uploadFinished, setUploadFinished] = useState<boolean>(false); // used to show the snackabar feedback
  const [selectFileAlert, setSelectFileAlert] = useState<boolean>(false); // used to show warning when users didn't upload files but click upload
  const [fileCheckFailed, setFileCheckFailed] = useState<boolean>(false); //
  const [invalidFiles, setInvalidFiles] = useState<string[]>([]); // used to show which files are failed to upload
  const theme = useTheme();
  const { t } = useTranslation();

  const fileSet = useRef<Set<string>>(new Set());
  const { fetchNetworkId, setNetworkId, networkId: network_id } = useNetworkId();
  // console.log('network_id');
  // console.log(network_id);

  const tableDataInfo = `
  <h4>${t("info.tableNodeInfo.title")}</h4>
  <h3>${t("info.tableDataInfo.description","Data File: Please make sure all junctions, pmcs are in the junctions file to ensure the program accuracy")}.</h3>
  <table border="1" style="width:100% ">
    <tr>
      <th>t_stamp</th>
      <th>Inlet_Pressure_Pa</th>
      <th>Inlet_Flow_m3h</th>
      <th>Junction(s)_Pressure</th>
      <th>pmc_Totalizer</th>
      <th>Valve(s)_Config</th>
    </tr>
    <tr>
      <td>${t("info.tableDataInfo.t_stampDescription","t_stamp need to be in datetime format")}<br><b>(Year-Month-Day Hour:Minute:Second)</b></td>
      <td>${t("info.tableDataInfo.Inlet_Pressure_PaDescription","The starting pressure of the pipeline")}</td>
      <td>${t("info.tableDataInfo.Inlet_Flow_m3hDescription","The starting flow of the pipeline")}</td>
      <td style="word-wrap: break-word; overflow-wrap: break-word;">${t("info.tableDataInfo.Junction_PressureDescription","Each junction will have their own pressure column and flow column if they have")}. 
      ${t("info.tableDataInfo.ColumnFormat","Column format")}: <b>[junction-name]_Pressure_Pa</b> ${t("info.tableDataInfo.PaUnit","for pressure in Pa unit or")} <b>[junction-name]_Flow_m3h</b> ${t("info.tableDataInfo.m3hUnit","for flow in m3h unit")}
</td>
      <td style="word-wrap: break-word; overflow-wrap: break-word;">${t("info.tableDataInfo.pmc_TotalizerDescription","Each pmc will have their own Totalizer column")}. ${t("info.tableDataInfo.ColumnNameFormat","Column name format")}: <b>[pmc-name]_Totalizer</b>.</td>
      <td style="word-wrap: break-word; overflow-wrap: break-word;">${t("info.tableDataInfo.Valve_ConfigDescription","Please make sure each valve (control point) has their own column. 0 for close and 1 for open during each timestamp")}.</td>
    </tr>
  </table>
`;

  // fetch networkId
  useEffect(() => {
    fetchNetworkId().then((nid) => setNetworkId(nid));
  }, []);

  const onDrop = useCallback((acceptedFiles: File[], fileRejections: FileRejection[]) => {
    setInvalidFiles([]);
    acceptedFiles
      .filter((file) => !fileSet.current.has(file.name))
      .forEach((file) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          if (!dataFileCheck(event.target?.result as string)) {
            setFileCheckFailed(true);
            setInvalidFiles((prev) => {
              prev.push(file.name);
              return prev;
            });
          } else {
            fileSet.current.add(file.name);
            setDataFiles((prefile) => [...prefile, file]);
          }
        };
        reader.readAsText(file);
      });

    fileRejections.forEach((file) => {
      console.log("wrong file:", file.file.name);
    });
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "text/csv": [".csv"],
    },
  });

  // make it sync
  const handleUploadDataFile = async () => {
    for (const file of dataFiles) {
      setUploadingFileIdx((prev) => prev + 1);
      setUploadProgress(0);

      console.log(file.name);

      // wrap FileReader in a promise
      const csv: string = await new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onabort = () => reject("file reading was aborted");
        reader.onerror = () => reject("file reading has failed");
        reader.onload = (event) => resolve(event.target?.result as string);
        reader.readAsText(file);
      });

      const temp_json: any = convertCsvToJson(csv);
      try {
        const response = await axios.post(
          API_URL + "/dailydata/" + network_id,
          {
            daily_data: temp_json,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
            onUploadProgress: (progressEvent) => {
              if (progressEvent?.total) setUploadProgress(Math.round((progressEvent.loaded * 100) / progressEvent?.total));
            },
          }
        );
        console.log(response.data);
      } catch (error) {
        console.log(error);
      } finally {
      }
    }
    // show success toast, reset the status
    if (dataFiles.length > 0) setUploadFinished(true);
    else setSelectFileAlert(true);
    fileSet.current.clear();
    setDataFiles([]);
    setUploadingFileIdx(-1);
  };
  /*
  const handleRunSimulation = async () => {
    setLoading(true);
    await axios
      .post(`${API_URL}/network/simulation/${network_id}`)
      .then((response) => {
        console.log("sim successful", response.data);
        setFinished(true);
      })
      .finally(() => {setLoading(false)});
  };*/

  return (
    <div>
      <div style={{ display: "flex" }}>
        <Navbar />
        <PageWrapper>
          <Box display="flex" flexDirection="column" alignItems="start">
            <PageHeader title={t("connectData.title")} description={t("connectData.description")} />
            <div
              style={{
                marginTop: "24px",
                paddingLeft: "15%",
                // boxSizing: "border-box",
                width: "65%",
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                position: "relative",
              }}
            >
              <Box>
                <Typography component={"span"} variant="h2" color="#000A14">
                  {t("connectData.content.title")}
                </Typography>
                <InfoTooltip info={tableDataInfo}></InfoTooltip>
              </Box>

              <Box
                {...getRootProps()}
                sx={{
                  cursor: "pointer",
                  border: isDragActive ? " 2px dashed #245FA6" : "2px dashed #CCCCCC",
                  padding: "2rem",
                  width: "100%",
                  height: "200px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "2rem 0",
                  boxSizing: "border-box",
                  borderRadius: "8px",
                  background: isDragActive ? "#EFF5FA" : undefined,
                }}
              >
                <input {...getInputProps()} />
                <BiUpload size="2rem" color="#666666" />
                <Typography variant="h5" fontWeight={400} margin="1rem">
                  {t("dnd.intro")} {t("dnd.historicalData")}
                </Typography>
                <Typography variant="h5" fontWeight={400}>
                  {t("dnd.or")}{" "}
                  <Typography
                    variant="h5"
                    fontWeight={400}
                    color="#245FA6"
                    sx={{ textDecorationLine: "underline" }}
                    component={"span"}
                  >
                    {t("dnd.selectFile")}
                  </Typography>
                </Typography>
              </Box>
              {dataFiles.length > 0 && (
                <Box
                  sx={{
                    marginBottom: " 2rem",
                    border: "1px solid #CCD6E0",
                    width: "100%",
                    boxSizing: "border-box",
                    padding: "1rem",
                    borderRadius: "8px",
                  }}
                >
                  {dataFiles.map((file, idx) => (
                    // can be seperated to a component
                    <Box
                      key={idx}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        background: "#EFF5FA",
                        margin: "8px 0",
                        padding: "16px 24px;",
                        borderRadius: "8px",
                      }}
                    >
                      <Box display="flex" gap="3rem">
                        <BsFiletypeCsv size={"2.5rem"} />
                        <Box display="flex" flexDirection="column">
                          <Typography variant="body2">{file.name}</Typography>
                          <Typography variant="body2">{formatFileSize(file.size)}</Typography>
                        </Box>
                      </Box>
                      {uploadingFileIdx === -1 ? (
                        <IconButton
                          onClick={() => {
                            fileSet.current.delete(file.name);
                            setDataFiles((prev) => prev.filter((_, i) => i !== idx));
                          }}
                          sx={{ justifySelf: "end", padding: uploadingFileIdx > idx ? "4px 4px" : "8px" }}
                        >
                          <DeleteIcon sx={{ fontSize: "2rem" }} />
                        </IconButton>
                      ) : (
                        <IconButton sx={{ justifySelf: "end", padding: uploadingFileIdx > idx ? "4px 4px" : "8px" }}>
                          {uploadingFileIdx === idx && <CircularWithValueLabel size="40px" value={uploadProgress} />}
                          {uploadingFileIdx > idx && <CheckCircleOutlineIcon sx={{ color: "#4CAF50", fontSize: "40px" }} />}
                        </IconButton>
                      )}
                    </Box>
                  ))}
                </Box>
              )}
              <Button
                sx={{ borderRadius: "8px", padding: "0.5rem", textTransform: "none", marginBottom: "2rem" }}
                variant="contained"
                fullWidth
                onClick={handleUploadDataFile}
              >
                {t("common.upload")}
              </Button>
              <Snackbar
                open={uploadFinished}
                autoHideDuration={5000}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                TransitionComponent={Fade}
                sx={{ boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.2)", borderRadius: "8px" }}
                onClose={() => setUploadFinished(false)}
              >
                <Alert severity="info" sx={{ width: "100%" }} onClose={() => setUploadFinished(false)}>
                  {t("common.fileUploadSuccessfully")}
                </Alert>
              </Snackbar>
              <Snackbar
                open={selectFileAlert}
                autoHideDuration={5000}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                TransitionComponent={Fade}
                sx={{ boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.2)", borderRadius: "8px" }}
                onClose={() => setSelectFileAlert(false)}
              >
                <Alert severity="info" sx={{ width: "100%" }} onClose={() => setSelectFileAlert(false)}>
                  {t("error.selectFileFirst")}
                </Alert>
              </Snackbar>
            </div>
          </Box>
        </PageWrapper>
        <Snackbar
          open={fileCheckFailed}
          autoHideDuration={5000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          sx={{ boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.2)", borderRadius: "8px" }}
          onClose={() => setFileCheckFailed(false)}
        >
          <Alert severity="warning">{`${t("error.TheFile")} [${invalidFiles.toString()}] ${t("error.isNotValid")}`}</Alert>
        </Snackbar>
      </div>
    </div>
  );
};

export default RunSimulationPage;
