import { Box, MenuItem, Select, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import Navbar, { PageWrapper } from "../components/utils/Navbar";
import PageHeader from "../components/utils/PageHeader";

const UserConfigPage = () => {
  const { t, i18n } = useTranslation();
  return (
    <div style={{ display: "flex" }}>
      <Navbar />
      <PageWrapper>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <PageHeader title={t("menu.languageSelection")} description={""} />
          <Box display="flex" gap="1rem" alignContent="center" margin="1rem 2rem">
            <Box display="flex" justifyContent="center" alignItems="center" textAlign="center">
              <Typography variant="h2">{t("common.language")}</Typography>
            </Box>
            <Select value={i18n.language} onChange={(e) => i18n.changeLanguage(e.target.value)}>
              <MenuItem value={"zh"}>中文</MenuItem>
              <MenuItem value={"en"}>English</MenuItem>
              <MenuItem value={"vn"}>Tiếng Việt</MenuItem>
              <MenuItem value={"th"}>แบบไทย</MenuItem>
            </Select>
          </Box>
        </div>
      </PageWrapper>
    </div>
  );
};

export default UserConfigPage;
