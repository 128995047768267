import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, TextField, Container, Typography, Box, useTheme, InputAdornment, IconButton, Divider } from "@mui/material";
import styled from "styled-components";
import axios from "axios";
import { API_URL } from "../constants";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Logo from "../icons/beaglelogo 2.png";
import LoginImage from "../icons/login_image.jpeg";
import { useTranslation } from "react-i18next";

const PageWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  margin: 0;
  padding: 0;
`;

const LoginContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

const LoginContent = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 48%;
  height: 45%;
`;

const LoginPage: React.FC = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleLogin = () => {
    axios
      .post(
        `${API_URL}/login`,
        {
          username,
          password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => { if (response.data==true){
        console.log(response)
        navigate("/pipeline-config")}
        else{
          alert("Invalid username or password");
        }
      })
      .catch((error) => {
        alert("Invalid username or password");
      });
  };

  return (
    <PageWrapper>
      <LoginContainer>
        <Box display="flex" alignItems="center" gap="2rem" position="absolute" top="12%" left="16%">
          <img
            style={{
              width: "70px",
              height: "70px",
              borderRadius: "50%",
              objectFit: "cover",
              objectPosition: "50% 30%",
            }}
            src={Logo}
            alt="logo"
          />
          <Typography component="span" fontSize="48px" fontFamily="Montserrat">
            GAILL
          </Typography>
        </Box>
        <LoginContent>
          <Typography variant="h4" marginBottom={"1rem"} marginLeft={"0.2rem"}>
            {t("loginPage.welcome")}
          </Typography>
          <Typography variant="h2" margin={"1rem 0"}>
            {t("loginPage.login")}
          </Typography>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label={t("loginPage.username")}
            name="username"
            autoComplete="username"
            autoFocus
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label={t("loginPage.password")}
            type={showPassword ? "text" : "password"}
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            style={{ textTransform: "none" }}
            onClick={handleLogin}
          >
            {t("loginPage.logIn")}
          </Button>
        </LoginContent>
      </LoginContainer>
      <img src={LoginImage} style={{ objectFit: "cover", height: "100vh", width: "30rem" }} />
    </PageWrapper>
  );
};

export default LoginPage;
