import { Box, Container, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import styled from "styled-components";
import axios from "axios";

import { API_URL } from "../constants";
import NetworkMetadataEditor from "../components/pipeline_config/NetworkMetadataEditor";
import Navbar, { PageWrapper } from "../components/utils/Navbar";
import PageHeader from "../components/utils/PageHeader";
import FileUploadStepper from "../components/pipeline_config/FileUploadStepper";
import InfoTooltip from "../components/utils/InfoIcon";
import { useNetworkId } from "../hooks/useNetworkId";
import { useTranslation } from "react-i18next";

const FileUploadBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-height: 100vh;
  /* overflow: scroll; */
  flex: 1;
`;

// a pipe's info
export interface PipeMetadataType {
  LABEL: string;
  START_NODE: string;
  STOP_NODE: string;
  LENGTH_m: number;
  D_mm: number;
  MATERIAL: string;

  VALVE?: boolean;
  VALVE_STATUS?: string;
}

export interface JunctionMetadataType {
  LABEL: string;
  GPS_LONGITUDE: number;
  GPS_LATITUDE: number;
  ELEV_m: number;
  NODE_TYPE: string;
  KNOWN_PRESSURE?: boolean;
  PDMA?: string;
}

export type JunctionMap = Record<string, JunctionMetadataType>; // key is LABEL
export type PipeMap = Record<string, PipeMetadataType>;

export interface NetworkMetadataProps {
  network_id: string;
  pipes: PipeMap;
  junctions: JunctionMap;
  setPipes: React.Dispatch<React.SetStateAction<PipeMap>>;
  setJunctions: React.Dispatch<React.SetStateAction<JunctionMap>>;
}
// data format form the request
export interface JunctionsRawData {
  ELEV_m: number[];
  GPS_LATITUDE: number[];
  GPS_LONGITUDE: number[];
  KNOWN_PRESSURE: boolean[];
  LABEL: string[];
  NODE_TYPE: string[];
}
export interface PipesRawData {
  LABEL: string[];
  D_mm: number[];
  LENGTH_m: number[];
  MATERIAL: string[];
  PDMA?: number[];
  START_NODE: string[];
  STOP_NODE: string[];
  VALVE: boolean[];
  VALVE_STATUS: string[];
}

const fakeJunctionMetadata: JunctionMap = {
  // "J-1": { LABEL: "J-1", ELEV_m: 10, GPS_LATITUDE: 10, GPS_LONGITUDE: 37.1, NODE_TYPE: "U" },
};
const fakePipeMetadata: PipeMap = {
  // Pipe_1: { LABEL: "Pipe_1", START_NODE: "J-2", STOP_NODE: "J-1", LENGTH_m: 80, D_mm: 100, MATERIAL: "DI", ROUGHNESS: 0.061 },
};

// parse raw data {[column]: arrays} to JunctionMap
export const raw2junctions = (rawJunctions: JunctionsRawData): JunctionMap => {
  if (!rawJunctions || !rawJunctions.LABEL) return {};

  return rawJunctions.LABEL.reduce<JunctionMap>((accumulator, label, index) => {
    if (!label) {
      return accumulator;
    }
    return {
      ...accumulator,
      [label]: {
        LABEL: label,
        GPS_LONGITUDE: rawJunctions.GPS_LONGITUDE[index],
        GPS_LATITUDE: rawJunctions.GPS_LATITUDE[index],
        ELEV_m: rawJunctions.ELEV_m[index],
        NODE_TYPE: rawJunctions.NODE_TYPE[index],
        KNOWN_PRESSURE: rawJunctions.KNOWN_PRESSURE[index],
      },
    };
  }, {});
};
// parse raw data {[column]: arrays} to PipeMap
export const raw2pipes = (rawPipes: PipesRawData): PipeMap => {
  if (!rawPipes || !rawPipes.LABEL) return {};

  return rawPipes.LABEL.reduce<PipeMap>((accumulator, label, index) => {
    if (!label) {
      return accumulator;
    }
    return {
      ...accumulator,
      [label]: {
        LABEL: label,
        D_mm: rawPipes.D_mm[index],
        LENGTH_m: rawPipes.LENGTH_m[index],
        MATERIAL: rawPipes.MATERIAL[index],
        PDMA: rawPipes.PDMA ? rawPipes.PDMA[index] : null,
        START_NODE: rawPipes.START_NODE[index],
        STOP_NODE: rawPipes.STOP_NODE[index],
        VALVE: rawPipes.VALVE[index],
        // VALVE_STATUS: rawPipes.VALVE_STATUS[index],
      },
    };
  }, {});
};

const PipelineConfigPage: React.FC = () => {
  const [junctions, setJunctions] = useState<JunctionMap>({} as JunctionMap); // junction map: {[Label]: {metadata}}
  const [pipes, setPipes] = useState<PipeMap>({} as PipeMap); // pipe map: {[Label]: {metadata}}
  const [nid, setNid] = useState<string>(""); // network_id
  const [isFetching, setIsFetching] = useState<boolean>(true); // load FileUploadStepper component after fetching the data
  const { t } = useTranslation();

  const { fetchNetworkId, setNetworkId } = useNetworkId();
  useEffect(() => {
    const fetchData = async () => {
      try {
        // get the network_id, use the last model(new created)
        const network_id = await fetchNetworkId().then((nid) => {
          setNid(nid);
          setNetworkId(nid);
          return nid;
        });

        // fetch raw junctions in a form of {[column]: arrays}
        await axios.get<string | JunctionsRawData>(API_URL + "/network/junctions/" + network_id).then((junctionsRawData) => {
          // console.log(API_URL + "/network/junctions/" + network_id);
          console.log("junctionsRawData", junctionsRawData);

          // parse raw data {[column]: arrays} to JunctionMap
          const parsedJunctions = raw2junctions(
            // if return data contains NaN, then it will be a string type instead of Json
            typeof junctionsRawData.data === "string"
              ? (JSON.parse(junctionsRawData.data.replace(/\bNaN\b/g, "null")) as JunctionsRawData)
              : junctionsRawData.data
          );
          setJunctions(parsedJunctions);
          return parsedJunctions;
        });

        // fetch raw pipe in a form of {[column]: arrays}, same as above
        await axios.get<string | PipesRawData>(API_URL + "/network/pipes/" + network_id).then((pipesRawData) => {
          console.log("pipesRawData", pipesRawData);
          const parsedPipes = raw2pipes(
            // if response contains NaN which is not a JSON format, replace it with null
            typeof pipesRawData.data === "string"
              ? (JSON.parse(pipesRawData.data.replace(/\bNaN\b/g, "null")) as PipesRawData)
              : pipesRawData.data
          );

          setPipes(parsedPipes);

          return parsedPipes;
        });
      } catch (error) {
        console.error("An error occurred while fetching data:", error);
      } finally {
        // fetching data successfully, it's used for the map
        setIsFetching(false);
      }
    };
    fetchData();
  }, []);

  return (
    <div style={{ display: "flex" }}>
      <Navbar />
      <PageWrapper>
        <Box display="flex" flexDirection="column" alignItems="start">
          <PageHeader title={t("uploadNetwork.title")} description={t("uploadNetwork.description")} />
          <div
            style={{
              marginTop: "24px",
              paddingLeft: "15%",
              width: "65%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography variant="h1">{t("uploadNetwork.content.title")}</Typography>
            <Typography variant="body2" margin="8px 0 16px">
              {t("uploadNetwork.content.description")}
            </Typography>

            {/* load component after fetching the data */}
            {isFetching || (
              <FileUploadStepper
                pipes={pipes}
                junctions={junctions}
                setPipes={setPipes}
                setJunctions={setJunctions}
                network_id={nid}
              />
            )}
          </div>
        </Box>
      </PageWrapper>
    </div>
  );
};

export default PipelineConfigPage;
