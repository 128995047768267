import axios from "axios";
import React, { useEffect, useState } from "react";
import { API_URL } from "../constants";

type NetworkIdContextType = {
  fetchNetworkId: () => Promise<string>;
  networkId: string;
  setNetworkId: (networId:string)=>void;
};
// Create a new context that provides a function which returns a Promise<string>.
const NetworkIdContext = React.createContext<NetworkIdContextType>({
  fetchNetworkId: async () => {
    try {
      const response = await axios.get(API_URL + "/models/test_model/leak_detection");
      return response.data[Object.keys(response.data).length - 1];
    } catch (e) {
      console.log("couldn't fetch the network_id");
      throw e;
    }
  },
  networkId: "",
  setNetworkId: ()=>{}
});

export const NetworkIdProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [networkId, setNetworkId] = useState<string>("");
  const fetchNetworkId= async () => {
    try {
      const response = await axios.get(API_URL + "/models/test_model/leak_detection");
      return response.data[Object.keys(response.data).length - 1];
    } catch (e) {
      console.log("couldn't fetch the network_id");
      throw e;
    }
  }

  return <NetworkIdContext.Provider value={{fetchNetworkId, networkId, setNetworkId}}>{children}</NetworkIdContext.Provider>;
};

export const useNetworkId = () => React.useContext(NetworkIdContext);
