import * as React from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import MapIcon from "@mui/icons-material/Map";
import LogoutIcon from "@mui/icons-material/Logout";
import { Box, Typography, useTheme } from "@mui/material";
import { useMenuContext } from "../../hooks/useMenuInfo";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { API_URL } from "../../constants";
import { useNetworkId } from "../../hooks/useNetworkId";

const appMenuData: AppMenuItemProp[] = [
  {
    name: "Language Selection",
    translationKey: "menu.languageSelection",
    // icon: <MapIcon />,
    link: "/user-config",
    isSubtab: false,
  },
  {
    name: "Config",
    translationKey: "menu.config",
    icon: <SettingsIcon />,
    isSubtab: false,
    items: [
      {
        name: "Upload Network",
        translationKey: "menu.uploadNetwork",
        link: "/pipeline-config",
        isSubtab: true,
      },
      {
        name: "Connect Data",
        translationKey: "menu.connectData",
        link: "/run-simulation",
        isSubtab: true,
      },
    ],
  },
  {
    name: "Report",
    translationKey: "menu.report",
    icon: <AnalyticsIcon />,
    isSubtab: false,
    items: [
      {
        name: "Management Report",
        translationKey: "menu.menagementReport",
        link: "/chart-report",
        isSubtab: true,
      },
      {
        name: "Find Leaks and Anomalies",
        translationKey: "menu.findLeaks",
        link: "/anomaly-report",
        isSubtab: true,
      },
      {
        name: "Generate Work Order",
        translationKey: "menu.generateWorkOrder",
        link: "/work-order-generation",
        isSubtab: true,
      },
    ],
  },
  {
    name: "Pipeline Details",
    translationKey: "menu.pipelineDetails",
    icon: <MapIcon />,
    link: "/map",
    isSubtab: false,
  },
];

const sysMenuData: AppMenuItemProp[] = [
  {
    name: "Logout",
    translationKey: "menu.logout",
    icon: <LogoutIcon />,
    isSubtab: false,
    link: "/login",
  },
];

// this controls the width of the menu bar
const listItemStyle = {
  width: "240px",
};
const iconStyle = {
  color: "white",
  minWidth: "45px",
};
const listItemTextStyle = {
  backgroundColor: "red",
};
const AppMenu: React.FC = () => {
  const theme = useTheme();
  const { fetchNetworkId, setNetworkId, networkId: network_id } = useNetworkId();

  useEffect(() => {
    fetchNetworkId().then((nid) => {
      setNetworkId(nid);
    });
  }, []);
  return (
    <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%" }}>
      <List sx={{ color: "white" }} component="nav">
        {appMenuData.map((item, idx) => (
          <AppMenuItem key={idx} {...item} />
        ))}
      </List>

      <List sx={{ color: "white" }} component="nav">
        {sysMenuData.map((item, idx) => (
          <Box
          // destroy the model and create it if the model exist
            onClick={() => {
              if (network_id)
                axios.post(`${API_URL}/destroy/${network_id}`).then(() => {
                  axios.post(`${API_URL}/create`);
                });
              else axios.post(`${API_URL}/create`);
            }}
          >
            <AppMenuItem key={idx} {...item} />
          </Box>
        ))}
      </List>
    </div>
  );
};

export interface AppMenuItemProp {
  name: string;
  icon?: JSX.Element;
  link?: string;
  items?: AppMenuItemProp[];
  isSubtab: boolean;
  translationKey?: string;
}

const AppMenuItem: React.FC<AppMenuItemProp> = ({ name, translationKey, icon, link, items, isSubtab }) => {
  const location = useLocation();
  const theme = useTheme();
  const { menuInfo, setMenuInfo } = useMenuContext();
  const { t, i18n } = useTranslation();

  // const [open, setOpen] = useState<boolean>(menuInfo[name]??true);
  const navigation = useNavigate();
  return !items || items.length === 0 ? (
    <ListItemButton
      style={listItemStyle}
      sx={{
        pl: isSubtab ? 2 : undefined,
        borderRadius: "8px",
        backgroundColor: location.pathname === link ? theme.palette.primary.main : undefined,
        "&:hover": location.pathname === link ? { backgroundColor: theme.palette.primary.main } : undefined,
      }}
      onClick={() => (link ? navigation(link) : <div />)}
    >
      <ListItemIcon sx={iconStyle}>{icon}</ListItemIcon>
      <ListItemText>{t(translationKey ?? "translationError")}</ListItemText>
    </ListItemButton>
  ) : (
    <div>
      <ListItemButton
        style={listItemStyle}
        sx={{
          pl: isSubtab ? 2 : undefined,
          borderRadius: "8px",
          backgroundColor: location.pathname === link ? theme.palette.primary.main : undefined,
          "&:hover": location.pathname === link ? { backgroundColor: theme.palette.primary.main } : undefined,
        }}
        onClick={() => {
          // setOpen(!open);
          console.log(".");
          console.log(menuInfo);
          console.log({ ...menuInfo, [name]: !menuInfo[name] });

          setMenuInfo({ ...menuInfo, [name]: !menuInfo[name] });
          link ? navigation(link) : <div />;
        }}
      >
        <ListItemIcon sx={iconStyle}>{icon}</ListItemIcon>
        <ListItemText>{i18n.t(translationKey ?? "translationError")}</ListItemText>
        {menuInfo[name] ?? true ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={menuInfo[name] ?? true} timeout="auto" unmountOnExit>
        <List component="div" disablePadding={isSubtab}>
          {items.map((item, idx) => (
            <AppMenuItem key={idx} {...item} />
          ))}
        </List>
      </Collapse>
    </div>
  );
};

export default AppMenu;
