import { AnomalyRecordType } from "../components/anomaly_report/AnomalyReportDataGrid";
import { JunctionMap, JunctionMetadataType, PipeMap, PipeMetadataType } from "../pages/PipelineConfigPage";
import { FilterConfigType, WorkOrderType } from "../pages/WorkOrderGenerationPage";

export const anomaly2WorkOrder = (
  DMA: string,
  filteredAnomalies: AnomalyRecordType[],
  pipes: PipeMap | undefined,
  junctions: JunctionMap | undefined
): WorkOrderType[] => {
  if (pipes)
    return filteredAnomalies.map(
      (anomaly) =>{
        // console.log({
        //   DMA: DMA,
        //   netType: "pipe",
        //   startNodeGPS: junctions?[junctions[pipes[anomaly.location].START_NODE]?.GPS_LONGITUDE.toPrecision(4), junctions[pipes[anomaly.location].START_NODE]?.GPS_LATITUDE.toPrecision(4)]:[],
        //   stopNodeGPS: junctions?[junctions[pipes[anomaly.location].STOP_NODE]?.GPS_LONGITUDE.toPrecision(4), junctions[pipes[anomaly.location].STOP_NODE]?.GPS_LATITUDE.toPrecision(4)]:[],
        //   ...pipes[anomaly.location],
        //   ...anomaly,
        // })
        return ({
          DMA: DMA,
          netType: "pipe",
          startNodeGPS: junctions?[junctions[pipes[anomaly.location].START_NODE]?.GPS_LONGITUDE.toFixed(3), junctions[pipes[anomaly.location].START_NODE]?.GPS_LATITUDE.toFixed(3)]:[],
          stopNodeGPS: junctions?[junctions[pipes[anomaly.location].STOP_NODE]?.GPS_LONGITUDE.toFixed(3), junctions[pipes[anomaly.location].STOP_NODE]?.GPS_LATITUDE.toFixed(3)]:[],
          ...pipes[anomaly.location],
          ...anomaly,
        } as WorkOrderType)}
    );
  else if (junctions)
    return filteredAnomalies.map(
      (anomaly) =>
        ({
          DMA: DMA,
          netType: anomaly.location.startsWith("H") ? "house" : "node",
          ...anomaly,
          ...junctions[anomaly.location.split("_")[0]],
        } as WorkOrderType)
    );
  return [];
};

// filter anomalies then map them to pipes
export const anomaly2pipe = (
  filterConfig: FilterConfigType,
  anomalies: AnomalyRecordType[],
  pipes: PipeMap
): PipeMetadataType[] => {
  console.log("start to filter pipes");
  return anomalyFilter(filterConfig, anomalies, pipes).map((anomaly) => {
    console.log(pipes[anomaly.location]);
    return pipes[anomaly.location];
  });
};

// filter anomalies then map them to nodes

export const anomaly2node = (
  filterConfig: FilterConfigType,
  anomalies: AnomalyRecordType[],
  junctions: JunctionMap
): JunctionMetadataType[] => {
  console.log("start to filter nodes");
  return anomalyFilter(filterConfig, anomalies, undefined).map((anomaly) => {
    console.log(junctions[anomaly.location.split("_")[0]]);
    return junctions[anomaly.location.split("_")[0]];
  });
};

function anomalyStatusFilter(filterConfig: FilterConfigType, anomaly: AnomalyRecordType) {
  if (filterConfig.detection_status.includes("abnormal") && anomaly.detection_status === "abnormal") return true;
  if (filterConfig.detection_status.includes("false alert") && anomaly.detection_status === "false alert") return true;
  
// if the status is in the array
  return (
    filterConfig.detection_status.includes(anomaly.detection_status) &&
    filterConfig.anomaly_type.includes(anomaly.anomaly_type) &&
    filterConfig.repair_status.includes(anomaly.repair_status)
  );
}

//["0,1","1,10"]
// parse the string to range of number
const inRange =(length:number,ranges_string:string[])=>{
  if (ranges_string.length===0){
    return true
  }
  var res = false
  var ranges = ranges_string.map((range_str)=>{
    const r_str = range_str.split(",")
    return [ Number(r_str[0]), Number(r_str[1])]
  })
  console.log(ranges_string)
  ranges.map((range)=>{
    res = res || (length < range[1] && length >=range[0])
  })
  console.log(ranges)
  return res 

}

// if provide pipes, then return anomalies filtered by pipe config
export const anomalyFilter = (
  filterConfig: FilterConfigType,
  anomalies: AnomalyRecordType[],
  pipes: PipeMap | undefined
): AnomalyRecordType[] => {
  const filteredAnomalies = anomalies
    .filter((anomaly) => anomaly.estimate_accuracy >= filterConfig.accuracyLevel)
    .filter((anomaly) => anomalyStatusFilter(filterConfig, anomaly));
  if (pipes && Object.keys(pipes).length > 0)
    return filteredAnomalies
      .filter((anomaly) => anomaly.location.startsWith("Pipe"))
      .filter(
        (anomaly) =>
          //pipes[anomaly.location].LENGTH_m >= (filterConfig.lengthRange ?? [0, Infinity])[0] &&
          //pipes[anomaly.location].LENGTH_m < (filterConfig.lengthRange ?? [0, Infinity])[1]
          inRange(pipes[anomaly.location].LENGTH_m,filterConfig.lengthRange)
      )
      .filter(
        (anomaly) =>
          //pipes[anomaly.location].D_mm >= (filterConfig.diameterRange ?? [0, Infinity])[0] &&
          //pipes[anomaly.location].D_mm < (filterConfig.diameterRange ?? [0, Infinity])[1]
          inRange(pipes[anomaly.location].D_mm,filterConfig.diameterRange)
      )
      .filter((anomaly) => filterConfig.material.includes(pipes[anomaly.location].MATERIAL));
  console.log("filter node/house");
  return filteredAnomalies.filter(
    (anomaly) =>
      (filterConfig.netType.includes("house") && anomaly.location.startsWith("H")) ||
      (filterConfig.netType.includes("node") && anomaly.location.startsWith("N"))
  );
};
