import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./locale/en/translation.json";
import zh from "./locale/zh/translation.json";
import vn from "./locale/vn/translation.json";
import th from "./locale/th/translation.json";
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
  en: {
    translation: en,
  },
  zh: {
    translation: zh,
  },
  vn:{
    translation: vn,
  },
  th:{
    translation: th,
  }
};


i18n
//   .use(Backend)
//   .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    debug: true,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });


export default i18n;