import React from "react";
import { Page, Text, View, Document, StyleSheet, PDFViewer, Font } from "@react-pdf/renderer";
import { WorkOrderType } from "../../pages/WorkOrderGenerationPage";
import { Divider } from "@mui/material";
import { useTranslation } from "react-i18next";

const styles = StyleSheet.create({
  title: {
    fontFamily: 'Roboto',
    textAlign: "center",
    fontSize: 32,
    marginBottom: 10,
  },
  page: {
    flexDirection: "column",
    backgroundColor: "#E4E4E4",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
  header: {
    fontSize: 18,
    fontWeight: 500,
    marginTop: 10,
    marginBottom: 10,
  },
});

const font = "Roboto"
const tableStyle = StyleSheet.create({
  wotable: {
    fontFamily: font,
    display: "flex",
    flexDirection: "column",
    marginBottom: 10,
    marginTop: 10,
    paddingBottom: 10,
    paddingTop: 10,
    // border: "1 solid black",
  },
  midRow: {
    fontFamily: font,
    flexDirection: "row",
    // borderLeft: "1 solid black",
  },
  row: {
    fontFamily: font,
    flexDirection: "row",
    border: "1 solid black",
  },
  cell: {
    fontFamily: font,
    display: "flex",
    width: "25%",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    fontSize: '16px'
  },
  tricells: {
    fontFamily: font,
    display: "flex",
    width: "75%",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    fontSize: '16px'
  },
  divider: {
    fontFamily: font,
    marginBottom: 50, // optional margin to the bottom
    height: 1, // height of the divider
    backgroundColor: "black", // color of the divider
  },
});


Font.register({
  family: font,
  src: `${process.env.PUBLIC_URL}/unifont-15.0.06.ttf`
});
const WorkOrderPDF: React.FC<{ workOrders: WorkOrderType[] }> = ({ workOrders }) => {
  const { t, i18n } = useTranslation();
  return (
    <Document>
      <Page size="A4" >
        <View style={styles.title}>
          <Text>{t("generateWorkOrder.workOrders")}</Text>
        </View>
        <View style={{ marginBottom: 30 }}>
          <Text> </Text>
        </View>
        {/*This is where the view for the work order start */}
        {workOrders.map((workOrder, index) => (
          <View style={tableStyle.wotable} break={index%2==0 && index!=0}>
            <View style={tableStyle.row}>
              <View style={tableStyle.tricells}>
                <Text>DMA</Text>
              </View>
              <View style={tableStyle.cell}>
                <Text>{t("findLeaksandAnomalies.content.location")}</Text>
                <Text>{`(${t("property.longitude")},${t("property.latitude")})`}</Text>
              </View>
            </View>
            <View style={tableStyle.row}>
              <View style={tableStyle.tricells}>
                <Text>{workOrder.DMA}</Text>
              </View>
              <View style={tableStyle.cell}>
                <Text>{workOrder.location}</Text>
                {/* {workOrder.netType==='pipe' && <Text>{`(${workOrder.startNodeGPS})`}</Text>}
                {workOrder.netType==='pipe' && <Text>{`=>`}</Text>}
                {workOrder.netType==='pipe' && <Text>{`(${workOrder.stopNodeGPS})`}</Text>}
                {workOrder.netType!=='pipe' && <Text>{`(${workOrder.GPS_LONGITUDE?.toFixed(3)},${workOrder.GPS_LATITUDE?.toFixed(3)})`}</Text>} */}
                {workOrder.netType==='pipe' && <Text>{`(GPS DATA)`}</Text>}
                {workOrder.netType==='pipe' && <Text>{`=>`}</Text>}
                {workOrder.netType==='pipe' && <Text>{`(GPS DATA)`}</Text>}
                {workOrder.netType!=='pipe' && <Text>{`(GPS DATA)`}</Text>}
                
              </View>
            </View>
            {/* third line */}
            <View style={tableStyle.row}>
              <View style={tableStyle.cell}>
                <Text>{t("generateWorkOrder.content.networkType")}</Text>
              </View>
              <View style={tableStyle.cell}>
                <Text>{t("generateWorkOrder.content.detectionStatus")}</Text>
              </View>
              <View style={tableStyle.cell}>
                <Text>{t("generateWorkOrder.content.amomalyType")}</Text>
              </View>

              <View style={tableStyle.cell}>
                <Text>{t("generateWorkOrder.content.repairStatus")}</Text>
              </View>
            </View>

            {/* 4th line */}
            <View style={tableStyle.midRow}>
              <View style={[tableStyle.cell, workOrder.netType === "pipe" ? { textDecoration: "underline" } : {}]}>
                <Text>{t("networkType.pipe")}</Text>
              </View>
              <View style={[tableStyle.cell, workOrder.detection_status === "identified" ? { textDecoration: "underline" } : {}]}>
                <Text>{t("detectionStatus.identified")}</Text>
              </View>
              <View style={[tableStyle.cell, workOrder.anomaly_type === "leak" ? { textDecoration: "underline" } : {}]}>
                <Text>{t("anomalyType.Leak")}</Text>
              </View>
              <View style={[tableStyle.cell, workOrder.repair_status === "not repaired" ? { textDecoration: "underline" } : {}]}>
                <Text>{t("repairStatus.notRepaired")}</Text>
              </View>
            </View>

            {/* 5th line */}
            <View style={tableStyle.midRow}>
              <View style={[tableStyle.cell, workOrder.netType === "house" ? { textDecoration: "underline" } : {}]}>
                <Text>{t("networkType.house")}</Text>
              </View>
              <View style={[tableStyle.cell, workOrder.detection_status === "abnormal" ? { textDecoration: "underline" } : {}]}>
                <Text>{t("detectionStatus.abnormal")}</Text>
              </View>
              <View style={[tableStyle.cell, workOrder.anomaly_type === "clog" ? { textDecoration: "underline" } : {}]}>
                <Text>{t("anomalyType.Clog")}</Text>
              </View>
              <View style={[tableStyle.cell, workOrder.repair_status === "repaired" ? { textDecoration: "underline" } : {}]}>
                <Text>{t("repairStatus.repaired")}</Text>
              </View>
            </View>

            {/* 6th line */}
            <View style={tableStyle.midRow}>
              <View style={[tableStyle.cell, workOrder.netType === "node" ? { textDecoration: "underline" } : {}]}>
                <Text>{t("networkType.node")}</Text>
              </View>
              <View
                style={[tableStyle.cell, workOrder.detection_status === "false alert" ? { textDecoration: "underline" } : {}]}
              >
                <Text>{t("detectionStatus.FalseAlert")}</Text>
              </View>
              <View style={[tableStyle.cell, workOrder.anomaly_type === "pilferage" ? { textDecoration: "underline" } : {}]}>
                <Text>{t("anomalyType.Pilferage")}</Text>
              </View>
            </View>

            {/* 7th line */}
            <View style={tableStyle.midRow}>
              <View style={tableStyle.cell}>
                <Text> </Text>
              </View>
              <View style={tableStyle.cell}>
                <Text> </Text>
              </View>
              <View style={[tableStyle.cell, workOrder.anomaly_type === "N/A" ? { textDecoration: "underline" } : {}]}>
                <Text>N/A</Text>
              </View>
            </View>

            {/* 8th line */}
            <View style={tableStyle.row}>
              <View style={tableStyle.cell}>
                <Text>{t("generateWorkOrder.accuracy")}</Text>
              </View>
              <View style={tableStyle.cell}>
                <Text>{t("property.length")}</Text>
              </View>
              <View style={tableStyle.cell}>
                <Text>{t("property.diameter")}</Text>
              </View>
              <View style={tableStyle.cell}>
                <Text>{t("property.material")}</Text>
              </View>
            </View>
            {/* 8th line */}
            <View style={tableStyle.midRow}>
              <View style={tableStyle.cell}>
                <Text>{workOrder.estimate_accuracy}</Text>
              </View>
              <View style={tableStyle.cell}>
                <Text>{workOrder.LENGTH_m ? `${workOrder.LENGTH_m}m` : ""}</Text>
              </View>
              <View style={tableStyle.cell}>
                <Text>{workOrder.D_mm ? `${workOrder.D_mm}mm` : ""}</Text>
              </View>
              <View style={tableStyle.cell}>
                <Text>{workOrder.MATERIAL}</Text>
              </View>
            </View>
            <View style={tableStyle.divider} />
          </View>
        ))}
      </Page>
    </Document>
  );
};

// const WorkOrderPDFPreview = () => {
//   return (
//     <PDFViewer>
//       <WorkOrderPDF />
//     </PDFViewer>
//   );
// };

export default WorkOrderPDF;
