import { Typography } from "@mui/material";
import React from "react";

interface PageHeaderProps {
  title: string;
  description: string;
}
const headerStyle = {
  display: "flex",
  padding: "32px",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "8px",
};

// font-family: Montserrat;
// font-size: 24px;
// font-style: normal;
// font-weight: 600;
// line-height: 28px;
const PageHeader: React.FC<PageHeaderProps> = ({ title, description }) => {
  return (
    <div
      style={{
        padding: "32px 32px 28px",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "10px",
        borderBottom: "1px solid #CCC",
        width: "100%",
        boxSizing: "border-box",
      }}
    >
      <div style={{ width:'100%', display: "flex", justifyContent: "space-between", flex: "1 0" }}>
          <Typography variant="h2">
            {title}
          </Typography>
        {/* <div>Profile</div> */}
      </div>
      <div style={{ width: '80%' }}>
        <Typography variant="body2">
          {description}
        </Typography>
      </div>
    </div>
  );
};

export default PageHeader;
