import React, { useState } from "react";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  MenuItem,
  Pagination,
  Select,
  SelectChangeEvent,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
  styled,
  useTheme,
} from "@mui/material";

import axios from "axios";
import { API_URL } from "../../constants";
import { DataGrid, GridColDef, GridValueFormatterParams } from "@mui/x-data-grid";
import { smallTypography } from "../../app";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";

const tableTitle = ["Latest Detection Time", "Location/Address", "Type of Anomaly", "Est. Accuracy Level", "flag"];
export interface AnomalyRecordType {
  id: number;
  detection_status: "abnormal" | "false alert" | "identified";
  anomaly_type: string;
  repair_status: string;
  alarm_name: string;

  detection_time: string;
  estimate_accuracy: number;
  location: string;
  type: string;
}

// abnormaly_type
// alarm_name
// detection_status
// detection_time
// estimate_accuracy
// id
// location
// repair_status
// type

export interface TableData {
  id: number[];
  detection_status: ("abnormal" | "false alert" | "identified")[];
  anomaly_type: string[];
  repair_status: string[];
  alarm_name: string[];

  detection_time: string[];
  estimate_accuracy: number[];
  location: string[];
  type: string[];
}
// interface FlagStatus {
//   onConfirm: () => void;
//   anomalyRecord: AnomalyRecordType;
// }

// convert the tabledata {col1: any[], ...} to {col1: any, ...}[] with
export const convertToAnomalyRecords = (tableData: TableData | null, thred: number): AnomalyRecordType[] | null => {
  console.log("tableData in converter");
  console.log(tableData);

  thred = thred ?? 0;
  return (
    tableData?.estimate_accuracy
      .map((_, i) => ({
        id: i,
        detection_status: tableData.detection_status[i],
        anomaly_type: tableData.anomaly_type[i],
        repair_status: tableData.repair_status[i],
        alarm_name: tableData.alarm_name[i],

        detection_time: tableData.detection_time[i],
        estimate_accuracy: tableData.estimate_accuracy[i],
        location: tableData.location[i],
        type: tableData.type[i],
      }))
      .filter((row, _) => row.estimate_accuracy >= thred) ?? null
  );
};

export function translate(text: string, t: TFunction): string {
  return text
    .replace("abnormal", t("detectionStatus.abnormal", text))
    .replace("false alert", t("detectionStatus.falseAlert", text))
    .replace("identified", t("detectionStatus.identified", text))
    .replace("leak", t("anomalyType.leak", text))
    .replace("Leak", t("anomalyType.Leak", text))
    .replace("clog", t("anomalyType.clog", text))
    .replace("Clog", t("anomalyType.Clog", text))
    .replace("pilferage", t("anomalyType.pilferage", text))
    .replace("Pilferage", t("anomalyType.Pilferage", text))
    .replace("not repaired", t("repairStatus.notRepaired", text))
    .replace("repaired", t("repairStatus.repaired", text))
    .replace("consumption", t("anomalyTable.consumption", text))
    .replace("Data", t("anomalyTable.data", text))
    .replace("Collection", t("anomalyTable.consumption", text))
    .replace("Totalizer", t("anomalyTable.totalizer", text))
    .replace("Transmission", t("anomalyTable.transmission", text))
    .replace("For further validation", t("anomalyTable.forFurtherValidation", text));
}

const CellBox = styled(Box)({
  height: "100%",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const AnomalyReportDataGrid: React.FC<{ tableData: TableData | null; onConfirm: () => void; acc_thred: number }> = ({
  tableData,
  onConfirm,
  acc_thred,
}) => {
  const anomalyRecords = convertToAnomalyRecords(tableData, acc_thred);
  const anomalyTypes = Array.from(new Set(tableData?.type));
  const [selectedRow, setSelectedRow] = useState<AnomalyRecordType | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const theme = useTheme();

  const getDSbgcolor = (detection_status: "abnormal" | "false alert" | "identified") => {
    switch (detection_status) {
      case "abnormal":
        return theme.palette.warning.light;
      case "false alert":
        return "#6D4C41";
      case "identified":
        return "#C8504A";
      default:
        return theme.palette.success.light;
    }
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      minWidth: 50,
      type: "number",
      align: "center",
      headerAlign: "center",
    },
    {
      field: "detection_time",
      headerName: t("findLeaksandAnomalies.content.detectionTime"),
      minWidth: 150,
      type: "string",
      sortable: true,
      align: "center",
      headerAlign: "center",
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        return new Date(params.value as number).toLocaleString();
      },
      renderCell: (params) => (
        <Typography whiteSpace={"normal"} sx={{ wordWrap: "break-word", textAlign: "center" }} variant="body1">
          {params.value}
        </Typography>
      ),
    },

    {
      field: "location",
      headerName: t("findLeaksandAnomalies.content.location"),
      minWidth: 170,
      align: "center",
      headerAlign: "center",
      sortable: true,
      renderCell: (params) => (
        <Typography whiteSpace={"normal"} sx={{ wordWrap: "break-word", textAlign: "center" }}>
          {translate(params.value, t)}
        </Typography>
      ),
    },
    {
      field: "type",
      headerName: t("findLeaksandAnomalies.content.systemAnomalyType"),
      description: "",
      type: "singleSelect",
      valueOptions: anomalyTypes,
      sortable: true,
      minWidth: 200,
      align: "center",
      headerAlign: "center",
      valueFormatter: (params: GridValueFormatterParams<string>) => {
        return translate(params.value, t);
      },
    },
    {
      field: "estimate_accuracy",
      headerName: t("findLeaksandAnomalies.content.estAccuracy"),
      minWidth: 160,
      type: "number",
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (params.value == null) {
          return "";
        }
        return `${(params.value * 100).toLocaleString()} %`;
      },
      sortable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "detection_status",
      headerName: t("findLeaksandAnomalies.content.detectionStatus"),
      description: "",
      sortable: false,
      type: "singleSelect",
      valueOptions: [t("detectionStatus.abnormal"), t("detectionStatus.identified"), t("detectionStatus.falseAlert")],
      editable: true,
      minWidth: 150,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <CellBox sx={{ background: "#f8f4ef" }}>
            <Chip
              label={translate(params.value, t)}
              variant="outlined"
              sx={{
                borderColor: getDSbgcolor(params.value),
                color: getDSbgcolor(params.value),
              }}
            />
          </CellBox>
        );
      },
    },
    {
      field: "anomaly_type",
      headerName: t("findLeaksandAnomalies.content.anomalyType"),
      description: "",
      sortable: false,
      type: "string",
      minWidth: 130,
      align: "center",
      headerAlign: "center",
      valueFormatter: (params: GridValueFormatterParams<string>) => {
        if (params.value === "N/A") {
          return "--";
        }
        return params.value;
      },
      renderCell: (params) => {
        // return <FlagSelect anomalyRecord={params.row} onConfirm={onConfirm} />;
        return (
          <CellBox sx={{ background: "#edf4f9" }}>
            {params.value === "N/A" ? (
              "--"
            ) : (
              <Chip
                label={translate(params.value, t)}
                sx={{
                  background: "#FAEEED",
                  color: "#582522",
                }}
              />
            )}
          </CellBox>
        );
      },
    },
    {
      field: "repair_status",
      headerName: t("findLeaksandAnomalies.content.repairedStatus"),
      description: "",
      sortable: false,
      type: "string",
      // editable: true,
      minWidth: 160,
      align: "center",
      headerAlign: "center",
      valueFormatter: (params: GridValueFormatterParams<string>) => {
        if (params.value === "N/A") {
          return "--";
        }
        return params.value;
      },
      renderCell: (params) => {
        return (
          <CellBox sx={{ background: "#f9f8f1" }}>
            {params.value === "N/A" ? (
              "--"
            ) : (
              <Chip
                label={translate(params.value, t)}
                sx={{
                  background: params.value === "repaired" ? "#F0F8F1" : "#FDF4E7",
                  color: params.value === "repaired" ? "#284524" : "#603E11",
                }}
              />
            )}
          </CellBox>
        );
      },
    },
  ];
  const handleRowClick = (param: any) => {
    console.log("param.row");

    console.log(param.row);
    setSelectedRow(param.row);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // detection_status
  // abnormaly_type
  // repair_status
  const statusChange = (flag: "fixed" | "false alert" | "abnormal") => {
    console.log(flag);
    if (flag === "false alert") return "Ignored";
    else if (flag === "fixed") return "Fixed";
    else return "New";
  };
  return (
    <Box sx={{ height: 631 }}>
      <DataGrid
        rows={anomalyRecords ?? []}
        columns={columns}
        onRowClick={handleRowClick}
        sx={{
          typography: smallTypography.body1,
          border: "none",
          ".MuiDataGrid-root": {
            border: "none",
          },
          ".MuiDataGrid-columnHeaders": {
            background: "#EFF5FA",
            borderRadius: "8px 8px 0 0",
            border: "none",
          },
          "& .MuiDataGrid-columnName": {
            // This targets both header and cells of the "name" column
            backgroundColor: "lightblue",
          },
          "& .MuiDataGrid-cell": {
            padding: "0",
          },
        }}
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
            },
          },
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
          
        }}
        pageSizeOptions={[10, 20, 100]}
        localeText={{
          MuiTablePagination: {
            labelRowsPerPage: t("findLeaksandAnomalies.content.rowsPerPage"),
          }
        }}
        // checkboxSelection
        disableRowSelectionOnClick
      />
      {selectedRow && (
        <StatusTransferDialog
          key={selectedRow.id}
          onConfirm={onConfirm}
          anomalyRecord={selectedRow}
          open={open}
          setOpen={setOpen}
          setSelectedRow={setSelectedRow}
        />
      )}
    </Box>
  );
};
export default AnomalyReportDataGrid;

interface StatusTransferDialogProps {
  onConfirm: () => void;
  anomalyRecord: AnomalyRecordType;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedRow:React.Dispatch<React.SetStateAction<AnomalyRecordType | null>>;
}

const checkInitStep = (anomalyRecord: AnomalyRecordType) => {
  console.log("checkInitStep");

  console.log(anomalyRecord);
  // TODO: need to fix logic here
  if (anomalyRecord.detection_status === "abnormal") return 0;
  else if (anomalyRecord.anomaly_type === "N/A") return 1;
  else if (anomalyRecord.repair_status === "repaired") return 3;
  else return 2;
};
const StatusTransferDialog: React.FC<StatusTransferDialogProps> = ({ onConfirm, anomalyRecord, open, setOpen,setSelectedRow }) => {
  const initStep = checkInitStep(anomalyRecord);
  const [activeStep, setActiveStep] = useState<number>(initStep);
  const [newAnomalyRecord, setNewAnomalyRecord] = useState<AnomalyRecordType>(JSON.parse(JSON.stringify(anomalyRecord)));
  const [isReady, setIsReady] = useState<boolean>(false);
  const { t } = useTranslation();
  const handleClose = () => {
    setOpen(false);
    setSelectedRow(null);
  };
  const handleChangeDetectionStatus = (e: SelectChangeEvent<"abnormal" | "false alert" | "identified">) => {
    setNewAnomalyRecord((prev) => ({ ...prev, detection_status: e.target.value as "abnormal" | "false alert" | "identified" }));
  };

  const handleChangeAnomalyType = (e: SelectChangeEvent<string>) => {
    setNewAnomalyRecord((prev) => ({ ...prev, anomaly_type: e.target.value, repair_status:"not repaired" }));    
  };

  function handleChangeRepairStatus(e: SelectChangeEvent<string>) {
    setNewAnomalyRecord((prev) => ({ ...prev, repair_status: e.target.value }));
  }

  function handleSave(event: any): void {
    axios
      .put(
        API_URL + "/abnormal/flag",
        {
          location: newAnomalyRecord.location,
          detection_time: newAnomalyRecord.detection_time,
          new_detection_status: newAnomalyRecord.detection_status,
          new_anomaly_type: newAnomalyRecord.anomaly_type,
          new_repair_status: newAnomalyRecord.repair_status,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(() => {onConfirm()});
    // setOpen(false);
    handleClose();
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle marginTop={"1rem"}>
        <Typography variant="h2">ID {anomalyRecord.id}</Typography>
      </DialogTitle>
      <DialogContent sx={{ padding: "2rem" }}>
        <Stepper activeStep={activeStep} orientation="vertical">
          <Step
            key={"Detection Status"}
            sx={{ ".css-mbio22-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed": { color: "green" } }}
          >
            <StepLabel>{t("findLeaksandAnomalies.content.detectionStatus")}</StepLabel>
            <StepContent>
              <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                <Typography variant="body2">{t("findLeaksandAnomalies.content.step1description")}</Typography>
                <Select
                  value={newAnomalyRecord.detection_status}
                  onChange={(e) => {
                    handleChangeDetectionStatus(e);
                  }}
                  sx={{ width: "15rem" }}
                >
                  <MenuItem value="abnormal" disabled>
                    {t("detectionStatus.Abnormal")}
                  </MenuItem>
                  <MenuItem value="false alert">{t("detectionStatus.FalseAlert")}</MenuItem>
                  <MenuItem value="identified">{t("detectionStatus.Identified")}</MenuItem>
                </Select>
                <Box sx={{ mb: 2 }}>
                  <Button
                    disabled={!["false alert", "identified"].includes(newAnomalyRecord.detection_status)}
                    variant="contained"
                    onClick={() => setActiveStep((prev) => prev + 1)}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {t("common.confirm")}
                  </Button>
                </Box>
              </Box>
            </StepContent>
          </Step>

          {/* step 2, determine the identified anomaly type */}
          {newAnomalyRecord.detection_status !== "false alert" && (
            <Step key={"Anomaly Type"} sx={{ ".css-mbio22-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed": { color: "green" } }}>
              <StepLabel>{t("findLeaksandAnomalies.content.anomalyType")}</StepLabel>
              <StepContent>
                <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                  <Typography variant="body2">{t("findLeaksandAnomalies.content.step2description")}</Typography>
                  <Select
                    value={newAnomalyRecord.anomaly_type}
                    onChange={(e) => {
                      handleChangeAnomalyType(e);
                    }}
                    sx={{ width: "15rem" }}
                  >
                    <MenuItem value={"N/A"} disabled>
                      --
                    </MenuItem>
                    <MenuItem value="leak">{t("anomalyType.Leak")}</MenuItem>
                    <MenuItem value="clog">{t("anomalyType.Clog")}</MenuItem>
                    <MenuItem value="pilferage">{t("anomalyType.Pilferage")}</MenuItem>
                  </Select>
                  <Box sx={{ mb: 2 }}>
                    <Button variant="contained" onClick={() => setActiveStep((prev) => prev + 1)} sx={{ mt: 1, mr: 1 }} disabled={newAnomalyRecord.anomaly_type==='N/A'}>
                      {t("common.confirm")}
                    </Button>
                  </Box>
                </Box>
              </StepContent>
            </Step>
          )}
          {newAnomalyRecord.detection_status !== "false alert" && (
            <Step
              key={"Repaired Status"}
              sx={{ ".css-mbio22-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed": { color: "green" } }}
            >
              <StepLabel>{t("findLeaksandAnomalies.content.repairedStatus")}</StepLabel>
              <StepContent>
                <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                  <Typography variant="body2">{t("findLeaksandAnomalies.content.step3description")}</Typography>
                  <Select
                    value={newAnomalyRecord.repair_status}
                    onChange={(e) => {
                      handleChangeRepairStatus(e);
                    }}
                    sx={{ width: "15rem" }}
                  >
                    <MenuItem value="not repaired">{t("repairStatus.no")}</MenuItem>
                    <MenuItem value="repaired">{t("repairStatus.yes")}</MenuItem>
                  </Select>
                  <Box sx={{ mb: 2 }}>
                    <Button
                      disabled={['"false alert" | "identified"'].includes(newAnomalyRecord.detection_status)}
                      variant="contained"
                      onClick={() => setActiveStep((prev) => prev + 1)}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      {t("common.confirm")}
                    </Button>
                  </Box>
                </Box>
              </StepContent>
            </Step>
          )}
        </Stepper>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>{t("common.cancel")}</Button>
        <Button onClick={handleSave} autoFocus disabled={newAnomalyRecord.detection_status==='identified' && newAnomalyRecord.anomaly_type==='N/A'}>
          {t("common.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// Outdated

// const FlagSelect: React.FC<FlagStatus> = ({ anomalyRecord, onConfirm }) => {
//   const theme = useTheme();
//   const [status, setStatus] = useState<string>(anomalyRecord.detection_status);
//   const [tempStatus, setTempStatus] = useState<"abnormal" | "false alert" | "identified">(anomalyRecord.detection_status);
//   const [open, setOpen] = useState<boolean>(false);
//   const {t} = useTranslation();
//   const handleCancel = () => {
//     console.log("canceled");
//     setOpen(false);
//   };
//   const handleConfirm = () => {
//     console.log("confirm");
//     console.log("cur:" + tempStatus);

//     axios
//       .put(
//         API_URL + "/abnormal/flag",
//         {
//           // location: anomalyRecord.location,
//           // detection_time: anomalyRecord.detection_time,
//           // new_flag: tempStatus,
//           // new_status: anomalyRecord..,
//         },
//         {
//           headers: {
//             "Content-Type": "application/json",
//           },
//         }
//       )
//       .then(() => setStatus(tempStatus))
//       .then(() => onConfirm());

//     setOpen(false);
//   };
//   const handleChange = (event: SelectChangeEvent<string>) => {
//     setTempStatus(event.target.value as "identified" | "false alert" | "abnormal");
//     setOpen(true);
//   };

//   const getBackgroundColor = (status: string) => {
//     switch (status) {
//       case "fixed":
//         return theme.palette.success.light;
//       case "false alert":
//         return "#6D4C41";
//       case "abnormal":
//         return theme.palette.warning.light;
//       default:
//         return "green";
//     }
//   };

//   return (
//     <FormControl variant="outlined" fullWidth style={{ width: "auto", minWidth: "100%", borderColor: "yellow" }}>
//       <Select
//         value={status}
//         onChange={(e) => {
//           handleChange(e);
//         }}
//         sx={{
//           color: "white",
//           border: "none",
//           background: getBackgroundColor(status),
//           borderRadius: "20px",
//           ".MuiSelect-select": {
//             height: "fit-content",
//             padding: "4px 12px",
//           },
//           ".MuiOutlinedInput-notchedOutline": {
//             border: "none",
//           },
//           ".MuiSvgIcon-root": {
//             color: "white",
//           },
//           textAlign: "center",
//         }}
//       >
//         <MenuItem value={"fixed"}>Fixed</MenuItem>
//         <MenuItem value={"false alert"}>False Alert</MenuItem>
//         <MenuItem value={"abnormal"}>Abnormal</MenuItem>
//       </Select>
//       <Dialog open={open} onClose={handleCancel} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
//         <DialogContent>
//           <DialogContentText id="alert-dialog-description">
//             You are setting it {tempStatus}. Are you going to update it?
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleCancel}>Cancel</Button>
//           <Button onClick={handleConfirm} autoFocus>
//             {t("common.confirm")}
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </FormControl>
//   );
// };

// const AnomalyReportTable: React.FC<{ tableData: TableData | null; onConfirm: () => void }> = ({ tableData, onConfirm }) => {
//   const anomalyRecords = convertToAnomalyRecords(tableData, 1);
//   const [curPage, setCurPage] = useState<number>(1);
//   const [itemsPerPage, setItemsPerPage] = useState<number>(10);

//   const handlePageChange = (event: any, page: number) => {
//     setCurPage(page);
//   };
//   console.log("render a");
//   return (
//     <Box display="flex" flexDirection="column" overflow={"scroll"}>
//       <table className="table">
//         <thead>
//           <tr>
//             {tableTitle.map((title, idx) => (
//               <th key={idx}>{title}</th>
//             ))}
//           </tr>
//         </thead>
//         {/* show the table here */}
//         <tbody>
//           {anomalyRecords?.slice((curPage - 1) * itemsPerPage, curPage * itemsPerPage).map((row, index) => (
//             // <TableRow key={index} {...row} />
//             <tr key={index + (curPage - 1) * itemsPerPage} style={{ border: "1px solid" }}>
//               <td key={0}>{row.detection_time}</td>
//               <td key={1}> {row.location}</td>
//               <td key={2}> {row.type}</td>
//               <td key={3}>{row.estimate_accuracy}</td>
//               <td key={4} style={{ padding: 0, minWidth: "8rem" }}>
//                 <FlagSelect anomalyRecord={row} onConfirm={onConfirm} />
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//       <Pagination
//         size="large"
//         style={{ alignSelf: "center", margin: "1rem" }}
//         showFirstButton
//         showLastButton
//         count={Math.ceil((anomalyRecords?.length ?? 1) / itemsPerPage)}
//         page={curPage}
//         onChange={handlePageChange}
//       />
//     </Box>
//   );
// };

// const OldFlagSelect: React.FC<FlagStatus> = ({ anomalyRecord, onConfirm }) => {
//   const [status, setStatus] = useState<string>(anomalyRecord.flag);
//   // let tempStatus:string = flag;
//   const [tempStatus, setTempStatus] = useState<"fixed" | "false alert" | "abnormal">(anomalyRecord.flag);
//   const [open, setOpen] = useState<boolean>(false);

//   const handleCancel = () => {
//     console.log("canceled");
//     setOpen(false);
//   };
//   const handleConfirm = () => {
//     console.log("confirm");
//     console.log("cur:" + tempStatus);

//     axios
//       .put(
//         API_URL + "/abnormal/flag",
//         {
//           location: anomalyRecord.location,
//           detection_time: anomalyRecord.detection_time,
//           new_flag: tempStatus,
//         },
//         {
//           headers: {
//             "Content-Type": "application/json",
//           },
//         }
//       )
//       .then(() => setStatus(tempStatus))
//       .then(() => onConfirm());

//     setOpen(false);
//   };
//   const handleChange = (event: SelectChangeEvent<string>) => {
//     setTempStatus(event.target.value as "fixed" | "false alert" | "abnormal");
//     setOpen(true);
//   };

//   return (
//     <>
//       <FormControl variant="outlined" fullWidth style={{ width: "auto", minWidth: "100%" }}>
//         <Select
//           value={status}
//           onChange={(e) => {
//             handleChange(e);
//           }}
//         >
//           <MenuItem value={"fixed"}>Fixed</MenuItem>
//           <MenuItem value={"false alert"}>False Alert</MenuItem>
//           <MenuItem value={"abnormal"}>Abnormal</MenuItem>
//         </Select>
//       </FormControl>

//       <Dialog open={open} onClose={handleCancel} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
//         <DialogContent>
//           <DialogContentText id="alert-dialog-description">
//             You are setting it {tempStatus}. Are you going to update it?
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleCancel}>Cancel</Button>
//           <Button onClick={handleConfirm} autoFocus>
//             Confirm
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </>
//   );
// };
