import React, { useEffect, useState } from "react";
import { useNetworkId } from "../hooks/useNetworkId";
import axios from "axios";
import { API_URL } from "../constants";
import { Autocomplete, Box, Container, MenuItem, Select, Tab, Tabs, TextField, Tooltip } from "@mui/material";
import PipelineMap, { EmptyMap } from "../components/map/PipelineMap";
import FeatureInfoCard from "../components/map/FeatureInfoCard";
import ReactSearchBox from "react-search-box";
import SearchIcon from "../icons/searchIcon.svg";
import ColorRange from "../components/map/ColorRange";
import PathFinder from "../components/map/PathFinder";
import Navbar, { PageWrapper } from "../components/utils/Navbar";
import styled from "styled-components";
import InfoTooltip from "../components/utils/InfoIcon";
import { useTranslation } from "react-i18next";

export interface Node {
  COORDS: number[];
  ELEV: number;
  GPS_LATITUDE: number;
  GPS_LONGITUDE: number;
  NODE_COLOR: string;
  NODE_TYPE: string;
  P: number;
  color: string;
  p_list: number;

  LABEL: string;
  elev_color?: string;
}
export interface Pipe {
  Label?: string;
  Reynolds: number;
  START_NODE: string;
  STOP_NODE: string;
  color: string;
  dia: number;
  end_HGL: number;
  end_node_elevation: number;
  flow_rates: number;
  friction_factor: number;
  hf: number;
  if_valve: false;
  length: number;
  material: string;
  p_in: number;
  p_out: number;
  roughness: number;
  start_HGL: number;
  start_node_elevation: number;
  valve_state: string;
  valves: string;
  D?: number;
}
export type NodeRecord = Record<string, Node>;
export type PipeRecord = Record<string, Pipe>;

export interface NetworkData {
  temp_nodes: NodeRecord;
  temp_pipes: PipeRecord;
}

export interface SearchDataType {
  name: string;
  id: number;
  key: string;
  value: string;
  type: "junction" | "pipe";
}

const PipelineMapPage: React.FC = () => {
  const [networkId, setNetworkId] = useState<string>("");
  const [subnetworks, SetSubnetworks] = useState<string[]>([]);
  const [selectedSubnetwork, setSelectedSubnetwork] = useState<string>("");
  const { t } = useTranslation();
  const [networkData, setNetworkData] = useState<NetworkData | null>(null);
  const [featureInfo, setFeatureInfo] = useState<{ [key: string]: string | number } | null>(null);

  // search pipeline
  const [pipeline, setPipeline] = useState<string[]>([]);

  // search junctions/pipes
  const [displayNetworkData, setDisplayNetworkData] = useState<NetworkData | null>(null);
  // const [reactSearchBoxValue, setReactSearchBoxValue] = useState<string>("search a node/pipe");
  const [searchData, setSearchData] = useState<SearchDataType[]>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [initViewPos, setInitViewPos] = useState<number[]>([0, 0]);

  const [activeTab, setActiveTab] = useState<"network" | "path">("network");

  const handleSearch = (key: string, type: "junction" | "pipe") => {
    const tempNetworkData = JSON.parse(JSON.stringify(networkData));
    let pipes = { ...tempNetworkData?.temp_pipes };
    let nodes = { ...tempNetworkData?.temp_nodes };

    if (type === "pipe") {
      for (const k in pipes) {
        if (k !== key) {
          pipes[k].dia = 0.05;
          pipes[k].color = "#808080";
        }
      }
      const pipe = pipes[key];
      pipe.dia = 0.2;
      const start_node = pipe.START_NODE;
      const stop_node = pipe.STOP_NODE;
      for (const k in nodes) {
        if (k === start_node || k === stop_node) nodes[k].color = "#D44D4D";
        else nodes[k].color = "#808080";
      }
      setInitViewPos([
        (nodes[start_node].GPS_LONGITUDE + nodes[stop_node].GPS_LONGITUDE) / 2,
        (nodes[start_node].GPS_LATITUDE + nodes[stop_node].GPS_LATITUDE) / 2,
      ]);
    } else if (type === "junction") {
      console.log("changing junction color");
      for (const k in nodes) {
        if (k === key) nodes[k].color = "#D44D4D";
        else nodes[k].color = "#808080";
      }
      setInitViewPos([nodes[key].GPS_LONGITUDE, nodes[key].GPS_LATITUDE]);
    }

    setDisplayNetworkData(tempNetworkData);
  };

  const displayPipeline = (pipeline: string[]) => {
    const tempNetworkData = JSON.parse(JSON.stringify(networkData));
    let pipes = { ...tempNetworkData?.temp_pipes };
    let nodes = { ...tempNetworkData?.temp_nodes };
    const junctions = new Set<string>();
    pipeline.forEach((p) => {
      junctions.add(pipes[p].START_NODE);
      junctions.add(pipes[p].STOP_NODE);
    });
    for (const key in nodes) {
      if (!junctions.has(key)) {
        nodes[key].color = "#808080";
      }
    }
    for (const key in pipes) {
      if (!pipeline.includes(key)) {
        pipes[key].color = "#808080";
      }
    }
    setDisplayNetworkData(tempNetworkData);

    const initPos = [0, 0];
    junctions.forEach((j) => {
      initPos[0] += nodes[j].GPS_LONGITUDE;
      initPos[1] += nodes[j].GPS_LATITUDE;
    });
    initPos[0] = initPos[0] / junctions.size;
    initPos[1] = initPos[1] / junctions.size;
    setInitViewPos(initPos);
  };

  const {fetchNetworkId} = useNetworkId();

  const setSectionSearchData = (sectionData: NetworkData) => {
    const sd: SearchDataType[] = [];
    Object.keys(sectionData.temp_nodes).map((keyName) =>
      sd.push({ name: keyName, id: sd.length, key: keyName, value: keyName, type: "junction" })
    );
    Object.keys(sectionData.temp_pipes).map((keyName) =>
      sd.push({ name: keyName, id: sd.length, key: keyName, value: keyName, type: "pipe" })
    );
    setSearchData(sd);
  };
  const setSectionInitViewPos = (sectionData: NetworkData) => {
    const initPos = [0, 0];
    for (const key in sectionData.temp_nodes) {
      initPos[0] += sectionData.temp_nodes[key].GPS_LONGITUDE;
      initPos[1] += sectionData.temp_nodes[key].GPS_LATITUDE;
    }
    initPos[0] = initPos[0] / Object.keys(sectionData.temp_nodes).length;
    initPos[1] = initPos[1] / Object.keys(sectionData.temp_nodes).length;
    setInitViewPos(initPos);
  };

  useEffect(() => {
    // get network_id, not used for now
    fetchNetworkId().then((nid) => {
      setNetworkId(nid);
      console.log(nid);
    });

    // set the subnet, default value is the first subnet
    const fetchSections = async () => {
      await axios.get<string[]>(`${API_URL}/network/pipeline/sections`).then((response) => {
        console.log(response.data);
        SetSubnetworks(response.data);
        setSelectedSubnetwork(response.data[0] ?? "");
        if (!!response.data[0]) {
          axios
            .get<NetworkData>(`${API_URL}/network/pipeline/${response.data[0]}`)
            .then((response) => {
              // console.log(response.data);
              setNetworkData(response.data);
              return response.data;
            })
            .then((data) => {
              setSectionSearchData(data);
              setSectionInitViewPos(data);
              return data;
            })
            .catch((e) => console.log(e));
        }
      });
    };
    fetchSections();
  }, []);

  useEffect(() => {
    console.log("useEffect:", pipeline);
    if (pipeline.length > 0) displayPipeline(pipeline);
    else setDisplayNetworkData(networkData);
  }, [pipeline]);

  useEffect(() => {
    if (networkData) {
      setSectionSearchData(networkData);
      setSectionInitViewPos(networkData);
    }
    setDisplayNetworkData(JSON.parse(JSON.stringify(networkData)));
  }, [networkData]);

  const handleChangeSubnetwork = async (e: any) => {
    setSelectedSubnetwork(e.target.value);
    await axios
      .get<NetworkData>(`${API_URL}/network/pipeline/${e.target.value}`)
      .then((response) => {
        console.log(response.data);
        setNetworkData(response.data);
      })
      .catch((e) => console.log(e));
  };

  return (
    <div style={{ display: "flex" }}>
      <Navbar />
      <PageWrapper>
        <Box display="flex" position="relative">
          <MapPannelWrapper>
            <Select
              style={{ display: "block", width: "100%" }}
              size="small"
              value={selectedSubnetwork}
              onChange={handleChangeSubnetwork}
            >
              {subnetworks.map((item, idx) => (
                <MenuItem key={idx} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
            <Tabs
              sx={{ width: "100%", minHeight: "2rem" }}
              TabIndicatorProps={{ hidden: true }}
              value={activeTab}
              onChange={(_, value) => setActiveTab(value)}
            >
              <Tab
                sx={{
                  width: "10rem",
                  minHeight: "2rem",
                  height: "2rem",
                  borderRadius: "8px 0px 0px 8px",
                  // borderRadius: "8px",
                  borderTop: "1px solid #CCD6E0",
                  borderLeft: "1px solid #CCD6E0",
                  borderBottom: "1px solid #CCD6E0",
                  textTransform: "none",
                  "&.Mui-selected": {
                    border: "1px solid #245FA6",
                    background: "#EFF5FA",
                  },
                }}
                label={t("pipelineDetails.networkSearch")}
                value="network"
              />
              <Tab
                sx={{
                  width: "10rem",
                  borderRadius: "0px 8px 8px 0px",
                  minHeight: "2rem",
                  height: "2rem",
                  // borderRadius: "8px",

                  borderTop: "1px solid #CCD6E0",
                  borderRight: "1px solid #CCD6E0",
                  borderBottom: "1px solid #CCD6E0",
                  textTransform: "none",
                  "&.Mui-selected": {
                    border: "1px solid #245FA6",
                    background: "#EFF5FA",
                  },
                }}
                label={t("pipelineDetails.pathFinder")}
                value="path"
              />
            </Tabs>

            {/* <Autocomplete
              sx={{ width: '100%' }}
              options={searchData}
              autoHighlight
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField {...params} label="SearchNode" />}
            /> */}

            {activeTab === "network" && (
              <SearchBarWrapper>
                <ReactSearchBox
                  placeholder={searchText.length===0?t("pipelineDetails.search"):searchText}
                  data={searchData}
                  clearOnSelect
                  onSelect={(record: any) => {
                    setSearchText(record.item.value);
                    handleSearch(record.item.key, record.item.type);
                  }}
                  onFocus={() => {
                    setSearchText("");
                    setDisplayNetworkData(networkData);
                  }}
                  leftIcon={<img src={SearchIcon} className="search-icon" />}
                  iconBoxSize="48px"
                  onChange={(text) => {
                    setSearchText(text);
                    if (!text) setDisplayNetworkData(networkData);
                  }}
                />
              </SearchBarWrapper>
            )}
            {activeTab === "path" && <PathFinder searchData={searchData} section={selectedSubnetwork} setPath={setPipeline} />}
            {/* {featureInfo && <FeatureInfoCard feature={featureInfo} />} */}
          </MapPannelWrapper>
          <ColorRange />

          <Box flex={1}>
            {displayNetworkData ? (
              <PipelineMap networkData={displayNetworkData} setFeatureInfo={setFeatureInfo} initPos={initViewPos} />
            ) : (
              <EmptyMap />
            )}
          </Box>
        </Box>
      </PageWrapper>
    </div>
  );
};

export default PipelineMapPage;

const MapPannelWrapper = styled(Box)({
  borderRadius: '8px',
  opacity: 0.9,
  position: "absolute",
  top: "1.5rem",
  left: "3rem",
  zIndex: 1,

  display: "inline-flex",
  padding: "16px",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "16px",

  background: "#FFF",
});
export const SearchBarWrapper = styled.div({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  ".react-search-box-dropdown": {
    maxHeight: "10rem",
    overflowY: "scroll",
    zIndex: 2,
  },
});
