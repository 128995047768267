import React, { useState, useTransition } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import { JunctionMap, JunctionMetadataType, JunctionsRawData, raw2junctions } from "../../pages/PipelineConfigPage";
import axios from "axios";
import { API_URL } from "../../constants";
import { useTranslation } from "react-i18next";

interface EditJunctionDialogProps {
  create: boolean; // is creating new junction or not
  network_id: string;
  open: boolean;
  selectedJunction: JunctionMetadataType | null; // selected junctions to edit
  setSelectedJunction: React.Dispatch<React.SetStateAction<JunctionMetadataType | null>>;
  setJunctions?: React.Dispatch<React.SetStateAction<JunctionMap>>;
  editable?: boolean;
}

// determine if 2 junctions are equal, if yes, won't request
function areJunctionsEqual(junction1: JunctionMetadataType, junction2: JunctionMetadataType): boolean {
  return (
    junction1.LABEL === junction2.LABEL &&
    junction1.GPS_LONGITUDE === junction2.GPS_LONGITUDE &&
    junction1.GPS_LATITUDE === junction2.GPS_LATITUDE &&
    junction1.ELEV_m === junction2.ELEV_m &&
    junction1.NODE_TYPE === junction2.NODE_TYPE &&
    junction1.PDMA === junction2.PDMA
  );
}

const EditJunctionDialog: React.FC<EditJunctionDialogProps> = ({
  create,
  open,
  selectedJunction,
  setSelectedJunction,
  network_id,
  setJunctions,
  editable,
}) => {
  const [editedJunction, setEditedJunction] = useState<JunctionMetadataType>(JSON.parse(JSON.stringify(selectedJunction)));
  const { t } = useTranslation();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // should
    console.log("changing data");
    console.log({
      ...editedJunction,
      [event.target.name]: event.target.value,
    });
    setEditedJunction({
      ...editedJunction,
      [event.target.name]: event.target.value,
    } as JunctionMetadataType);
  };
  const handleClose = () => {
    setSelectedJunction(null);
  };

  const handleSave = async () => {
    if (!selectedJunction) {
      console.log("selectedJunction is a null");
      handleClose();
      return;
    }
    console.log(editedJunction);
    console.log(selectedJunction);

    if (create) {
      axios
        .post(API_URL + `/network/junction/${network_id}`, {
          new_node_dict: editedJunction,
        })
        .catch((e) => console.log(e));
    } else {
      console.log("edit mode");
      if (!areJunctionsEqual(editedJunction, selectedJunction)) {
        console.log("data changed");
        axios.delete(API_URL + `/network/junction/${selectedJunction?.LABEL}/${network_id}`).then(() =>
          axios
            .post(API_URL + `/network/junction/${network_id}`, {
              new_node_dict: editedJunction,
            })
            .then(() => {
              axios.get(API_URL + "/network/junctions/" + network_id).then((junctionsRawData) => {
                const data =
                  typeof junctionsRawData.data === "string"
                    ? JSON.parse(junctionsRawData.data.replace(/\bNaN\b/g, "null"))
                    : junctionsRawData.data;
                const parsedJunctions = raw2junctions(data as JunctionsRawData);
                setJunctions!(parsedJunctions);
                return parsedJunctions;
              });
            })
        );
      } else {
        console.log("data isn't changed");
      }
    }
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleSave} fullWidth>
      <DialogTitle>
        {create
          ? t("uploadNetwork.dialog.addJunction")
          : editable
          ? t("uploadNetwork.dialog.editJunction")
          : selectedJunction?.LABEL}
      </DialogTitle>
      <DialogContent>
        {editable && (
          <DialogContentText>
            {create ? t("uploadNetwork.dialog.addJunctionIntro") : t("uploadNetwork.dialog.editJunctionIntro")}
          </DialogContentText>
        )}
        {create && (
          <Box display={"flex"} justifyContent={"center"} marginTop={"1rem"}>
            <TextField name="LABEL" label="Node Name" value={editedJunction.LABEL} onChange={handleChange} />
          </Box>
        )}

        <Box display={"flex"} justifyContent={"center"}>
          <Box display={"flex"} flexDirection={"column"} gap={"1rem"} margin={"1rem"}>
            <TextField
              disabled={true}//{!editable ? true : false}
              name="GPS_LONGITUDE"
              label={t("property.GPSLongitude")}
              value="GPS DATA"//{editedJunction.GPS_LONGITUDE ?? ""}
              onChange={handleChange}
              fullWidth
            />
            <TextField
              disabled= {true}//{!editable ? true : false}
              name="GPS_LATITUDE"
              label={t("property.GPSLatitude")}
              value="GPS DATA"//{editedJunction.GPS_LATITUDE ?? ""}
              onChange={handleChange}
              fullWidth
            />
            <TextField
              disabled={!editable ? true : false}
              name="ELEV_m"
              label={t("property.elevation")+"(m)"}
              value={editedJunction.ELEV_m ?? ""}
              onChange={handleChange}
              fullWidth
            />
            <TextField
              disabled={!editable ? true : false}
              name="NODE_TYPE"
              label={t("property.nodeType")}
              value={editedJunction.NODE_TYPE ?? ""}
              onChange={handleChange}
              fullWidth
            />
            <TextField
              disabled={!editable ? true : false}
              name="PDMA"
              label={t("property.PDMA")}
              value={editedJunction.PDMA ?? ""}
              onChange={handleChange}
              fullWidth
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t("common.cancel")}
        </Button>
        <Button onClick={handleSave} color="primary" disabled={!editable ? true : false}>
          {t("common.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditJunctionDialog;
