import React, { useState } from "react";
import InfoIcon from "@mui/icons-material/Info";
import { Typography, useTheme } from "@mui/material";
interface InfoTooltipProps {
  info?: string;
  html?: JSX.Element;
}

const InfoTooltip: React.FC<InfoTooltipProps> = ({ info, html }) => {
  const theme = useTheme();
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <span style={{ position: "relative", display: "inline-block" }}>
      <InfoIcon
        className="info-icon"
        sx={{
          cursor: "pointer",
          fontSize: "1rem",
          color: theme.palette.info.main,
          translate: "0 -50%",
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      />
      {showTooltip && info && (
        <Typography variant="body1">
          <div
            style={{
              position: "absolute",
              top: "90%",
              left: "30%",
              transform: "translateX(-25%)",
              padding: "10px",
              backgroundColor: "#f9f9f9",
              border: "1px solid #ddd",
              boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
              zIndex: 1,
            }}
            dangerouslySetInnerHTML={{ __html: info }}
          />
        </Typography>
      )}

      {showTooltip && html && <Typography variant="body1">{html}</Typography>}
    </span>
  );
};

export default InfoTooltip;
