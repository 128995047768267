// CustomLegend.tsx
import React, { useCallback, useState } from "react";
import { DefaultLegendContentProps, DefaultLegendContent } from "recharts";
import { DataKeyType } from "./NRWComparisonChart";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

interface OpacityProps {
  passedOpacity: DataKeyType;
  setPassedOpacity: React.Dispatch<React.SetStateAction<DataKeyType>>;
}
interface StrokeWidthProps {
  passedStrokeWidth: DataKeyType;
  setPassedStrokeWidth: React.Dispatch<React.SetStateAction<DataKeyType>>;
}

const CustomLegend: React.FC<DefaultLegendContentProps & OpacityProps & StrokeWidthProps> = ({
  payload,
  passedOpacity,
  setPassedOpacity,
  passedStrokeWidth,
  setPassedStrokeWidth,
}) => {
  const {t} = useTranslation();
  payload = payload?.map((p)=>{p['value']=t(`managementReport.legend.${p['value']}`); return p});

  const handleMouseEnter = useCallback(
    (o: any) => {
      console.log("enter");

      const { dataKey } = o;
      console.log(dataKey);

      setPassedOpacity({ ...passedOpacity, [dataKey]: 1 });
      setPassedStrokeWidth({ ...passedStrokeWidth, [dataKey]: 6 });
    },
    [passedOpacity, setPassedOpacity, passedStrokeWidth, setPassedStrokeWidth]
  );

  const handleMouseLeave = useCallback(
    (o: any) => {
      const { dataKey } = o;
      setPassedOpacity({ ...passedOpacity, [dataKey]: 0.9 });
      setPassedStrokeWidth({ ...passedStrokeWidth, [dataKey]: 4 });
    },
    [passedOpacity, setPassedOpacity, passedStrokeWidth, setPassedStrokeWidth]
  );


  return (
    <>
      <LegendWrapper>
        <DefaultLegendContent
          payload={payload?.filter((item) => item.type === "line")}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />
      </LegendWrapper>
      <div style={{ display: "inline-block", float: "right" }}>
        <DefaultLegendContent
          payload={payload?.filter((item) => item.type === "rect")}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />
      </div>
    </>
  );
};

export default CustomLegend;
const LegendWrapper = styled.div`
  /* float: right; */
  float: left;
  & .recharts-default-legend {
    display: flex;
    flex-direction: column-reverse;
    gap: 0.5rem;
    align-items: start;
    transform: translateY(-50%);
    /* transform: translateX(-15%) translateY(-50%); */
  }
`;
