import { createContext, useContext, useState } from "react";

type MenuContextType = {
    menuInfo: {[key: string]: boolean}
    setMenuInfo: (menuInfo: {[key: string]: boolean}) => void;
}
const MenuContext = createContext<MenuContextType>({
    menuInfo: {},
    setMenuInfo: ()=>{},
});

export const MenuContextProvider:React.FC<{ children: React.ReactNode }> = ({children})=>{
    const [menuInfo, setMenuInfo] = useState<{[key: string]: boolean}>({
        Config: true,
        Report: true,
    })

    return <MenuContext.Provider value={{menuInfo, setMenuInfo}}>{children}</MenuContext.Provider>
}
export const useMenuContext = () => useContext(MenuContext)