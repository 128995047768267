import React from "react";
import AppMenu from "./AppMenu";
import { Box, MenuItem, Select, Typography } from "@mui/material";
import styled from "styled-components";
import Logo from "../../icons/beaglelogo 2.png";
import { useTranslation } from "react-i18next";
// import i18n from "../../i18n";

const NavbarWrapper = styled.div`
  background-color: #000a14;
  padding: 44px 32px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
`;
const Navbar: React.FC = () => {
  const { i18n } = useTranslation();
  return (
    <NavbarWrapper>
      <Box display="flex" flexDirection="column">
        <Box display="flex" alignItems="center" marginLeft="1rem" gap="1rem" marginBottom="1.5rem">
          <img
            style={{
              width: "2rem",
              height: "2rem",
              borderRadius: "50%",
              objectFit: "cover",
              objectPosition: "50% 30%",
            }}
            src={Logo}
            alt="logo"
          />
          <Typography component="span" color={"white"}>
            GAILL
          </Typography>
          {/* for testing  */}
        </Box>
        {/* <Box display='flex' gap='1rem' alignContent='center'>
          <Box display='flex' justifyContent='center' alignItems='center' textAlign='center'>Language:</Box>
          <Select sx={{ color: "white" }} value={i18n.language} onChange={(e) => i18n.changeLanguage(e.target.value)}>
            <MenuItem value={"zh"}>中文</MenuItem>
            <MenuItem value={"en"}>English</MenuItem>
            <MenuItem value={"vn"}>Tiếng Việt</MenuItem>
            <MenuItem value={"th"}>แบบไทย</MenuItem>
          </Select>
        </Box> */}
      </Box>
      <AppMenu />
    </NavbarWrapper>
  );
};

export default Navbar;

export const PageWrapper = styled.div`
  background-color: #000a14;
  flex-grow: 1;

  & > :first-child {
    border-radius: 20px 0 0 20px;
    background-color: #ffffff;
    height: 100vh;
    overflow: scroll;
  }
`;
