import dayjs, { Dayjs } from "dayjs";
import React, { createContext, useContext, useState } from "react";

type DateContextType = {
  startDate: Dayjs | null;
  setStartDate: (date: Dayjs | null) => void;
  endDate: Dayjs | null;
  setEndDate: (date: Dayjs | null) => void;
};

// Initialize the context with default values.
const DateContext = createContext<DateContextType>({
  startDate: null,
  setStartDate: () => {},
  endDate: null,
  setEndDate: () => {},
});

export const DateProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const maxDate = dayjs();
  const minDate = maxDate.subtract(1, "day");
  const [endDate, setEndDate] = useState<Dayjs | null>(maxDate);
  const [startDate, setStartDate] = useState<Dayjs | null>(minDate);

  return <DateContext.Provider value={{ startDate, setStartDate, endDate, setEndDate }}>{children}</DateContext.Provider>;
};

export const useDateContext = (): DateContextType => useContext(DateContext);
