import React, { useState } from "react";
import { ComposedChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Line, Label, ResponsiveContainer } from "recharts";
import CustomLegend from "./CustomLegend";
import styled from "styled-components";
import { useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

export type RecordType = {
  date: string;
  total_consumption: number;
  total_supply: number;
  nrw: number;
};

export type DataKeyType = Omit<RecordType, "date">;

interface ChartDataProps {
  chartHeight?: number;
  chartWidth?: number;
  chartData: RecordType[];
}

const convertDateFormat = (dateString: string): string => {
  let [_, month, day] = dateString.split("-");
  return `${month}/${day}`;
};

const ChartCostumStyleWrapper = styled.div`
  display: flex;
  margin: 0 1rem;

  flex-direction: column;
  justify-content: "space-between";
  .recharts-surface {
    overflow: visible;
  }
`;

const NRWComparisonChart: React.FC<ChartDataProps> = ({ chartData, ...props }) => {
  const theme = useTheme();
  const [opacity, setOpacity] = useState<DataKeyType>({
    total_consumption: 0.9,
    total_supply: 0.9,
    nrw: 0.9,
  });
  const [strokeWidth, setStrokeWidth] = useState<DataKeyType>({
    total_consumption: 4,
    total_supply: 4,
    nrw: 4,
  });
  console.log("chart rendered");
  const barNum = chartData.length > 0 ? chartData.length : 1;
  const {t} = useTranslation();
  return (
    <ChartCostumStyleWrapper
    // height={props.chartHeight == null ? 700 : props.chartHeight}
    // width={props.chartWidth == null ? 375 : props.chartWidth}
    >
      <ComposedChart
        height={props.chartHeight == null ? 700 : props.chartHeight}
        width={props.chartWidth == null ? 375 : props.chartWidth}
        data={chartData}
        margin={{ top: 20, right: 10, left: 20, bottom: 5 }}
        style={{ overflow: "visible" }}
      >
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <XAxis dataKey="date" tickFormatter={convertDateFormat} />
        <YAxis yAxisId="0" unit="m³" type="number" domain={[0, "dataMax"]}>
          <Label
            value={Array(17).join('\u00A0') + t("managementReport.legend.volumeOfWater", 'Volume of Water')}
            offset={-20}
            position="insideBottomLeft"
            angle={-90}
            transform=""
          />
        </YAxis>
        {/* <YAxis yAxisId="1" orientation="right" type="number" domain={[0, 100]} tickFormatter={(decimal) => `${decimal}%`}>
          <Label value="NRW" offset={0} position="right" angle={90} />
        </YAxis> */}

        <Tooltip contentStyle={{ opacity: 0.9 }} wrapperStyle={{ opacity: 0.9 }} />

        <Legend
          content={
            <CustomLegend
              passedOpacity={opacity}
              setPassedOpacity={setOpacity}
              passedStrokeWidth={strokeWidth}
              setPassedStrokeWidth={setStrokeWidth}
            />
          }
          verticalAlign="top"
          height={36}
        />

        <Bar
          dataKey="nrw"
          yAxisId="0"
          fill={theme.palette.warning.main}
          maxBarSize={60}
          radius={[20 / barNum, 20 / barNum, 0, 0]}
          strokeOpacity={opacity.nrw}
        />
        <Line
          type="monotone"
          dataKey="total_consumption"
          yAxisId="0"
          stroke={theme.palette.info.main}
          strokeWidth={strokeWidth.total_consumption}
          strokeOpacity={opacity.total_consumption}
        />
        <Line
          type="monotone"
          dataKey="total_supply"
          yAxisId="0"
          stroke={theme.palette.success.light}
          strokeWidth={strokeWidth.total_supply}
          strokeOpacity={opacity.total_supply}
        />
      </ComposedChart>
    </ChartCostumStyleWrapper>
  );
};

export default React.memo(NRWComparisonChart);
