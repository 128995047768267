import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import App from './app'
import './index.css'
import './i18n'

const root = ReactDOM.createRoot(
  document.getElementById('root')! 
);
root.render(
  <App/>
);

