import React, { useEffect, useState } from "react";
import NRWComparisonChart, { RecordType } from "../components/chart_report/NRWComparisonChart";
import { API_URL } from "../constants";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import axios from "axios";
import { Box, Divider, Typography } from "@mui/material";
import { useDateContext } from "../hooks/useDateContext";
import Navbar, { PageWrapper } from "../components/utils/Navbar";
import PageHeader from "../components/utils/PageHeader";
import PipeLeakIcon from "../icons/PipeLeakIcon.svg";
import InfoTooltip from "../components/utils/InfoIcon";
import { useTranslation } from "react-i18next";

type rawChartDataType = {
  name: string[];
  t_stamp: string[];
  value: (number | null)[];
};

function numberWithCommas(num: number): string {
  const parts = num.toFixed(2).split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

function convertRawChartDataToRecord(rawData: rawChartDataType): RecordType[] {
  const recordMap: Map<string, RecordType> = new Map();

  rawData.name.forEach((name, i) => {
    if (name === "nrw" || name === "total_supply" || name === "total_consumption") {
      // If a record does not exist for this date yet, create one
      if (!recordMap.has(rawData.t_stamp[i])) {
        recordMap.set(rawData.t_stamp[i], {
          date: rawData.t_stamp[i],
          nrw: 0,
          total_supply: 0,
          total_consumption: 0,
        });
      }
      // Update the value of the corresponding property if it's not null
      const record = recordMap.get(rawData.t_stamp[i]);
      if (record && rawData.value[i] !== null) {
        record[name] = parseFloat(rawData.value[i]?.toFixed(2) ?? "0") as number; // Type assertion since we checked for null
      }
    }
  });
  // change the nrw to m3
  return Array.from(recordMap.values()).map((record) => {
    record.nrw = parseFloat((record.total_supply - record.total_consumption).toFixed(2) ?? "0") as number;
    return record;
  });
}

export const NRWMetricsCard: React.FC<{
  number: number;
  unit: string;
  description: string;
  icon?: JSX.Element;
  color: string;
}> = ({ number, unit, description, color, icon }) => {
  console.log(description, number, unit);
  return (
    <Box
      borderRadius="8px"
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"space-between"}
      padding={"16px 20px"}
      gap={"16px"}
      sx={{ background: color }}
      boxSizing={"content-box"}
      minWidth={"25%"}
    >
      <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
        <Typography component={"span"} variant="h5" color="#000A14">
          {description}
        </Typography>
        <span>{icon}</span>
      </div>
      <div>
        <Typography
          component="span"
          fontFamily={"Inter"}
          fontSize={"48px"}
          fontStyle={"normal"}
          fontWeight={"600px"}
          lineHeight={"36px"}
        >
          {Number.isNaN(number) ? "--" : numberWithCommas(Math.round(number * 10) / 10)}
        </Typography>

        {/* make the percentage red */}
        {unit.includes("(") ? (
          <Typography
            component="span"
            fontFamily={"Inter"}
            fontSize={"24px"}
            fontStyle={"normal"}
            fontWeight={"600px"}
            lineHeight={"28px"}
          >
            {unit.slice(0, unit.indexOf("(") + 1)}
            <span style={{ color: "red" }}>{unit.slice(unit.indexOf("(") + 1, unit.indexOf(")"))}</span>
            {unit.slice(unit.indexOf(")"))}
          </Typography>
        ) : (
          <Typography
            component="span"
            fontFamily={"Inter"}
            fontSize={"24px"}
            fontStyle={"normal"}
            fontWeight={"600px"}
            lineHeight={"28px"}
          >
            {unit}
          </Typography>
        )}
      </div>
    </Box>
  );
};
function averageRecords(records: RecordType[]): RecordType {
  const { totalConsumptionSum, totalSupplySum, nrwSum } = records.reduce(
    (acc, record) => ({
      totalConsumptionSum: acc.totalConsumptionSum + record.total_consumption,
      totalSupplySum: acc.totalSupplySum + record.total_supply,
      nrwSum: acc.nrwSum + record.nrw,
    }),
    {
      totalConsumptionSum: 0,
      totalSupplySum: 0,
      nrwSum: 0,
    }
  );

  const recordCount = records.length;

  return {
    date: "Average",
    total_consumption: totalConsumptionSum / recordCount,
    total_supply: totalSupplySum / recordCount,
    nrw: nrwSum / recordCount,
  };
}
const ChartReportPage: React.FC = () => {
  const { startDate, setStartDate, endDate, setEndDate } = useDateContext();
  const [displayedChartData, setDisplayedChartData] = useState<RecordType[]>([]);
  const [lastDateData, setLastDateData] = useState<RecordType>({} as RecordType);
  const [firstDateData, setFirstDateData] = useState<RecordType>({} as RecordType);

  const [averageData, setAverageData] = useState<RecordType>({} as RecordType);
  const [loadingChart, setLoadingChart] = useState<boolean>(false);
  const { t } = useTranslation();
  const info = `<div style="width: 300px;font-size:14px; font-weight: normal; padding: 5px;"><p>${t(
    "info.managementReport",
    "The chart shows non-revenue water, consumption, supply from the latest data"
  )}.</p></div>`;

  useEffect(() => {
    setLoadingChart(true);
    axios
      .get<rawChartDataType>(API_URL + "/dailydata" + `/${startDate?.format("YYYY-MM-DD")}/${endDate?.format("YYYY-MM-DD")}`)
      .then((response) => {
        console.log("raw chart data");
        console.log(response.data);
        console.log("converted chart data");
        console.log(convertRawChartDataToRecord(response.data));

        return convertRawChartDataToRecord(response.data);
      })
      .then((newDisplayedData) => {
        if (newDisplayedData.length > 0) setLastDateData(newDisplayedData[newDisplayedData.length - 1]);
        else setLastDateData({} as RecordType);

        if (newDisplayedData.length >= 1) setFirstDateData(newDisplayedData[1]);
        else setFirstDateData({} as RecordType);

        if (newDisplayedData.length > 0) setAverageData(averageRecords(newDisplayedData.slice(1)));
        else setAverageData({} as RecordType);

        setDisplayedChartData(newDisplayedData);
        return newDisplayedData;
      })
      .catch((e) => console.log(e))
      .finally(() => setLoadingChart(false));
  }, [startDate, endDate]);
  return (
    <div style={{ display: "flex" }}>
      <Navbar />
      <PageWrapper>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <PageHeader title={t("managementReport.title")} description={t("managementReport.description")} />
          <div style={{ marginTop: "24px", display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }}>
            <Box marginTop="24px" gridTemplateColumns="1fr auto 1fr" display="inline-grid" width={"100%"}>
              <div></div>
              <Typography component="span" variant="h1" sx={{ justifySelf: "center" }}>
                {t("managementReport.content.title")} <InfoTooltip info={info}></InfoTooltip>
              </Typography>
              <Typography sx={{ justifySelf: "flex-end", marginRight: "10rem" }} component="span" variant="body2">
                {t("managementReport.repdate")}: {dayjs().format("MM/DD/YYYY").toString()}
              </Typography>
            </Box>

            <Box marginTop="8px">
              <Typography variant="body2">
                {t("managementReport.reportPeriod")}: {startDate?.format("MM/DD/YYYY").toString()} -{" "}
                {endDate?.format("MM/DD/YYYY").toString()}
              </Typography>
              <Typography variant="body2">
                {t("managementReport.dataPeriod")}: {dayjs(firstDateData.date).format("MM/DD/YYYY")} - {dayjs(lastDateData.date).format("MM/DD/YYYY")}
              </Typography>
            </Box>
            <Box marginTop={"20px"} width={"80%"} display={"flex"} justifyContent={"space-between"}>
              <NRWMetricsCard
                description={t("managementReport.content.card.lastDailySupply")}
                number={lastDateData.total_supply ?? NaN}
                unit="m³"
                color="#EFF5FA"
              />
              <NRWMetricsCard
                description={t("managementReport.content.lastNon-revenueWater")}
                icon={<img src={PipeLeakIcon} alt="Pipe Leak Icon" />}
                number={(lastDateData.total_supply ?? NaN) - (lastDateData.total_consumption ?? NaN)}
                unit={
                  Number.isNaN(lastDateData.nrw ?? NaN)
                    ? `%`
                    : `m³(${((100 * lastDateData.nrw) / (lastDateData.total_supply ?? NaN)).toFixed(2)}%)`
                }
                color="#FFF4EA"
              />
              <NRWMetricsCard
                description={t("managementReport.content.card.lastDailyConsumption")}
                number={lastDateData.total_consumption ?? NaN}
                unit="m³"
                color="#EFF5FA"
              />
            </Box>
            <Box marginTop={"20px"} width={"80%"} display={"flex"} justifyContent={"space-between"}>
              <NRWMetricsCard
                description={t("managementReport.content.card.averageDailySupply")}
                number={averageData.total_supply ?? NaN}
                unit="m³"
                color="#EFF5FA"
              />
              <NRWMetricsCard
                description={t("managementReport.content.averageNon-revenueWater")}
                icon={<img src={PipeLeakIcon} alt="Pipe Leak Icon" />}
                number={(averageData.total_supply ?? NaN) - (averageData.total_consumption ?? NaN)}
                unit={
                  Number.isNaN(averageData.nrw ?? NaN)
                    ? `%`
                    : `m³(${((100 * averageData.nrw) / (averageData.total_supply ?? NaN)).toFixed(2)}%)`
                }
                color="#FFF4EA"
              />
              <NRWMetricsCard
                description={t("managementReport.content.card.averageDailyConsumption")}
                number={averageData.total_consumption ?? NaN}
                unit="m³"
                color="#EFF5FA"
              />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              marginTop={"32px"}
              width={"90%"}
              borderRadius="16px"
              border="2px solid #EFF5FA"
              padding="23px 32px 32px"
              margin="38px 0 32px"
            >
              {/* <Typography variant="h2" marginBottom="1rem">
                Non-revenue Water
              </Typography> */}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div
                  style={{ width: "60%", display: "flex", justifyContent: "space-between", alignItems: "center", gap: "1rem" }}
                >
                  <DesktopDatePicker
                    label={t("managementReport.content.chart.setStartDate")}
                    value={startDate}
                    // minDate={minDate}
                    maxDate={endDate}
                    onChange={(sd) => setStartDate(sd)}
                    disabled={loadingChart}
                  />
                  {/* <div>---</div> */}
                  <Divider></Divider>
                  <DesktopDatePicker
                    label={t("managementReport.content.chart.setEndDate")}
                    value={endDate}
                    onChange={(ed) => setEndDate(ed)}
                    minDate={startDate}
                    disabled={loadingChart}
                  />
                </div>
                {(endDate ?? dayjs("9999-1-1")) < (startDate ?? dayjs("0000-1-1")) && (
                  <div style={{ textAlign: "center", marginTop: "1rem", color: "#d32f2f" }}>Invalid date range.</div>
                )}
              </LocalizationProvider>
              <Typography variant="h2" marginBottom="0rem" marginTop="2.5rem">
                {t("managementReport.content.non-revenueWater")}
              </Typography>
              <NRWComparisonChart chartData={displayedChartData.slice(1)} chartWidth={900} chartHeight={350} />
            </Box>
          </div>
        </div>
      </PageWrapper>
    </div>
  );
};

export default ChartReportPage;
