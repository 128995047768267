import { createContext, useContext, useState } from "react";
import { FilterConfigType } from "../pages/WorkOrderGenerationPage";
import { AnomalyRecordType } from "../components/anomaly_report/AnomalyReportDataGrid";
import { JunctionMap, JunctionMetadataType, PipeMap, PipeMetadataType } from "../pages/PipelineConfigPage";

export const initConfig: FilterConfigType = {
  DMA:[],
  detection_status: ["identified"],
  anomaly_type: ["N/A"],
  repair_status: ["N/A"],
  netType: ["pipe"],
  accuracyLevel: 0.7,
  lengthRange: ["0,Infinity"],
  diameterRange: ["0,Infinity"],
  material: ["DI"],
};

type FilterConfigContextType = {
  filterConfig: FilterConfigType;
  setFilterConfig: React.Dispatch<React.SetStateAction<FilterConfigType>>;
};
const FilterConfigContext = createContext<FilterConfigContextType>({
  filterConfig: {} as FilterConfigType,
  setFilterConfig: () => {},
});

export const FilterConfigContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [filterConfig, setFilterConfig] = useState<FilterConfigType>(initConfig);
  return <FilterConfigContext.Provider value={{ filterConfig, setFilterConfig }}>{children}</FilterConfigContext.Provider>;
};
export const useFilterConfigContext = () => useContext(FilterConfigContext);
