import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { PipeMap, PipeMetadataType, PipesRawData, raw2pipes } from "../../pages/PipelineConfigPage";
import axios from "axios";
import { API_URL } from "../../constants";
import { useTranslation } from "react-i18next";

interface EditPipeDialogProps {
  create: boolean;
  network_id: string;
  open: boolean;
  selectedPipe: PipeMetadataType | null;
  setSelectedPipe: React.Dispatch<React.SetStateAction<PipeMetadataType | null>>;
  setPipes?: React.Dispatch<React.SetStateAction<PipeMap>>;
  editable?: boolean;
}

function arePipesEqual(pipe1: PipeMetadataType, pipe2: PipeMetadataType): boolean {
  return (
    pipe1.LABEL === pipe2.LABEL &&
    pipe1.START_NODE === pipe2.START_NODE &&
    pipe1.STOP_NODE === pipe2.STOP_NODE &&
    pipe1.LENGTH_m === pipe2.LENGTH_m &&
    pipe1.D_mm === pipe2.D_mm &&
    pipe1.MATERIAL === pipe2.MATERIAL 
  );
}

const EditPipeDialog: React.FC<EditPipeDialogProps> = ({
  create,
  open,
  selectedPipe,
  setSelectedPipe,
  network_id,
  setPipes,
  editable,
}) => {
  const [editedPipe, setEditedPipe] = useState<PipeMetadataType>(JSON.parse(JSON.stringify(selectedPipe)));
  const { t } = useTranslation();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // should
    console.log("changing data");
    console.log({
      ...editedPipe,
      [event.target.name]: event.target.value,
    });
    setEditedPipe({
      ...editedPipe,
      [event.target.name]: event.target.value,
    } as PipeMetadataType);
  };
  const handleClose = () => {
    setSelectedPipe(null);
  };

  const handleSave = async () => {
    if (!selectedPipe) {
      console.log("selectedPipe is a null");
      handleClose();
      return;
    }
    console.log(editedPipe);
    console.log(selectedPipe);

    if (create) {
      axios
        .post(API_URL + `/network/pipe/${network_id}`, {
          new_edge_dict: editedPipe,
        })
        .catch((e) => console.log(e));
    } else {
      console.log("edit mode");
      if (!arePipesEqual(editedPipe, selectedPipe)) {
        console.log("data changed");
        axios.delete(API_URL + `/network/pipe/${selectedPipe?.LABEL}/${network_id}`).then(() =>
          axios
            .post(API_URL + `/network/pipe/${network_id}`, {
              new_edge_dict: editedPipe,
            })
            .then(() => {
              axios.get(API_URL + "/network/pipes/" + network_id).then((pipesRawData) => {
                const data =
                  typeof pipesRawData.data === "string"
                    ? JSON.parse(pipesRawData.data.replace(/\bNaN\b/g, "null"))
                    : pipesRawData.data;
                const parsedPipes = raw2pipes(data as PipesRawData);
                setPipes!(parsedPipes);
                return parsedPipes;
              });
            })
        );
      } else {
        console.log("data isn't changed");
      }
    }
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleSave} fullWidth>
      <DialogTitle>
        {create ? t("uploadNetwork.dialog.addPipe") : editable ? t("uploadNetwork.dialog.editPipe") : selectedPipe?.LABEL}
      </DialogTitle>
      <DialogContent>
        {editable && (
          <DialogContentText>
            {create ? t("uploadNetwork.dialog.addPipeIntro") : t("uploadNetwork.dialog.editPipeIntro")}
          </DialogContentText>
        )}
        {create && (
          <Box display={"flex"} justifyContent={"center"} marginTop={"1rem"}>
            <TextField name="LABEL" label="Edge Name" value={editedPipe.LABEL} onChange={handleChange} />
          </Box>
        )}

        <Box display={"flex"} justifyContent={"center"}>
          <Box display={"flex"} flexDirection={"column"} gap={"1rem"} margin={"1rem"}>
            <TextField
              disabled={!editable ? true : false}
              name="MATERIAL"
              label={t("property.material")}
              value={editedPipe.MATERIAL}
              onChange={handleChange}
              fullWidth
            />
            <TextField
              disabled={!editable ? true : false}
              name="D_mm"
              label={`${t("property.diameter")}(mm)`}
              value={editedPipe.D_mm}
              onChange={handleChange}
              fullWidth
            />
            {/* <TextField
              disabled={!editable ? true : false}
              name="ROUGHNESS"
              label={t("property.roughness")}
              value={editedPipe.ROUGHNESS}
              onChange={handleChange}
              fullWidth
            /> */}
            <TextField
              disabled={!editable ? true : false}
              name="LENGTH_m"
              label= {`${t("property.length")}(m)`}
              value={editedPipe.LENGTH_m}
              onChange={handleChange}
              fullWidth
            />
          </Box>
          <Box display={"flex"} flexDirection={"column"} gap={"1rem"} margin={"1rem"}>
            <TextField
              disabled={!editable ? true : false}
              name="START_NODE"
              label={t("property.startNode")}
              value={editedPipe.START_NODE}
              onChange={handleChange}
              fullWidth
            />
            <TextField
              disabled={!editable ? true : false}
              name="STOP_NODE"
              label={t("property.stopNode")}
              value={editedPipe.STOP_NODE}
              onChange={handleChange}
              fullWidth
            />
            <TextField
              disabled={!editable ? true : false}
              name="VALVE"
              label={t("property.valve")}
              value={String(editedPipe.VALVE ?? "")}
              onChange={handleChange}
              fullWidth
            />
            {/* <TextField
              disabled={!editable ? true : false}
              name="VALVE_STATUS"
              label={t("property.valveStatus")}
              value={String(editedPipe.VALVE_STATUS ?? "")}
              onChange={handleChange}
              fullWidth
            /> */}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t("common.cancel")}
        </Button>
        <Button onClick={handleSave} color="primary" disabled={!editable ? true : false}>
          {t("common.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditPipeDialog;
