import React, { useEffect, useState } from "react";
import PageHeader from "../components/utils/PageHeader";
import Navbar, { PageWrapper } from "../components/utils/Navbar";
import {
  AnomalyRecordType,
  TableData,
  convertToAnomalyRecords,
  translate,
} from "../components/anomaly_report/AnomalyReportDataGrid";
import {
  JunctionMap,
  JunctionMetadataType,
  JunctionsRawData,
  PipeMap,
  PipeMetadataType,
  PipesRawData,
  raw2junctions,
  raw2pipes,
} from "./PipelineConfigPage";
import { initConfig, useFilterConfigContext } from "../hooks/useFilterConfig";
import { useNetworkId } from "../hooks/useNetworkId";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  styled,
  Grid,
  Typography,
  ListItemText,
} from "@mui/material";
import axios from "axios";
import { API_URL } from "../constants";
import { anomalyFilter, anomaly2WorkOrder } from "../utils/AnomalyFilter";
import WorkOrderCheckbox from "../components/work_order_generation/WorkOrderCheckbox";
import { PDFViewer } from "@react-pdf/renderer";
import WorkOrderPDF from "../components/work_order_generation/WorkOrderPDF";
import { useTranslation } from "react-i18next";
import { DataGrid, GridColDef, GridToolbar, GridValueFormatterParams } from "@mui/x-data-grid";
import { smallTypography } from "../app";
import {
  GridToolbarContainer,
  gridDensitySelector,

} from '@mui/x-data-grid';

export type FilterConfigType = {
  detection_status: string[];
  anomaly_type: string[];
  repair_status: string[];
  netType: ("pipe" | "node" | "house")[];
  accuracyLevel: number;
  DMA: string[];
  lengthRange: string[];
  diameterRange: string[];
  material: string[];
};

export type WorkOrderType = {
  DMA: string;
  netType: "house" | "pipe" | "node";
  startNodeGPS?: string[];
  stopNodeGPS?: string[];
} & AnomalyRecordType &
  Partial<PipeMetadataType> &
  Partial<JunctionMetadataType>;

const options = {
  detection_status: ["abnormal", "false alert", "identified"],
  anomaly_type: ["N/A", "leak", "clog", "pilferage"],
  netType: ["pipe", "node", "house"],
  repair_status: ["N/A", "repaired", "not repaired"],
  accuracyLevel: [0.5, 0.6, 0.7, 0.8, 0.9],
};
function uppercase(str: string) {
  return str.replace(/\w\S*/g, function (word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });
}
const WorkOrderGenerationPage = () => {
  const [anomalies, setAnomalies] = useState<AnomalyRecordType[]>([]); // all anomaly
  const [pipes, setPipes] = useState<PipeMap>({} as PipeMap); // all anomaly
  const [junctions, setJunctions] = useState<JunctionMap>({} as JunctionMap); // all anomaly
  const [selectedWorkOrders, setSelectedWorkOrders] = useState<WorkOrderType[]>([]);
  const [workOrders, setWorkOrders] = useState<WorkOrderType[]>([]);
  const [openPDF, setOpenPDF] = useState<boolean>(false);

  const [open, setOpen] = useState<boolean>(false);
  const [testMessage, setTestMessage] = useState<string>("");

  const { fetchNetworkId, networkId, setNetworkId } = useNetworkId();
  const { filterConfig, setFilterConfig } = useFilterConfigContext();

  const { t } = useTranslation();
  useEffect(() => {
    // get network_id
    fetchNetworkId()
      .then((nid) => {
        setNetworkId(nid);
        return nid;
      })
      .then((nid) => {
        // get pipe metadata
        axios.get<string | JunctionsRawData>(API_URL + "/network/junctions/" + nid).then((junctionsRawData) => {
          // console.log(API_URL + "/network/junctions/" + network_id);
          console.log("junctionsRawData", junctionsRawData);

          // parse raw data {[column]: arrays} to JunctionMap
          const parsedJunctions = raw2junctions(
            // if return data contains NaN, then it will be a string type instead of Json
            typeof junctionsRawData.data === "string"
              ? (JSON.parse(junctionsRawData.data.replace(/\bNaN\b/g, "null")) as JunctionsRawData)
              : junctionsRawData.data
          );
          setJunctions(parsedJunctions);
          return parsedJunctions;
        });

        // fetch raw pipe in a form of {[column]: arrays}, same as above
        axios.get<string | PipesRawData>(API_URL + "/network/pipes/" + nid).then((pipesRawData) => {
          console.log("pipesRawData", pipesRawData);
          const parsedPipes = raw2pipes(
            // if response contains NaN which is not a JSON format, replace it with null
            typeof pipesRawData.data === "string"
              ? (JSON.parse(pipesRawData.data.replace(/\bNaN\b/g, "null")) as PipesRawData)
              : pipesRawData.data
          );

          setPipes(parsedPipes);

          return parsedPipes;
        });
      });
  }, []);

  // get abnormals
  useEffect(() => {
    console.log("fetching table data");
    axios
      .get<TableData>(API_URL + "/abnormals")
      .then((response) => {
        const anomalyRecords = convertToAnomalyRecords(response.data, 0);
        setAnomalies(anomalyRecords ?? []);
      })
      .catch((e) => {
        console.log("Failed to get Anomaly");
      });
  }, []);

  // testing selectedWorkOrders
  useEffect(() => {
    console.log("selectedWorkOrders");
    console.log(selectedWorkOrders);

    console.log("workOrders");
    console.log(workOrders);
  });

  // generate workorder for pipes here
  function handleFilterPipe() {
    const filteredPipeAnomalies = anomalyFilter(filterConfig, anomalies, pipes);
    setWorkOrders((prev) => [...prev, ...anomaly2WorkOrder(networkId, filteredPipeAnomalies, pipes, junctions)]);
    setOpen(true);
  }

  // generate workorder for node here
  function handleFilterNode() {
    const filteredJunctionAnomalies = anomalyFilter(filterConfig, anomalies, undefined);
    setWorkOrders((prev) => [...prev, ...anomaly2WorkOrder(networkId, filteredJunctionAnomalies, undefined, junctions)]);
    setOpen(true);
  }

  function handleReset() {
    setFilterConfig(initConfig);
    setWorkOrders([]);
    setSelectedWorkOrders([]);
  }
  const translationMapping: { [key: string]: string } = {
    pipe: t("networkType.pipe"),
    house: t("networkType.house"),
    node: t("networkType.node"),
    abnormal: t("detectionStatus.Abnormal"),
    "false alert": t("detectionStatus.FalseAlert"),
    identified: t("detectionStatus.Identified"),
    leak: t("anomalyType.Leak"),
    clog: t("anomalyType.Clog"),
    pilferage: t("anomalyType.Pilferage"),
    "N/A": "N/A",
    "not repaired": t("repairStatus.notRepaired", "not repaired"),
    repaired: t("repairStatus.repaired", "repaired"),
    "0,Infinity": t("generateWorkOrder.content.all",'All'),
    "0,1": "<1m",
    "1,10": "1-10m",
    "10,50": "10-50m",
    "50,Infinity": ">50m",
    "0,20":"<20mm",
    "20,50":"20-50mm",
    "50,200":"50-200mm",
    "200,Infinity":">200mm",
  };

  function woIncludes(workOrders: WorkOrderType[], selectedWorkOrder: WorkOrderType) {
    return workOrders.map((wo) => wo.id).includes(selectedWorkOrder.id);
  }
  // when user check/uncheck the box,
  function onToggleSelect(workOrder: WorkOrderType) {
    if (woIncludes(selectedWorkOrders, workOrder)) {
      setSelectedWorkOrders((prev) => prev.filter((wo) => wo.id !== workOrder.id));
    } else {
      setSelectedWorkOrders((prev) => [...prev, workOrder]);
    }
  }

  function handleGenerate() { }

  return (
    <div style={{ display: "flex" }}>
      <Navbar />
      <PageWrapper>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            overflowX: "hidden",
          }}
        >
          <PageHeader
            title={t("generateWorkOrder.title", "Generate Work Order")}
            description={t("generateWorkOrder.description", "Generate work order based on the data")}
          />

          <Box margin="2rem 3rem">
            {/* <div>{testMessage}</div> */}
            <Grid container spacing={4} marginTop={2}>
              <Grid item xs={9}>
                {/* DMA */}
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="DMA-label">{t("generateWorkOrder.content.dma")}</InputLabel>
                  <Select labelId="DMA-label" label={t("generateWorkOrder.content.dma")} value={networkId} onChange={() => { }}>
                    {/* <MenuItem value={networkId}>All</MenuItem> */}
                    <MenuItem value={networkId}>{networkId}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {/* Accuracy Level */}
              <Grid item xs={3}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="accuracy-level-label">{t("generateWorkOrder.content.accuracyLevel")}</InputLabel>
                  <Select
                    labelId="accuracy-level-label"
                    label={t("generateWorkOrder.content.accuracyLevel")}
                    value={filterConfig.accuracyLevel}
                    onChange={(e) => {
                      setFilterConfig((prev) => ({
                        ...prev,
                        accuracyLevel: Number(e.target.value),
                      }));
                    }}
                  >
                    {options.accuracyLevel.map((al) => (
                      <MenuItem key={al} value={al}>
                        {al * 100}%+
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                {/* Detection Status */}
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="DS-label">{t("generateWorkOrder.content.detectionStatus")}</InputLabel>
                  <Select
                    labelId="DS-label"
                    label={t("generateWorkOrder.content.detectionStatus")}
                    value={filterConfig.detection_status}
                    multiple
                    renderValue={(selected) =>
                      // make the first char uppercase
                      selected.map((s) => translationMapping[s]).join("&")
                    }
                    onChange={(e) => {
                      console.log("DS");
                      console.log(e.target.value);

                      // const value = e.target.value as "abnormal" | "false alert" | "identified";
                      if (e.target.value.length !== 0)
                        setFilterConfig((prev) => ({
                          ...prev,
                          detection_status: e.target.value as string[],
                        }));
                    }}
                  >
                    <MenuItem value="abnormal">
                      <Checkbox checked={filterConfig.detection_status.indexOf("abnormal") > -1} />
                      <ListItemText primary={t("detectionStatus.Abnormal")} />
                    </MenuItem>
                    <MenuItem value="false alert">
                      <Checkbox checked={filterConfig.detection_status.indexOf("false alert") > -1} />
                      <ListItemText primary={t("detectionStatus.FalseAlert")} />
                    </MenuItem>
                    <MenuItem value="identified">
                      <Checkbox checked={filterConfig.detection_status.indexOf("identified") > -1} />
                      <ListItemText primary={t("detectionStatus.Identified")} />
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {/* Anomaly Type */}
              <Grid item xs={3}>
                {filterConfig.detection_status.includes("identified") && (
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="AT-label">{t("generateWorkOrder.content.amomalyType")}</InputLabel>
                    <Select
                      labelId="AT-label"
                      label={t("generateWorkOrder.content.amomalyType")}
                      multiple
                      value={filterConfig.anomaly_type ?? "N/A"}
                      renderValue={(selected) =>
                        // make the first char uppercase
                        selected.map((s) => translationMapping[s]).join("&")
                      }
                      onChange={(e) => {
                        if (e.target.value.length !== 0)
                          setFilterConfig((prev) => ({
                            ...prev,
                            anomaly_type: e.target.value as string[],
                          }));
                      }}
                    >
                      <MenuItem value="N/A">
                        <Checkbox checked={filterConfig.anomaly_type.indexOf("N/A") > -1} />
                        <ListItemText primary={"N/A"} />
                      </MenuItem>
                      <MenuItem value="leak">
                        <Checkbox checked={filterConfig.anomaly_type.indexOf("leak") > -1} />
                        <ListItemText primary={t("anomalyType.Leak", "Leak")} />
                      </MenuItem>
                      <MenuItem value="clog">
                        <Checkbox checked={filterConfig.anomaly_type.indexOf("clog") > -1} />
                        <ListItemText primary={t("anomalyType.Clog", "Clog")} />
                      </MenuItem>
                      <MenuItem value="pilferage">
                        <Checkbox checked={filterConfig.anomaly_type.indexOf("pilferage") > -1} />
                        <ListItemText primary={t("anomalyType.Pilferage", "Pilferage")} />
                      </MenuItem>
                    </Select>
                  </FormControl>
                )}
              </Grid>

              {/* Repair Status */}
              <Grid item xs={3}>
                {filterConfig.detection_status.includes("identified") && (
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="RS-label">{t("generateWorkOrder.content.repairStatus")}</InputLabel>
                    <Select
                      labelId="RS-label"
                      label={t("generateWorkOrder.content.repairStatus")}
                      multiple
                      value={filterConfig.repair_status}
                      renderValue={(selected) =>
                        // make the first char uppercase
                        selected.map((s) => translationMapping[s]).join("&")
                      }
                      onChange={(e) => {
                        if (e.target.value.length !== 0)
                          setFilterConfig((prev) => ({
                            ...prev,
                            repair_status: e.target.value as string[],
                          }));
                      }}
                    >
                      <MenuItem value="N/A">
                        <Checkbox checked={filterConfig.repair_status.indexOf("N/A") > -1} />
                        <ListItemText primary={"N/A"} />
                      </MenuItem>
                      <MenuItem value="not repaired">
                        <Checkbox checked={filterConfig.repair_status.indexOf("not repaired") > -1} />
                        <ListItemText primary={t("repairStatus.notRepaired", "not repaired")} />
                      </MenuItem>
                      <MenuItem value="repaired">
                        <Checkbox checked={filterConfig.repair_status.indexOf("repaired") > -1} />
                        <ListItemText primary={t("repairStatus.repaired", "repaired")} />
                      </MenuItem>
                    </Select>
                  </FormControl>
                )}
              </Grid>
              <Grid item xs={3}></Grid>

              <Grid item xs={3}>
                {/* Network Type */}
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="NT-label">{t("generateWorkOrder.content.networkType")}</InputLabel>
                  <Select
                    labelId="NT-label"
                    label={t("generateWorkOrder.content.networkType")}
                    multiple
                    renderValue={(selected) =>
                      // make the first char uppercase
                      selected.map((s) => translationMapping[s]).join("&")
                    }
                    value={filterConfig.netType}
                    onChange={(e) => {
                      console.log(e.target.value);

                      // const value = e.target.value as "abnormal" | "false alert" | "identified";
                      if (e.target.value.length !== 0)
                        setFilterConfig((prev) => ({
                          ...prev,
                          netType: e.target.value as ("pipe" | "node" | "house")[],
                        }));
                    }}
                  >
                    <MenuItem value="pipe">
                      <Checkbox checked={filterConfig.netType.indexOf("pipe") > -1} />
                      <ListItemText primary={t("networkType.pipe")} />
                    </MenuItem>
                    <MenuItem value="house">
                      <Checkbox checked={filterConfig.netType.indexOf("house") > -1} />
                      <ListItemText primary={t("networkType.house")} />
                    </MenuItem>
                    <MenuItem value="node">
                      <Checkbox checked={filterConfig.netType.indexOf("node") > -1} />
                      <ListItemText primary={t("networkType.node")} />
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={3}>
                {/* Length Range */}
                {filterConfig.netType.includes("pipe") && (
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="LR-label">{t("generateWorkOrder.content.lengthRange")}</InputLabel>
                    <Select
                      labelId="LR-label"
                      label={t("generateWorkOrder.content.lengthRange")}
                      renderValue={(selected) =>
                        // make the first char uppercase
                        selected.map((s) => translationMapping[s]).join("&")
                      }
                      value={filterConfig.lengthRange}
                      multiple
                      onChange={(e) => {
                        console.log(e.target.value);
                        setFilterConfig((prev) => ({
                         ...prev,
                         lengthRange: e.target.value as string[],
                        }));
                      }}
                    >

                      <MenuItem value={"0,Infinity"}>
                        <Checkbox checked={filterConfig.lengthRange.includes("0,Infinity")} />
                        <ListItemText primary={t("generateWorkOrder.content.all",'All')}/>
                      </MenuItem>
                      <MenuItem value={"0,1"}>
                        <Checkbox checked={filterConfig.lengthRange.includes("0,1")} />
                        <ListItemText primary="<1m"/>
                      </MenuItem>
                      <MenuItem value={"1,10"}>
                        <Checkbox checked={filterConfig.lengthRange.includes("1,10")} />
                        <ListItemText primary="1 - 10m"/>
                      </MenuItem>
                      <MenuItem value={"10,50"}>
                        <Checkbox checked={filterConfig.lengthRange.includes("10,50")} />
                        <ListItemText primary="10 -50m"/>
                      </MenuItem>
                      <MenuItem value={"50,Infinity"}>
                        <Checkbox checked={filterConfig.lengthRange.includes("50,Infinity")} />
                        <ListItemText primary=">50m"/>
                      </MenuItem>

                    </Select>
                  </FormControl>
                )}
              </Grid>

              {/* Dia Range */}
              <Grid item xs={3}>
                {filterConfig.netType.includes("pipe") && (
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="DR-label">{t("generateWorkOrder.content.diameterRange")}</InputLabel>
                    <Select
                      labelId="DR-label"
                      label={t("generateWorkOrder.content.diameterRange")}
                      multiple
                      renderValue={(selected) =>
                        // make the first char uppercase
                        selected.map((s) => translationMapping[s]).join("&")
                      }
                      value={filterConfig.diameterRange}
                      onChange={(e) => {
                        console.log(e.target.value);
                        setFilterConfig((prev) => ({
                         ...prev,
                         diameterRange: e.target.value as string[],
                        }));
                      }}
                    >
                      <MenuItem value={"0,Infinity"}>
                        <Checkbox checked={filterConfig.diameterRange.includes("0,Infinity")} />
                        <ListItemText primary={t("generateWorkOrder.content.all",'All')}/>
                      </MenuItem>
                      <MenuItem value={"0,20"}>
                        <Checkbox checked={filterConfig.diameterRange.includes("0,20")} />
                        <ListItemText primary="<20mm"/>
                      </MenuItem>
                      <MenuItem value={"20,50"}>
                        <Checkbox checked={filterConfig.diameterRange.includes("20,50")} />
                        <ListItemText primary="20 - 50mm"/>
                      </MenuItem>
                      <MenuItem value={"50,200"}>
                        <Checkbox checked={filterConfig.diameterRange.includes("50,200")} />
                        <ListItemText primary="50 -200mm"/>
                      </MenuItem>
                      <MenuItem value={"200,Infinity"}>
                        <Checkbox checked={filterConfig.diameterRange.includes("200,Infinity")} />
                        <ListItemText primary=">200mm"/>
                      </MenuItem>
                    </Select>
                  </FormControl>
                )}
              </Grid>

              {/* Material */}
              <Grid item xs={3}>
                {filterConfig.netType.includes("pipe") && (
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="material-label">{t("generateWorkOrder.content.material")}</InputLabel>
                    <Select
                      labelId="material-label"
                      label={t("generateWorkOrder.content.material")}
                      value={filterConfig.material}
                      multiple
                      renderValue={(selected) =>
                        // make the first char uppercase
                        selected.map((s) => uppercase(s)).join("/")
                      }
                      onChange={(e) => {
                        console.log(e.target.value);
                        if (e.target.value.length !== 0)
                          setFilterConfig((prev) => ({
                            ...prev,
                            material: e.target.value as string[],
                          }));
                      }}
                    >
                      <MenuItem value="CI">
                        <Checkbox checked={filterConfig.material.indexOf("CI") > -1} />
                        <ListItemText primary={"Cast Iron(CI)"} />
                      </MenuItem>
                      <MenuItem value="DI">
                        <Checkbox checked={filterConfig.material.indexOf("DI") > -1} />
                        <ListItemText primary={"Ductile Iron(DI)"} />
                      </MenuItem>
                    </Select>
                  </FormControl>
                )}
              </Grid>
            </Grid>
          </Box>

          <Grid container spacing={4} marginTop={10} marginBottom={5}>
            <Grid item xs={4}></Grid>
            <Grid item xs={2}>
              <Button variant="contained" sx={{ textTransform: "none" }} onClick={handleReset} fullWidth>
                {t("common.reset")}
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                variant="contained"
                sx={{ textTransform: "none" }}
                onClick={() => {
                  console.log(filterConfig.netType);
                  setWorkOrders([]);
                  filterConfig.netType.includes("pipe") && handleFilterPipe();
                  (filterConfig.netType.includes("node") || filterConfig.netType.includes("house")) && handleFilterNode();
                  setOpen(true);
                }}
                fullWidth
              >
                {t("common.confirmSelection")}
              </Button>
            </Grid>
            <Grid item xs={4}></Grid>
          </Grid>
        </div>
      </PageWrapper>

      <Dialog
        open={open}
        onClose={() => {
          setWorkOrders([]);
          setSelectedWorkOrders([]);
          setOpen(false);
        }}
        maxWidth="xl"
      >
        <DialogTitle>{t("generateWorkOrder.dialog.title")}</DialogTitle>

        <DialogContent>
          {/* <FormControlLabel
            control={
              <Checkbox
                checked={workOrders.length === selectedWorkOrders.length}
                onChange={(_, checked) => {
                  console.log(checked);
                  if (checked) setSelectedWorkOrders(workOrders);
                  else setSelectedWorkOrders([]);
                }}
              />
            }
            label="Select All"
          />

          <Divider></Divider>
          {workOrders.map((wo, idx) => (
            <Box key={idx}>
              <WorkOrderCheckbox
                key={idx}
                workOrder={wo}
                isSelected={woIncludes(selectedWorkOrders, wo)}
                onToggleSelect={() => onToggleSelect(wo)}
              />
              <Divider></Divider>
            </Box>
          ))} */}
          <WorkOrderDataGrid
            workOrders={workOrders}
            setSelectedWorkOrders={setSelectedWorkOrders}
            translationMapping={translationMapping}
          />
        </DialogContent>

        {/* PDF Dialog */}
        <DialogActions>
          <Button
            size="large"
            onClick={() => {
              setSelectedWorkOrders([]);
              setOpen(false);
            }}
            color="primary"
          >
            {t("common.cancel")}
          </Button>
          <Button size="large" onClick={() => setOpenPDF(true)} color="primary" disabled={selectedWorkOrders.length === 0}>
            {t("generateWorkOrder.dialog.preview")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openPDF}
        onClose={() => {
          setOpenPDF(false);
        }}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>{t("generateWorkOrder.dialog.PDFPreview")}</DialogTitle>

        <DialogContent>
          <PDFViewer width="100%" height="500">
            <WorkOrderPDF workOrders={selectedWorkOrders} />
          </PDFViewer>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => {
              setOpenPDF(false);
            }}
            color="primary"
          >
            {t("common.cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default WorkOrderGenerationPage;

const WorkOrderDataGrid: React.FC<{
  translationMapping: { [key: string]: string };
  workOrders: WorkOrderType[];
  setSelectedWorkOrders: React.Dispatch<React.SetStateAction<WorkOrderType[]>>;
}> = ({ workOrders, setSelectedWorkOrders, translationMapping }) => {
  const { t } = useTranslation();
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      width: 50,
      type: "number",
      align: "center",
      headerAlign: "center",
    },
    {
      field: "location",
      headerName: t("findLeaksandAnomalies.content.location"),
      width: 170,
      align: "center",
      headerAlign: "center",
      sortable: true,
      renderCell: (params) => (
        <Typography whiteSpace={"normal"} sx={{ wordWrap: "break-word", textAlign: "center" }}>
          {translate(params.value, t)}
        </Typography>
      ),
    },
    {
      field: "detection_time",
      headerName: t("findLeaksandAnomalies.content.detectionTime"),
      width: 150,
      type: "string",
      sortable: true,
      align: "center",
      headerAlign: "center",
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        return new Date(params.value as number).toLocaleString();
      },
      renderCell: (params) => (
        <Typography whiteSpace={"normal"} sx={{ wordWrap: "break-word", textAlign: "center" }} variant="body1">
          {params.value}
        </Typography>
      ),
    },

    {
      field: "type",
      headerName: t("findLeaksandAnomalies.content.systemAnomalyType"),
      description: "",
      type: "singleSelect",
      sortable: true,
      width: 170,
      align: "center",
      headerAlign: "center",
      valueFormatter: (params: GridValueFormatterParams<string>) => {
        return translate(params.value, t);
      },
    },
    {
      field: "estimate_accuracy",
      headerName: t("findLeaksandAnomalies.content.estAccuracy"),
      width: 120,
      type: "number",
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (params.value == null) {
          return "";
        }
        return `${(params.value * 100).toLocaleString()} %`;
      },
      sortable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "detection_status",
      headerName: t("findLeaksandAnomalies.content.detectionStatus"),
      description: "",
      sortable: false,
      type: "singleSelect",
      valueOptions: [t("detectionStatus.abnormal"), t("detectionStatus.identified"), t("detectionStatus.falseAlert")],
      editable: true,
      width: 150,
      align: "center",
      headerAlign: "center",
      valueFormatter: (params: GridValueFormatterParams<string>) => {
        return translate(params.value, t);
      },
    },
    {
      field: "anomaly_type",
      headerName: t("findLeaksandAnomalies.content.anomalyType"),
      description: "",
      sortable: false,
      type: "string",
      width: 130,
      align: "center",
      headerAlign: "center",
      valueFormatter: (params: GridValueFormatterParams<string>) => {
        if (params.value === "N/A") {
          return "--";
        }
        return translate(params.value, t);
      },
    },
    {
      field: "repair_status",
      headerName: t("findLeaksandAnomalies.content.repairedStatus"),
      description: "",
      sortable: false,
      type: "string",
      // editable: true,
      width: 150,
      align: "center",
      headerAlign: "center",
      valueFormatter: (params: GridValueFormatterParams<string>) => {
        if (params.value === "N/A") {
          return "--";
        }
        return translate(params.value, t);
      },
    },

    {
      field: "LENGTH_m",
      headerName: t("property.length"),
      width: 100,
      align: "center",
      headerAlign: "center",
      sortable: true,
    },
    {
      field: "D_mm",
      headerName: t("property.diameter"),
      width: 100,
      align: "center",
      headerAlign: "center",
      sortable: true,
    },
    {
      field: "MATERIAL",
      headerName: t("property.material"),
      width: 100,
      align: "center",
      headerAlign: "center",
      sortable: true,
    },

    {
      field: "START_NODE",
      headerName: t("property.startNode"),
      width: 100,
      align: "center",
      headerAlign: "center",
      sortable: true,
    },
    // GPS Data for junctions
    {
      field: "STOP_NODE",
      headerName: t("property.stopNode"),
      width: 100,
      align: "center",
      headerAlign: "center",
      sortable: true,
    },
    // Start and end node for pipes


    {
      field: "GPS_LATITUDE",
      headerName: t("property.GPSLatitude"),
      width: 100,
      align: "center",
      headerAlign: "center",
      sortable: true,
    },

    {
      field: "GPS_LONGITUDE",
      headerName: t("property.GPSLongitude"),
      width: 100,
      align: "center",
      headerAlign: "center",
      sortable: true,
    },

  ];

  return (
    <Box sx={{ height: 550, width: "100%" }}>
      <DataGrid
        rows={workOrders ?? []}
        columns={columns}
        sx={{
          typography: smallTypography.body1,
          border: "none",
          ".MuiDataGrid-root": {
            border: "none",
          },
          ".MuiDataGrid-columnHeaders": {
            background: "#EFF5FA",
            borderRadius: "8px 8px 0 0",
            border: "none",
          },
          "& .MuiDataGrid-columnName": {
            // This targets both header and cells of the "name" column
            backgroundColor: "lightblue",
          },
          "& .MuiDataGrid-cell": {
            padding: "0",
          },
        }}
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
              START_NODE: false,
              STOP_NODE: false,
              GPS_LATITUDE: false,
              GPS_LONGITUDE: false,
            },
          },
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        slots={{
          toolbar: GridToolbar,
        }}
        pageSizeOptions={[10, 20, 100]}
        checkboxSelection
        disableRowSelectionOnClick
        onRowSelectionModelChange={(rowSelectionModel, details) => {
          setSelectedWorkOrders(rowSelectionModel.map((id) => workOrders.find((row) => row.id === id)) as WorkOrderType[]);
        }}
        localeText={{
          MuiTablePagination: {
            labelRowsPerPage: t("findLeaksandAnomalies.content.rowsPerPage"),
          },
          toolbarColumns: t("findLeaksandAnomalies.content.toolbarColumns"),
          columnsPanelHideAllButton:t("findLeaksandAnomalies.content.columnsPanelHideAllButton"),
          columnsPanelShowAllButton:t("findLeaksandAnomalies.content.columnsPanelShowAllButton"),
          checkboxSelectionHeaderName:t("findLeaksandAnomalies.content.checkboxSelectionHeaderName"),
          columnsPanelTextFieldLabel:t("findLeaksandAnomalies.content.columnsPanelTextFieldLabel"),
          columnsPanelTextFieldPlaceholder:t("findLeaksandAnomalies.content.columnsPanelTextFieldPlaceholder"),

          // filter
          toolbarFilters: t("findLeaksandAnomalies.content.toolbarFilters"),
          filterPanelOperator:  t("findLeaksandAnomalies.content.filterPanelOperator"),
          filterPanelColumns: t("findLeaksandAnomalies.content.filterPanelColumns"),
          filterPanelInputLabel: t("findLeaksandAnomalies.content.filterPanelInputLabel"),
          filterOperatorIsEmpty:t("findLeaksandAnomalies.content.filterOperatorIsEmpty"),
          filterOperatorIsNotEmpty:t("findLeaksandAnomalies.content.filterOperatorIsNotEmpty"),
          filterOperatorIsAnyOf:t("findLeaksandAnomalies.content.filterOperatorIsAnyOf"),
          filterPanelInputPlaceholder:t("findLeaksandAnomalies.content.filterPanelInputPlaceholder"),
        

          // export
          toolbarExport: t("findLeaksandAnomalies.content.toolbarExport"),
          toolbarExportCSV: t("findLeaksandAnomalies.content.toolbarExportCSV"),
          toolbarExportPrint: t("findLeaksandAnomalies.content.toolbarExportPrint"),

          // density
          toolbarDensity: t("findLeaksandAnomalies.content.toolbarDensity"),
          toolbarDensityComfortable: t("findLeaksandAnomalies.content.toolbarDensityComfortable"),
          toolbarDensityCompact: t("findLeaksandAnomalies.content.toolbarDensityCompact"),
          toolbarDensityStandard: t("findLeaksandAnomalies.content.toolbarDensityStandard"),

          // toolbarColumnsLabel: "Select Columns"
          
        }}
      />
    </Box>
  );
};
