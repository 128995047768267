import React, { useEffect, useRef, useState } from "react";
import { NetworkData, NodeRecord, Pipe, PipeRecord } from "../../pages/PipelineMapPage";
import "ol/ol.css";
import { Feature, Map, MapBrowserEvent, MapEvent, Overlay, View } from "ol";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import { fromLonLat } from "ol/proj";
import LineString from "ol/geom/LineString";
import Point from "ol/geom/Point";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import Style from "ol/style/Style";
import Circle from "ol/style/Circle";

import Fill from "ol/style/Fill";
import Stroke from "ol/style/Stroke";
import { Popover, PopoverPosition, Typography } from "@mui/material";
import { FeatureLike } from "ol/Feature";
import { JunctionMap, PipeMap } from "../../pages/PipelineConfigPage";
import FeatureInfoCard, { ShowFeature } from "./FeatureInfoCard";

interface PipelineMapProps {
  setFeatureInfo: React.Dispatch<
    React.SetStateAction<{
      [key: string]: string | number;
    } | null>
  >;
  networkData: NetworkData;
  initPos: number[];
}
function removeLayers(map: Map | null) {
  map
    ?.getLayers()
    .getArray()
    .filter((layer) => layer.get("name") === "pipes")
    .forEach((layer) => map.removeLayer(layer));

  map
    ?.getLayers()
    .getArray()
    .filter((layer) => layer.get("name") === "junctions")
    .forEach((layer) => map.removeLayer(layer));

  map
    ?.getLayers()
    .getArray()
    .filter((layer) => layer.get("name") === "junction_elevation")
    .forEach((layer) => map.removeLayer(layer));
}
function load_map(map: Map | null, pipes: PipeRecord, nodes: NodeRecord) {
  console.log("load_map");
  if (map == null) return;

  // load pipes
  for (const [label, info] of Object.entries<Pipe>(pipes)) {
    const points = [
      fromLonLat([nodes[info.START_NODE].GPS_LONGITUDE, nodes[info.START_NODE].GPS_LATITUDE]),
      fromLonLat([nodes[info.STOP_NODE].GPS_LONGITUDE, nodes[info.STOP_NODE].GPS_LATITUDE]),
    ];

    info["Label"] = label;
    const featureLine = new Feature({
      geometry: new LineString(points),
      attributes: info,
    });

    const vectorLine = new VectorSource({});
    vectorLine.addFeature(featureLine);

    // console.log(info)
    const vectorLineLayer = new VectorLayer({
      source: vectorLine,
      style: new Style({
        fill: new Fill({ color: info["color"] }),
        stroke: new Stroke({
          color: info["color"],
          // width: isNaN((info["D"]! / 100) * 3) ? 6 : (info["D"]! / 100) * 0.5,
          width: info["dia"] * 50,
        }),
      }),
    });
    vectorLineLayer.set("name", "pipes");
    map?.addLayer(vectorLineLayer);
  }

  // load junctions, 2layers, junctions & elevs(invisible)
  for (const [label, info] of Object.entries(nodes)) {
    const features = [];

    info["LABEL"] = label;

    var feature = new Feature({
      geometry: new Point(fromLonLat([info["GPS_LONGITUDE"], info["GPS_LATITUDE"]])),
      attributes: info,
    });

    features.push(feature);

    // create the source and layer for random features
    const vectorSource = new VectorSource({});
    vectorSource.addFeature(feature);

    // console.log(info['color'])
    const vectorLayer = new VectorLayer({
      source: vectorSource,
      style: new Style({
        image: new Circle({
          radius: 8,
          fill: new Fill({ color: info["color"] }),
        }),
      }),
    });
    vectorLayer.set("name", "junctions");

    map?.addLayer(vectorLayer);

    const elevLayer = new VectorLayer({
      source: vectorSource,
      style: new Style({
        image: new Circle({
          radius: 10,
          fill: new Fill({ color: info["elev_color"] }),
        }),
      }),
    });
    elevLayer.set("name", "junction_elevation");

    map?.addLayer(elevLayer);

    elevLayer.setVisible(false);
  }
}

const PipelineMap: React.FC<PipelineMapProps> = ({ networkData, setFeatureInfo, initPos }) => {
  const pipes: PipeRecord = networkData.temp_pipes;
  const nodes: NodeRecord = networkData.temp_nodes;

  const mapRef = useRef<HTMLDivElement | null>(null);

  const [viewPos, setViewPos] = useState<number[]>(initPos);
  const map = useRef<Map>(
    new Map({
      target: mapRef.current ?? undefined, // target div element
      layers: [new TileLayer({ source: new OSM() })],
      view: new View({ center: fromLonLat(viewPos), zoom: 17 }),
    })
  );
  const [selectedFeature, setSelectedFeature] = useState<FeatureLike | null>(null);
  const [anchorPos, setAnchorPos] = useState<PopoverPosition | null>(null);
  const [popoverContent, setPopoverContent] = useState<string>("");

  const id = !!anchorPos ? "simple-popover" : undefined;

  const addMapClickListener = () =>
    map.current.on("click", (evt: MapBrowserEvent<UIEvent>) => {
      console.log("click map");
      const pixel = evt.pixel;
      const feature = map.current.forEachFeatureAtPixel(pixel, function (feature, layer) {
        return feature;
      });

      if (!feature) {
        setAnchorPos(null);
        return;
      }

      // control popup
      const atts = feature.getProperties().attributes;
      const clickCoords = map.current.getPixelFromCoordinate(evt.coordinate);
      const rect = mapRef.current?.getBoundingClientRect();
      setPopoverContent(atts.LABEL ?? atts.Label);
      setAnchorPos({ left: clickCoords[0] + (rect?.left ?? 0), top: clickCoords[1] + (rect?.top ?? 0) - 10 });
      setSelectedFeature(feature);
      setFeatureInfo(atts);
    });

  const addMapMoveListener = () =>
    map.current.on("movestart", (evt: MapEvent) => {
      console.log("move start");
      setAnchorPos(null);
    });

  // if new data comes, reconstruct layers to the map
  useEffect(() => {
    console.log("useEffect-[mapRef, networkData]");
    console.log("mapRef.current:", mapRef.current);
    console.log("networkData:", networkData);
    if (!mapRef.current) return; // check if div element is ready

    // if map doesn't have a target, add target to the ref
    if (!map.current.getTarget()) {
      map.current.setTarget(mapRef.current);
    } else {
      // remove previous layers
      removeLayers(map.current);
    }
    load_map(map.current, pipes, nodes);

    // add listeners
    addMapClickListener();
    addMapMoveListener();

    setViewPos(initPos);

    console.log("useEffect-[mapRef, networkData]-end");
    console.log(" ");

    return () => {
      map.current.un("click", addMapClickListener);
      map.current.un("movestart", addMapMoveListener);
    };
  }, [mapRef, networkData]);

  useEffect(() => {
    console.log("useEffect-[viewPos]");

    const view = map.current.getView();
    view.setCenter(fromLonLat(viewPos));

    console.log("useEffect-[viewPos]-end");
    console.log(" ");
  }, [viewPos]);

  return (
    <div>
      <div ref={mapRef} style={{ width: "100%", height: "100vh", minHeight: "100vh", overflow: "clip" }}>
      <Popover
        id={id}
        open={!!anchorPos}
        anchorReference="anchorPosition"
        anchorPosition={anchorPos ?? undefined}
        onClose={() => setAnchorPos(null)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        // sx={{ pointerEvents: "none", padding: "1rem" }}
      >
        {selectedFeature && <FeatureInfoCard feature={selectedFeature} />}
        {/* <Typography sx={{ p: 2 }}>{popoverContent}</Typography>
          <Typography sx={{ p: 2 }}>{String(selectedFeature?.getProperties().attributes.LABEL)}</Typography> */}
      </Popover>

        {/* <ColorRangePicker /> */}
      </div>
    </div>
  );
};

export default React.memo(PipelineMap);

export const EmptyMap = () => {
  console.log("loading empty");
  const mapRef = useRef<HTMLDivElement | null>(null);
  const map = useRef<Map>(
    new Map({
      target: mapRef.current ?? undefined, // target div element
      layers: [new TileLayer({ source: new OSM() })],
      view: new View({ center: fromLonLat([0, 0]), zoom: 2 }),
    })
  );
  return <div ref={mapRef} style={{ width: "100%", height: "100vh" }} />;
};
interface MapPreviewProps {
  pipeMap?: PipeMap;
  junctionMap: JunctionMap;
  initPos: number[];
  mapRef: React.MutableRefObject<HTMLDivElement | null>;
}

function load_map_preview(map: Map, nodes: JunctionMap, pipes: PipeMap | undefined) {
  console.log("loading_map");
  // console.log(nodes);
  // console.log(pipes);
  if (map == null) return;

  try {
    if (pipes && Object.keys(pipes).length) {
      for (const label in pipes) {
        const points = [
          fromLonLat([nodes[pipes[label].START_NODE].GPS_LONGITUDE, nodes[pipes[label].START_NODE].GPS_LATITUDE]),
          fromLonLat([nodes[pipes[label].STOP_NODE].GPS_LONGITUDE, nodes[pipes[label].STOP_NODE].GPS_LATITUDE]),
        ];

        const featureLine = new Feature({
          geometry: new LineString(points),
          attributes: pipes[label],
        });

        const vectorLine = new VectorSource({});
        vectorLine.addFeature(featureLine);

        // console.log(info)
        const vectorLineLayer = new VectorLayer({
          source: vectorLine,
          style: new Style({
            fill: new Fill({ color: "blue" }),
            stroke: new Stroke({
              color: "blue",
              width: 4,
            }),
          }),
        });
        vectorLineLayer.set("name", "pipes");
        map.addLayer(vectorLineLayer);
      }
    }
    // load junctions, 2layers, junctions & elevs(invisible)
    for (const label in nodes) {
      const features = [];

      var feature = new Feature({
        geometry: new Point(fromLonLat([nodes[label]["GPS_LONGITUDE"], nodes[label]["GPS_LATITUDE"]])),
        attributes: nodes[label],
      });

      features.push(feature);

      // create the source and layer for random features
      const vectorSource = new VectorSource({});
      vectorSource.addFeature(feature);

      // console.log(info['color'])
      const vectorLayer = new VectorLayer({
        source: vectorSource,
        style: new Style({
          image: new Circle({
            radius: 8,
            fill: new Fill({ color: "#13ad0d" }),
          }),
        }),
      });
      vectorLayer.set("name", "junctions");
      map.addLayer(vectorLayer);
    }
  } catch (e) {
    alert(e);
  }
}

// LABEL: string;
// START_NODE: string;
// STOP_NODE: string;
// LENGTH_m: number;
// D_mm: number;
// MATERIAL: string;
// ROUGHNESS: number;
// VALVE?: boolean;

export const MapPreview: React.FC<MapPreviewProps> = ({ junctionMap, pipeMap, initPos, mapRef }) => {
  // const mapRef = useRef<HTMLDivElement | null>(null);

  const [viewPos, setViewPos] = useState<number[]>(initPos);
  const map = useRef<Map>(
    new Map({
      target: mapRef.current ?? undefined, // target div element
      layers: [new TileLayer({ source: new OSM() })],
      view: new View({ center: fromLonLat(viewPos), zoom: 15 }),
    })
  );
  const [selectedFeature, setSelectedFeature] = useState<FeatureLike | null>(null);
  const [anchorPos, setAnchorPos] = useState<PopoverPosition | null>(null);
  const id = !!anchorPos ? "metadata-popover" : undefined;

  const addMapClickListener = () =>
    map.current.on("click", (evt: MapBrowserEvent<UIEvent>) => {
      console.log("click map");
      const pixel = evt.pixel;
      const feature = map.current.forEachFeatureAtPixel(pixel, function (feature, layer) {
        return feature;
      });

      if (!feature) {
        setAnchorPos(null);
        return;
      }

      // control popup
      const atts = feature.getProperties().attributes;
      const clickCoords = map.current.getPixelFromCoordinate(evt.coordinate);
      const rect = mapRef.current?.getBoundingClientRect();
      setAnchorPos({ left: clickCoords[0] + (rect?.left ?? 0), top: clickCoords[1] + (rect?.top ?? 0) - 10 });
      setSelectedFeature(feature);
    });

  const addMapMoveListener = () =>
    map.current.on("movestart", (evt: MapEvent) => {
      console.log("move start");
      setAnchorPos(null);
    });

  useEffect(() => {
    console.log("map effect:", junctionMap);
    if (!mapRef.current) return; // check if div element is ready
    if (!map.current.getTarget()) {
      map.current.setTarget(mapRef.current);
    } else {
      removeLayers(map.current);
    }
    load_map_preview(map.current, junctionMap, pipeMap);

    // add listeners
    addMapClickListener();
    addMapMoveListener();

    setViewPos(initPos);
    return () => {
      map.current.un("click", addMapClickListener);
      map.current.un("movestart", addMapMoveListener);
    };
  }, [mapRef, junctionMap]);

  useEffect(() => {
    const view = map.current.getView();
    view.setCenter(fromLonLat(viewPos));
  }, [viewPos]);

  return (
    <div ref={mapRef} style={{ borderRadius: "8px", width: "100%", height: "60vh", minHeight: "60vh", overflow: "clip" }}>
      <Popover
        id={id}
        open={!!anchorPos}
        anchorReference="anchorPosition"
        anchorPosition={anchorPos ?? undefined}
        onClose={() => setAnchorPos(null)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        sx={{ pointerEvents: "none", padding: "1rem" }}
      >
        {selectedFeature && <ShowFeature feature={selectedFeature} />}
        {/* <Typography sx={{ p: 2 }}>{popoverContent}</Typography>
          <Typography sx={{ p: 2 }}>{String(selectedFeature?.getProperties().attributes.LABEL)}</Typography> */}
      </Popover>
    </div>
  );
};
