import React, { useCallback } from "react";
import { FileRejection, useDropzone } from "react-dropzone";

import Navbar, { PageWrapper } from "../components/utils/Navbar";
import PageHeader from "../components/utils/PageHeader";
import { Box, Button } from "@mui/material";
import ReactPDF, { Page, Text, View, Document, StyleSheet, PDFViewer } from "@react-pdf/renderer";
import ReactDOM from "react-dom";

const styles = StyleSheet.create({
  page2:{

  },
  page: {
    flexDirection: "column",
    backgroundColor: "#E4E4E4",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});
// Create Document Component
const MyDocument = () => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text>Section #1</Text>
      </View>
      <View style={styles.section}>
        <Text>Section #2</Text>
      </View>
    </Page>
  </Document>
);

const Test = () => {
  return (
    <div style={{ display: "flex" }}>
      <Navbar />
      <PageWrapper>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <PageHeader
            title="Chart Report Page"
            description="Chart Report PageChart Report PageChart Report PageChart Report PageChart Report PageChart Report PageChart Report PageChart Report Page"
          />
          <div>Get Started</div>

          <PDFViewer>
            <MyDocument />
          </PDFViewer>
          
        </div>
      </PageWrapper>
    </div>
  );
};
export default Test;
