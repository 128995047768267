import React, { useEffect, useState } from "react";
import AnomalyReportDataGrid, { TableData, convertToAnomalyRecords } from "../components/anomaly_report/AnomalyReportDataGrid";
import { API_URL } from "../constants";
import Fade from "@mui/material/Fade";
import axios from "axios";
import {
  Alert,
  AlertTitle,
  Box,
  CircularProgress,
  Button,
  MenuItem,
  Select,
  Snackbar,
  Typography,
  useTheme,
  Divider,
} from "@mui/material";
import Navbar, { PageWrapper } from "../components/utils/Navbar";
import PageHeader from "../components/utils/PageHeader";
import dayjs from "dayjs";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import MagnifyScanIcon from "../icons/mdi_magnify-scan.svg";
import FalseAlertIcon from "../icons/FalseAlertIcon.svg";
import { useNetworkId } from "../hooks/useNetworkId";
import { CheckCircle } from "@mui/icons-material";
import { Link } from "react-router-dom";
import InfoTooltip from "../components/utils/InfoIcon";
import { useTranslation } from "react-i18next";

export const MetricsCard: React.FC<{ description: string; icon: JSX.Element; n: number; color: string; width: string }> = ({
  description,
  icon,
  n,
  color,
  width,
}) => {
  return (
    <Box
      borderRadius="8px"
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"space-between"}
      width={width}
      padding={"16px 0"}
      gap={"16px"}
      sx={{ background: color }}
      boxSizing={"content-box"}
      margin="0 0px 24px"
      minWidth={"25%"}
    >
      <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
        <Typography component={"span"} variant="h5" color="#000A14">
          {description}
        </Typography>
        <span>{icon}</span>
      </div>
      <Typography fontFamily={"Inter"} fontSize={"48px"} fontStyle={"normal"} fontWeight={"600px"} lineHeight={"48px"}>
        {n}
      </Typography>
    </Box>
  );
};

const info = () => {
  return (
    <div
      style={{
        width: "300px",
        border: "1px solid #ccc",
      }}
    >
      <p>
        The table contains the potential anomalies of junctions and pipes from the user's provided data. User can confirm or
        object the detection to help the A.I. improve accuracy.
      </p>
    </div>
  );
};

// const info = `<div style="width: 300px;font-size:14px; font-weight: normal; border: 1px solid #ccc; padding: 5px;"><p>The table contains the potential anomalies of junctions and pipes from the user's provided data.
// <br>User can confirm or object the detection to help the A.I. improve accuracy.</p></div>`;

const ReportPage: React.FC = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [anomalyRecord, setAnomalyRecord] = useState<TableData | null>(null);
  const [loadingTable, setLoadingTable] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [accuracy, setAccuracy] = useState<number>(0.7);
  const [loading, setLoading] = useState<boolean>(false);
  const [finished, setFinished] = useState<boolean>(false);

  const getBackgroundColor = (accuracy: number) => {
    switch (accuracy) {
      case 0.5:
        return "#70cff4";

      case 0.6:
        return "#5dabff";
      case 0.7:
        return theme.palette.info.dark;
      case 0.8:
        return theme.palette.warning.main;
      case 0.9:
        return theme.palette.error.main;
      default:
        return theme.palette.info.dark;
    }
  };
  const { fetchNetworkId, setNetworkId, networkId: network_id } = useNetworkId();

  useEffect(() => {
    fetchNetworkId().then((nid) => {
      setNetworkId(nid);
    });
  }, []);

  useEffect(() => {
    console.log("anomalyRecord");
    console.log(anomalyRecord);
  });
  useEffect(() => {
    setLoadingTable(true);
    console.log("fetching table data");

    axios
      .get<TableData>(API_URL + "/abnormals")
      .then((response) => {
        console.log("abnormals data");
        console.log(response.data);
        setAnomalyRecord(response.data ?? null);
      })
      .catch((e) => {
        console.log("Failed to get Anomaly");
      })
      .finally(() => setLoadingTable(false));
  }, [refresh]);

  const handleRunSimulation = async () => {
    setLoading(true);
    await axios
      .post(`${API_URL}/network/simulation/${network_id}`)
      .then((response) => {
        console.log("sim successful", response.data);
        setFinished(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const info = `<div style="width: 300px;font-size:14px; font-weight: normal; padding: 5px;"><p>${t(
    "info.anomalyReport",
    "The table contains the potential anomalies of junctions and pipes from the user's provided data"
  )}.
  <br>${t("info.anomalyReport2", "User can confirm or object the detection to help the A.I. improve accuracy")}.</p></div>`;
  const recordsAboveAccuracy = convertToAnomalyRecords(anomalyRecord, accuracy);
  return (
    <div style={{ display: "flex" }}>
      <Navbar />
      <PageWrapper>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <PageHeader title={t("findLeaksandAnomalies.title")} description={t("findLeaksandAnomalies.description")} />

          <div style={{ marginTop: "24px", display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }}>
            <Button
              sx={{ borderRadius: "4px", padding: "0.5rem", textTransform: "none", margin: "1rem 0 2rem", width: "25rem" }}
              variant="contained"
              fullWidth
              onClick={handleRunSimulation}
            >
              {t("findLeaksandAnomalies.content.findButton")}
            </Button>
            <Snackbar
              open={loading}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              TransitionComponent={Fade}
              sx={{ boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.2)", borderRadius: "8px" }}
            >
              <Alert
                iconMapping={{
                  info: <CircularProgress variant="indeterminate" style={{ width: "24px", height: "24px" }} />,
                }}
                severity="info"
                sx={{ width: "100%" }}
              >
                {t("findLeaksandAnomalies.content.checkAlert")}
              </Alert>
            </Snackbar>
            <Divider style={{ width: "100%" }}></Divider>
            <Box marginTop="24px">
              <Typography variant="h1">
                {t("findLeaksandAnomalies.content.title")} <InfoTooltip info={info}></InfoTooltip>
              </Typography>
            </Box>
            <Box marginTop="8px">
              <Typography variant="body2">{dayjs().format("MM/DD/YYYY").toString()}</Typography>
            </Box>
            <Box margin="24px 0 32px">
              <Select
                value={accuracy}
                onChange={(e) => {
                  setAccuracy(Number(e.target.value));
                }}
                renderValue={(value) => `${t("findLeaksandAnomalies.content.accuracyLevel")} ${value * 100}%+`}
                sx={{
                  color: "white",
                  border: "none",
                  background: getBackgroundColor(accuracy),
                  borderRadius: "5px",
                  padding: "5px 10px",
                  ".MuiSelect-select": {
                    height: "fit-content",
                    padding: "4px 12px",
                  },
                  ".MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  ".MuiSvgIcon-root": {
                    color: "white",
                  },
                  textAlign: "center",
                }}
              >
                <MenuItem value={0.5}>50%+</MenuItem>
                <MenuItem value={0.6}>60%+</MenuItem>
                <MenuItem value={0.7}>70%+</MenuItem>
                <MenuItem value={0.8}>80%+</MenuItem>
                <MenuItem value={0.9}>90%+</MenuItem>
              </Select>
            </Box>
            <Box width={"70%"} display={"flex"} flexDirection={"column"} justifyContent={"space-between"} alignItems={"center"}>
              <MetricsCard
                description={t("findLeaksandAnomalies.content.totalAnomalies")}
                icon={<img src={MagnifyScanIcon} alt="Pipe Leak Icon" />}
                n={recordsAboveAccuracy?.length ?? 0}
                color="#EFF5FA"
                width="100%"
              />
              <Box display={"flex"} justifyContent="space-between" width={"100%"}>
                <MetricsCard
                  description={t("detectionStatus.Identified")}
                  icon={<CheckCircleOutlineIcon sx={{ color: "#4CAF50", fontSize: "1.8rem" }} />}
                  // n={anomalyRecord?.detection_status.filter((f) => f === "identified").length ?? 0}
                  n={recordsAboveAccuracy?.filter((r) => r.detection_status === "identified").length ?? 0}
                  color="#F0F8F1"
                  width="30%"
                />
                <MetricsCard
                  description={t("detectionStatus.Abnormal")}
                  icon={<WarningAmberIcon sx={{ color: "#FF9800", fontSize: "1.8rem" }} />}
                  // n={anomalyRecord?.detection_status.filter((f) => f === "abnormal").length ?? 0}
                  n={recordsAboveAccuracy?.filter((r) => r.detection_status === "abnormal").length ?? 0}
                  color="#FFF4EA"
                  width="30%"
                />
                <MetricsCard
                  description={t("detectionStatus.FalseAlert")}
                  // icon={<ReportOffIcon sx={{ color: "#6D4C41", fontSize: "1.5rem" }} />}
                  icon={<img src={FalseAlertIcon} alt="Pipe Leak Icon" />}
                  // n={anomalyRecord?.detection_status.filter((f) => f === "false alert").length ?? 0}
                  n={recordsAboveAccuracy?.filter((r) => r.detection_status === "false alert").length ?? 0}
                  color="#F8F1EE"
                  width="30%"
                />
              </Box>
            </Box>
            {/* {anomalyRecord && (
              <AnomalyReportDataGrid tableData={anomalyRecord} onConfirm={() => setRefresh(!refresh)} acc_thred={accuracy} />
            )} */}
            <Snackbar
              open={finished}
              // autoHideDuration={1000}
              onClose={() => setFinished(false)}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              TransitionComponent={Fade}
              sx={{ boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.2)", borderRadius: "8px" }}
            >
              <Alert
                iconMapping={{
                  success: <CheckCircle fontSize="inherit" />,
                }}
                severity="success"
                sx={{ width: "100%" }}
                onClose={() => {
                  setFinished(false);
                }}
              >
                <AlertTitle>{t("common.success")}</AlertTitle>
                {t("common.your")} <Link to="/map">{t("common.map")}</Link> {t("common.and")}{" "}
                <Link to="/anomaly-report">{t("common.report")}</Link> {t("findLeaksandAnomalies.content.readyAlert")}
              </Alert>
            </Snackbar>
          </div>
          {anomalyRecord && (
            <AnomalyReportDataGrid tableData={anomalyRecord} onConfirm={() => setRefresh(!refresh)} acc_thred={accuracy} />
          )}
          <div>
            {loadingTable && (
              <div style={{ textAlign: "center", fontSize: "2rem" }}>
                Loading Table <CircularProgress size={"2rem"} />
              </div>
            )}
            {/* {anomalyRecord && <AnomalyDataGrid tableData={anomalyRecord} onConfirm={() => setRefresh(!refresh)} acc_thred={accuracy} />} */}
          </div>
        </div>
      </PageWrapper>
    </div>
  );
};

export default ReportPage;
