import React, { useState } from "react";
import { SearchBarWrapper, SearchDataType } from "../../pages/PipelineMapPage";
import ReactSearchBox from "react-search-box";
import SearchIcon from "../../icons/searchIcon.svg";
import { Alert, Box, Button, Fade, Snackbar } from "@mui/material";
import axios from "axios";
import { API_URL } from "../../constants";
import { useTranslation } from "react-i18next";

interface PathfinderProps {
  section: string;
  searchData: SearchDataType[];
  setPath: React.Dispatch<React.SetStateAction<string[]>>;
}

// this component will re-render only when searchData is changed
const PathFinder: React.FC<PathfinderProps> = ({ searchData, setPath, section }) => {
  const junctionData = searchData.filter((item) => item.type === "junction");
  const [startNode, setStartNode] = useState<string>("");
  const [endNode, setEndNode] = useState<string>("");
  const [pipelineLength, setPipelineLength] = useState<number>(-1);
  const [error, setError] = useState<string>("");
  const { t } = useTranslation();
  const handleSearch = () => {
    if (startNode.length > 0 && endNode.length > 0) {
      axios.get<string[]>(`${API_URL}/network/path/${startNode}/${endNode}/${section}`).then((response) => {
        console.log(response.data);
        if (response.data.length === 0) {
          // alert("The pipeline doesn't exist");
          setError("The pipeline doesn't exist");
        }
        setPath(response.data);
      });

      axios.get<number>(`${API_URL}/network/path_length/${startNode}/${endNode}/${section}`).then((response) => {
        console.log(response.data);
        if (response.data !== null) {
          console.log("setPipelineLength", response.data);
          setPipelineLength(response.data);
        } else {
          setPipelineLength(-1);
        }
      });
    }
  };
  return (
    <Box width="100%">
      <div style={{ display: "flex" }}>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "8px", padding: "1rem" }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
            <circle cx="6" cy="6" r="5.5" stroke="#000A14" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" width="4" height="21" viewBox="0 0 4 21" fill="none">
            <circle cx="2" cy="2" r="2" fill="#666666" />
            <circle cx="2" cy="11" r="2" fill="#666666" />
            <circle cx="2" cy="19" r="2" fill="#666666" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="10" viewBox="0 0 12 10" fill="none">
            <path
              d="M7.29988 8.75C6.72253 9.75 5.27915 9.75 4.7018 8.75L1.2377 2.75C0.66035 1.75 1.38204 0.5 2.53674 0.5H9.46494C10.6196 0.5 11.3413 1.75 10.764 2.75L7.29988 8.75Z"
              stroke="#000A14"
            />
          </svg>
        </div>
        <div className="search-box-btn" style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
          <SearchBarWrapper>
            <ReactSearchBox
              placeholder={startNode?.length === 0 ? t("pipelineDetails.chooseStart") : startNode}
              data={junctionData}
              onSelect={(record: any) => handleSearch()}
              clearOnSelect
              onFocus={() => {
                setStartNode("");
                setPath([]);
              }}
              leftIcon={<img src={SearchIcon} className="search-icon" />}
              iconBoxSize="48px"
              onChange={(text) => {
                setStartNode(text);
                if (!text) setPath([]);
                console.log(text);
              }}
            />

            <ReactSearchBox
              placeholder={endNode.length === 0 ? t("pipelineDetails.chooseStop") : endNode}
              data={junctionData}
              clearOnSelect
              onFocus={() => {
                setEndNode("");
                setPath([]);
              }}
              onSelect={(record: any) => handleSearch()}

              leftIcon={<img src={SearchIcon} className="search-icon" />}
              iconBoxSize="48px"
              onChange={(text) => setEndNode(text)}
            />
          </SearchBarWrapper>
        </div>
      </div>
      <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
        <div style={{ padding: "1rem" }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="8" viewBox="0 0 20 8" fill="none">
            <path
              d="M2.87406 7.2999L0.274061 4.6999C0.174061 4.5999 0.103061 4.49157 0.0610614 4.3749C0.0190614 4.25824 -0.00127179 4.13324 6.15388e-05 3.9999C6.15388e-05 3.86657 0.0210615 3.74157 0.0630615 3.6249C0.105062 3.50824 0.175395 3.3999 0.274061 3.2999L2.89906 0.674903C3.08239 0.49157 3.30739 0.399902 3.57406 0.399902C3.84073 0.399902 4.07406 0.499903 4.27406 0.699903C4.45739 0.883236 4.54906 1.11657 4.54906 1.3999C4.54906 1.68324 4.45739 1.91657 4.27406 2.0999L3.37406 2.9999H15.7741L14.8491 2.0749C14.6657 1.89157 14.5741 1.66657 14.5741 1.3999C14.5741 1.13324 14.6741 0.899903 14.8741 0.699903C15.0574 0.516569 15.2907 0.424903 15.5741 0.424903C15.8574 0.424903 16.0907 0.516569 16.2741 0.699903L18.8741 3.2999C18.9741 3.3999 19.0451 3.50824 19.0871 3.6249C19.1291 3.74157 19.1497 3.86657 19.1491 3.9999C19.1491 4.13324 19.1281 4.25824 19.0861 4.3749C19.0441 4.49157 18.9734 4.5999 18.8741 4.6999L16.2491 7.3249C16.0657 7.50824 15.8407 7.5999 15.5741 7.5999C15.3074 7.5999 15.0741 7.4999 14.8741 7.2999C14.6907 7.11657 14.5991 6.88324 14.5991 6.5999C14.5991 6.31657 14.6907 6.08324 14.8741 5.8999L15.7741 4.9999H3.37406L4.29906 5.9249C4.48239 6.10824 4.57406 6.33324 4.57406 6.5999C4.57406 6.86657 4.47406 7.0999 4.27406 7.2999C4.09073 7.48324 3.85739 7.5749 3.57406 7.5749C3.29073 7.5749 3.05739 7.48324 2.87406 7.2999Z"
              fill="black"
            />
          </svg>
        </div>
        <div>{`${t("pipelineDetails.distance")} ${pipelineLength >= 0 ? pipelineLength : "N/A"} m`}</div>
      </div>
      <Snackbar
        open={!!error}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{ boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.2)", borderRadius: "8px"}}
        onClose={() => setError("")}
      >
        <Alert severity="warning" onClose={() => setError("")}>
          {error}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default PathFinder;
