import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  Stepper,
  StepLabel,
  StepContent,
  Step,
  IconButton,
  Paper,
  Snackbar,
  Alert,
} from "@mui/material";
import axios, { AxiosResponse } from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { API_URL, RUN_URL } from "../../constants";
import {
  JunctionMap,
  JunctionMetadataType,
  NetworkMetadataProps,
  PipeMap,
  PipeMetadataType,
} from "../../pages/PipelineConfigPage";
import { FileRejection, useDropzone } from "react-dropzone";
import { BiUpload } from "react-icons/bi";
import { MapPreview } from "../map/PipelineMap";
import { BsFiletypeCsv } from "react-icons/bs";
import DeleteIcon from "@mui/icons-material/Delete";
import NetworkMetadataEditor from "./NetworkMetadataEditor";
import InfoTooltip from "../utils/InfoIcon";
import { junctionFileCheck, pipeFileCheck } from "../../utils/FileChecker";
import { useTranslation } from "react-i18next";

export function formatFileSize(sizeInBytes: number) {
  const sizeInKilobytes = sizeInBytes / 1024;
  return `${Math.round(sizeInKilobytes)}k`;
}

export function isNumeric(str: string): boolean {
  if (typeof str != "string") return false; // we only process strings!
  // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
  // ...and ensure strings of whitespace fail
  return !isNaN(str as unknown as number) && !isNaN(parseFloat(str));
}

export function convertCsvToJson(csvData: string): Record<string, (number | string | null)[]> {
  let lines = csvData.split("\n");
  lines = lines.slice(0, lines.length - 1);
  const headers = lines[0].split(",");

  const json: Record<string, (number | string | null)[]> = {};

  for (let i = 1; i < lines.length; i++) {
    const values = lines[i].split(",");
    if (headers[0] === "t_stamp" && values[0] === "") continue;

    for (let j = 0; j < headers.length; j++) {
      const header = headers[j];
      let value: string | null | number = values[j];

      if (value === "") {
        value = null;
      } else if (isNumeric(value)) {
        value = parseFloat(value);
      }

      if (json[header]) {
        json[header].push(value);
      } else {
        json[header] = [value];
      }
    }
  }

  return json;
}

const parseCSVtoMetadata = (csvData: string): PipeMap | JunctionMap => {
  const lines = csvData.split("\n");
  const headers = lines[0].split(",") as string[];

  // Map the rest of the lines to objects
  const pipes = lines.slice(1, lines.length - 1).map((line) => {
    const values = line.split(",");
    let metadata: any = {} as PipeMetadataType | JunctionMetadataType;
    headers.forEach((header, index) => {
      let value: string | number | boolean = values[index];

      // Convert to number if it's a number
      if (!isNaN(Number(value))) {
        value = Number(value);
      }

      // Convert to boolean if it's a boolean
      else if (value.toLowerCase() === "true" || value.toLowerCase() === "false") {
        value = value.toLowerCase() === "true";
      }
      metadata[header] = value;
    });
    return metadata as PipeMetadataType | JunctionMetadataType;
  });
  const MetadataMap: PipeMap | JunctionMap = pipes.reduce((map, pipe) => {
    map[pipe.LABEL] = pipe;
    return map;
  }, {} as PipeMap | JunctionMap);

  // console.log('MetadataMap',MetadataMap)
  return MetadataMap;
};

const setSectionInitViewPos = (junctionMap: JunctionMap) => {
  const initPos = [0, 0];
  if (Object.keys(junctionMap).length === 0) return initPos;
  for (const key in junctionMap) {
    initPos[0] += junctionMap[key].GPS_LONGITUDE;
    initPos[1] += junctionMap[key].GPS_LATITUDE;
  }
  console.log(initPos);
  initPos[0] = initPos[0] / Object.keys(junctionMap).length;
  initPos[1] = initPos[1] / Object.keys(junctionMap).length;
  console.log(initPos);
  return initPos;
};

const FileUploadStepper: React.FC<NetworkMetadataProps> = ({ network_id, ...props }) => {
  const { pipes, junctions, setPipes, setJunctions } = { ...props };
  const { t } = useTranslation();
  const isPipeExist: boolean = Object.keys(pipes).length > 0;
  const isJunctionExist: boolean = Object.keys(junctions).length > 0;

  const [junctionFile, setJunctionFile] = useState<File | null>(null);
  const [pipeFile, setPipeFile] = useState<File | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const mapRef = useRef<HTMLDivElement | null>(null);
  const [activeStep, setActiveStep] = React.useState<number>(
    isPipeExist && isJunctionExist ? 2 : !isPipeExist && !isJunctionExist ? 0 : 1
  );
  const [editable, setEditable] = useState<boolean>(false);

  const [open, setOpen] = useState<boolean>(false);
  const [junctionCsv, setJunctionCsv] = useState<string>("");
  const [pipeCsv, setPipeCsv] = useState<string>("");
  const junctionSet = useRef<Set<string>>(new Set<string>());
  const [fileCheckFailed, setFileCheckFailed] = useState<boolean>(false);

  useEffect(() => {
    // fetch if it's editable
    axios.get(`${API_URL}/models/${network_id}`).then((response) => {
      console.log(response.data, typeof response.data);
      setEditable(!response.data);
      if (response.data) {
        setActiveStep(3);
      }
    });
    Object.keys(junctions).forEach((key) => junctionSet.current.add(key));
  }, []);

  const updateJunctions = (csv: string): Promise<AxiosResponse<any, any>> => {
    return new Promise<AxiosResponse<any, any>>(async (resolve, reject) => {
      console.log("csv", csv);
      try {
        // Get junctions' rawdata
        const junctionResponse = await axios.post(
          `${RUN_URL}/junctions/${network_id}`,
          {
            node_dataset_dict: convertCsvToJson(csv),
          },
          {
            headers: { "Content-Type": "application/json" },
          }
        );
        console.log(junctionResponse.data);
        resolve(junctionResponse);
      } catch (error) {
        console.error("Error:", error);
        reject(error);
      }
    });
  };
  const updatePipes = (csv: string): Promise<AxiosResponse<any, any>> => {
    return new Promise<AxiosResponse<any, any>>(async (resolve, reject) => {
      try {
        // post the junction data to duos
        const pipeResponse = await axios.post(`${RUN_URL}/pipes/${network_id}`, { edge_dataset_dict: convertCsvToJson(csv) });
        console.log(pipeResponse.data);
        resolve(pipeResponse);
      } catch (error) {
        console.error(error);
        reject(error);
      }
    });
  };

  const onJunctionFileDrop = useCallback((acceptedFiles: File[], fileRejections: FileRejection[]) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = (event) => {
        if (!junctionFileCheck(event.target?.result as string)) {
          setFileCheckFailed(true);
          return;
        }
        setJunctionCsv((event.target?.result as string) ?? "");
        const jj = parseCSVtoMetadata(event.target?.result?.toString() ?? "") as JunctionMap;
        Object.keys(jj).forEach((key) => junctionSet.current.add(key));
        setJunctions(jj);
        setOpen(true);
        setJunctionFile(file);
      };
      reader.readAsText(file);
    });
    fileRejections.forEach((file) => {
      console.log("wrong file:", file.file.name);
    });
  }, []);

  const {
    getRootProps: getJunctionRootProps,
    getInputProps: getJunctionInputProps,
    isDragActive: isJunctionDragActive,
  } = useDropzone({
    onDrop: onJunctionFileDrop,
    accept: {
      "text/csv": [".csv"],
    },
  });

  const onPipeFileDrop = useCallback((acceptedFiles: File[], fileRejections: FileRejection[]) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = (event) => {
        if (!pipeFileCheck(event.target?.result as string, junctionSet.current)) {
          console.log("pipe check failed")
          setFileCheckFailed(true);
          return;
        }
        setPipeCsv((event.target?.result as string) ?? "");
        const pp = parseCSVtoMetadata(event.target?.result?.toString() ?? "") as PipeMap;
        setPipes(pp);
        setOpen(true);
        setPipeFile(file);
      };
      reader.readAsText(file);
    });
    fileRejections.forEach((file) => {
      console.log("wrong file:", file.file.name);
    });
  }, []);

  const {
    getRootProps: getPipeRootProps,
    getInputProps: getPipeInputProps,
    isDragActive: isPipeDragActive,
  } = useDropzone({
    onDrop: onPipeFileDrop,
    accept: {
      "text/csv": [".csv"],
    },
  });

  const handlePreview = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleConfirm = async () => {
    if (activeStep === 0) {
      try {
        const repsonse = await updateJunctions(junctionCsv);
        console.log("upload pipe file,", repsonse.data);
        toast.success("Pipe Files Uploaded");
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        handleClose();
      } catch (e) {
        console.error(e);
      }
    } else {
      try {
        const repsonse = await updatePipes(pipeCsv);
        console.log("upload pipe file,", repsonse.data);
        toast.success("Pipe Files Uploaded");
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        handleClose();
      } catch (e) {
        console.error(e);
      }
    }
  };

  const tableNodeInfo = `
    <h4>${t("info.tableNodeInfo.title")}</h4>
    <h3>${t("info.tableNodeInfo.description")}:</h3>
    <table border="1" style="width:100%">
      <tr>
        <th>LABEL</th>
        <th>ELEV_m</th>
        <th>GPS_LATITUDE</th>
        <th>GPS_LONGITUDE</th>
        <th>KNOWN_PRESSURE</th>
        <th>NODE_TYPE</th>
      </tr>
      <tr>
        <td>${t("info.tableNodeInfo.labelDescription", "name of the junction")}</td>
        <td>${t("info.tableNodeInfo.elevationDescription", "elevation from the sea level in meter")}</td>
        <td>${t("info.tableNodeInfo.latitudeDescription", "latitude number")}</td>
        <td>${t("info.tableNodeInfo.longitudeDescription", "longitude number")}</td>
        <td style="word-wrap: break-word; overflow-wrap: break-word;">${t(
          "info.tableNodeInfo.knownPressureDescription",
          "true if the junction pressure is known else False"
        )}</td>
        <td>${t(
          "info.tableNodeInfo.nodeTypeDescription",
          "I for inlet junction, E for exist junction, U for middle junctions"
        )}</td>
      </tr>
    </table>
  `;

  const tablePipeInfo = `
    <h4>${t("info.tablePipeInfo.title", "Please make sure the files are in the correct format")}</h4>
    <h3>${t("info.tablePipeInfo.description", "Pipe File")}:</h3>
    <table border="1" style="width:100%">
      <tr>
        <th>START_NODE</th>
        <th>STOP_NODE</th>
        <th>LENGTH_m</th>
        <th>D_mm</th>
        <th>LABEL</th>
        <th>MATERIAL</th>
        <th>PDMA</th>
        <th>VALVE</th>
      </tr>
      <tr>
        <td style="word-wrap: break-word; overflow-wrap: break-word;">${t(
          "info.tablePipeInfo.START_NODEDescription",
          "name of the start junction of the pipe"
        )}</td>
        <td style="word-wrap: break-word; overflow-wrap: break-word;">${t(
          "info.tablePipeInfo.STOP_NODEDescription",
          "name of the end junction of the pipe"
        )}</td>
        <td>${t("info.tablePipeInfo.LENGTH_mDescription", "length of the pipe")}</td>
        <td>${t("info.tablePipeInfo.D_mmDescription", "pipe's diameter")}</td>
        <td>${t("info.tablePipeInfo.LABELDescription", "pipe's label/name")}</td>
        <td>${t("info.tablePipeInfo.MATERIALDescription", "pipe's material")}</td>
        <td style="word-wrap: break-word; overflow-wrap: break-word;">${t(
          "info.tablePipeInfo.PDMADescription",
          "area code of the pipe"
        )}</td>
        <td style="width:120px;">${t("info.tablePipeInfo.VALVEDescription", "valve name of a pipe")}</td>
      </tr>
    </table>
  `;

  const message = `<div style="width: 400px; padding: 10px;">
  <p>${t("info.message", "Please check the table to see if you want to modify your pipes and junctions. Once you click confirm, you cannot make any changes.")}</p>
</div>`;

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <Stepper activeStep={activeStep} orientation="vertical">
        <Step key={"Add Junction File"} sx={{ ".css-mbio22-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed": { color: "green" } }}>
          <StepLabel>
            {t("uploadNetwork.content.step1")} {activeStep === 0 && <InfoTooltip info={tableNodeInfo} />}
          </StepLabel>
          <StepContent>
            <Typography variant="body2"> {t("uploadNetwork.content.step1description")}</Typography>
            {/* JunctionFileDNDBox */}

            {!junctionFile ? (
              <Box
                {...getJunctionRootProps()}
                sx={{
                  cursor: "pointer",
                  border: isJunctionDragActive ? " 2px dashed #245FA6" : "2px dashed #CCCCCC",
                  padding: "2rem",
                  width: "90%",
                  height: "200px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "2rem",
                  boxSizing: "border-box",
                  borderRadius: "8px",
                  background: isJunctionDragActive ? "#EFF5FA" : undefined,
                }}
              >
                <input {...getJunctionInputProps()} />
                <BiUpload size="2rem" color="#666666" />
                <Typography variant="h5" fontWeight={400} margin="1rem">
                  {t("dnd.intro")}
                  {t("dnd.junctionData")}
                </Typography>
                <Typography variant="h5" fontWeight={400}>
                  {t("dnd.or")}{" "}
                  <Typography
                    variant="h5"
                    fontWeight={400}
                    color="#245FA6"
                    sx={{ textDecorationLine: "underline" }}
                    component={"span"}
                  >
                    {t("dnd.selectFile")}
                  </Typography>
                </Typography>
              </Box>
            ) : (
              <Box
                sx={{
                  width: "90%",
                  display: "flex",
                  justifyContent: "space-between",
                  background: "#EFF5FA",
                  margin: "16px 0",
                  padding: "16px 24px;",
                  borderRadius: "8px",
                }}
              >
                <Box display="flex" gap="3rem">
                  <BsFiletypeCsv size={"2.5rem"} />
                  <Box display="flex" flexDirection="column">
                    <Typography variant="body2">{junctionFile.name}</Typography>
                    <Typography variant="body2">{formatFileSize(junctionFile.size)}</Typography>
                  </Box>
                </Box>
                <IconButton
                  sx={{ justifySelf: "end" }}
                  onClick={() => {
                    setJunctionFile(null);
                    setJunctions({} as JunctionMap);
                  }}
                >
                  <DeleteIcon fontSize="inherit" />
                </IconButton>
              </Box>
            )}
            <Box sx={{ mb: 2 }}>
              <div>
                <Button disabled={!junctionFile} variant="contained" onClick={handlePreview} sx={{ mt: 1, mr: 1 }}>
                  {t("common.preview")}
                </Button>
              </div>
            </Box>
          </StepContent>
        </Step>
        <Step key={"Add Pipe File"} sx={{ ".css-mbio22-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed": { color: "green" } }}>
          <StepLabel>
            {t("uploadNetwork.content.step2")} {activeStep === 1 && <InfoTooltip info={tablePipeInfo} />}
          </StepLabel>
          <StepContent>
            <Typography variant="body2"> {t("uploadNetwork.content.step2description")}</Typography>

            {/* PipeFileDNDBox */}
            {!pipeFile ? (
              <Box
                {...getPipeRootProps()}
                sx={{
                  cursor: "pointer",
                  border: isPipeDragActive ? " 2px dashed #245FA6" : "2px dashed #CCCCCC",
                  padding: "2rem",
                  width: "90%",
                  height: "200px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "2rem",
                  boxSizing: "border-box",
                  borderRadius: "8px",
                  background: isPipeDragActive ? "#EFF5FA" : undefined,
                }}
              >
                <input {...getPipeInputProps()} />
                <BiUpload size="2rem" color="#666666" />
                <Typography variant="h5" fontWeight={400} margin="1rem">
                  {t("dnd.intro")}
                  {t("dnd.pipeData")}
                </Typography>
                <Typography variant="h5" fontWeight={400}>
                  {t("dnd.or")}{" "}
                  <Typography
                    variant="h5"
                    fontWeight={400}
                    color="#245FA6"
                    sx={{ textDecorationLine: "underline" }}
                    component={"span"}
                  >
                    {t("dnd.selectFile")}
                  </Typography>
                </Typography>
              </Box>
            ) : (
              <Box
                sx={{
                  width: "90%",
                  display: "flex",
                  justifyContent: "space-between",
                  background: "#EFF5FA",
                  margin: "16px 0",
                  padding: "16px 24px;",
                  borderRadius: "8px",
                }}
              >
                <Box display="flex" gap="3rem">
                  <BsFiletypeCsv size={"2.5rem"} />
                  <Box display="flex" flexDirection="column">
                    <Typography variant="body2">{pipeFile.name}</Typography>
                    <Typography variant="body2">{formatFileSize(pipeFile.size)}</Typography>
                  </Box>
                </Box>
                <IconButton
                  sx={{ justifySelf: "end" }}
                  onClick={() => {
                    setPipeFile(null);
                    setPipes({} as PipeMap);
                  }}
                >
                  <DeleteIcon fontSize="inherit" />
                </IconButton>
              </Box>
            )}

            <Box sx={{ mb: 2 }}>
              <div>
                <Button disabled={!pipeFile} variant="contained" onClick={handlePreview} sx={{ mt: 1, mr: 1 }}>
                  {t("common.preview")}
                </Button>
                <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                  {t("common.back")}
                </Button>
              </div>
            </Box>
          </StepContent>
        </Step>
        <Step
          key={"Edit Network Metadata"}
          sx={{ ".css-mbio22-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed": { color: "green" } }}
        >
          <StepLabel>
            {t("uploadNetwork.content.step3")} {activeStep === 2 && <InfoTooltip info={message} />}
          </StepLabel>
        </Step>
      </Stepper>

      {activeStep === 3 && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography variant="body2"> {t("uploadNetwork.content.successMessage")}</Typography>

          {/* <Button variant="contained" onClick={()=>{setActiveStep((prestep)=>prestep+1)}}>View network metadata</Button> */}
        </Paper>
      )}
      {/* Pipe and junction edit(CRUD) list */}
      {activeStep >= 2 && (
        <NetworkMetadataEditor
          {...props}
          network_id={network_id}
          editable={editable}
          setEditable={setEditable}
          setActiveStep={setActiveStep}
        />
      )}

      {open && (
        <Dialog open={open} onClose={handleClose} fullWidth>
          <DialogTitle>
            <Typography
              align="center"
              sx={{
                fontFamily: ["Montserrat", "Inter"].join(","),
                fontSize: "32px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "36px",
                color: "#000A14",
              }}
            >
              {t("uploadNetwork.content.mapPreview")}
            </Typography>
          </DialogTitle>
          <DialogContent sx={{ padding: "0 2rem 1rem" }}>
            <DialogContentText>
              <Typography marginBottom="1rem" align="center" variant="body2" color="#666">
                {t("uploadNetwork.content.confirmWarning")}
              </Typography>
            </DialogContentText>

            <MapPreview junctionMap={junctions} pipeMap={pipes} initPos={setSectionInitViewPos(junctions)} mapRef={mapRef} />
          </DialogContent>
          <DialogActions sx={{ padding: "0 2rem 1rem" }}>
            <Button variant="outlined" onClick={handleClose} sx={{ width: "20%", marginRight: "0.5rem" }} color="primary">
              {t("common.cancel")}
            </Button>
            <Button variant="contained" onClick={handleConfirm} sx={{ width: "20%" }}>
              {t("common.confirm")}
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <Snackbar
        open={fileCheckFailed}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        sx={{ boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.2)", borderRadius: "8px" }}
        onClose={() => setFileCheckFailed(false)}
      >
        <Alert severity="warning">{t("uploadNetwork.snackbar.fileNotValid")}</Alert>
      </Snackbar>
    </div>
  );
};

export default FileUploadStepper;
